export class URLWrapper {
	static readonly urls = {
		auth: {
			loginUrl: "/auth/login", //POST
			loginData: "/auth/login/data",
			SocialAuth: "/auth/social/login",//post
			Linkedinlogin: "/auth/linkedin",//post
			register: {
				user: "/auth/register/user", //POST
				resendVerifyMail: "/auth/register/user/resendmail",
				community: "/auth/register/community", //POST
				invite: "/auth/register/invite", //POST
				link: "/auth/register/invite/link" //POST
			},
			Linkedin: {
				base_url: 'https://www.linkedin.com/oauth/v2',
				base_url_api: 'https://api.linkedin.com/v2',
				email: '/emailAddress?q=members&projection=(elements*(handle~))',
				profile: '/me',
				profilephoto: '/me?projection=(id,profilePicture(displayImage~digitalmediaAsset:playableStreams))'
			},
			existing: {
				invite: "/auth/existing/invite", //POST
			},

			password: {
				forgotPassword: "/auth/password/forgot", //POST
				resetPassword: "/auth/password/reset" //POST
			},
			emailVerification: "/auth/email/verification/resend", //POST,
			loginVerify: "/auth/login/verify", //POST
			logout: "/profile/logout", //POST
			lockscreen: {
				lockscreen: "/auth/lockscreen",//POST
				lockscreenview: "/auth/lockscreen/view",//GET
				lockscreenAuth: "/auth/lockscreen/auth",
				lockscreenDelete: "/auth/lockscreen/delete"
			},
			ShareableUrl: {
				CreateShareableUrl: "/auth/shareableurl",//POST
				GetShareableUrl: "/auth/shareable/post/content",//GET
			},
		},
		posts: {
			url: "/posts", //ALL CRUD
			like: "/posts/like",
			share: "/posts/share",
			comments: "/posts/comment",
			commentLike: "/posts/comment/like",
			commentReport: "/posts/comment/report",
			report: "/posts/report",
			hide: "/posts/hide",
			hideUser: "/posts/hide/user", //Hide post by User
			pinToMediaCenter: "/posts/pin",
		},
		profile: {
			url: "/profile",
			profile: "/profile",
			profileUpdate: "/profile/update",
			profileBrief: "/profile/brief",
			profileView: "/profile/view",
			profileViewBrief: "/profile/view/brief",
			profileVisits: "/profile/visits",
			profileCommunities: "/profile/communities", //All CRUD
			profileCommunitiesBrief: "/profile/communities/brief",
			profileCommunityInvitations: "/profile/community/invitations",
			profileCommunityRequests: "/profile/community/requests",
			// createCommunity: "/createCommunity",
			// deleteCommunity: "/deleteCommunity",
			// profileConnections: "/profile/connections",
			joinCommunity: "/profile/community/join", //POST Action,
			about: "/profile/about",
			email: "/profile/email",
			industry: '/profile/industry',
			explorePopUp: "/profile/explorepopup",
			activity: "/profile/activities",
			online: "/profile/online",
			changePassword: "/profile/password",
			details: "/profile/details", //ALL CRUD
			connections: "/profile/connections", //ALL CRUD
			expertise: "/profile/expertise", //ALL CRUD
			expertiseAll: "/profile/expertise/all", //ALL CRUD
			interests: "/profile/interests", //ALL CRUD
			interestsAll: "/profile/interests/all", //ALL CRUD
			experience: "/profile/experience", //ALL CRUD
			education: "/profile/education", //ALL CRUD
			membership: "/profile/membership", //ALL CRUD
			notification: "/profile/notifications", //ALL CRUD
			connectionRequests: "/profile/connectionRequests",
			communintyInvite: "/profile/connection/community/request",
			InviteinviteRequest: "/profile/community/inviteRequest",
			settings: "/profile/setting",
			readNotification: "/profile/notifications/read",
			// InviteAccept: "/profile/community/inviteRequest",
			openCommunitySection: "/profile/open-community-section", //All CRUD
		},
		message: {
			messageAllUrl: "/message/p2p", //GET Call
			messageGroupsUrl: "/message/groups", //CRUD
			messageGroupsPeople: "/message/groups/people", //CRUD
			messageChannelMember: "/message/channel/member", //CRUD
			messageToken: "/message/token", //GET Call
			recent: "/message/recent"
		},
		messageV2: {
			group: "/messagesv2/group",
			groupMembers: "/messagesv2/group/members",
			groupMessage: "/messagesv2/group/message",
			all: "/messagesv2/all",
			note: "/messagesv2/note",
			calendly: "/messagesv2/calendly",
			cacheInvalidationViaTriggers: "/messagesv2/cache",
			huddle: {
				token: "/messagesv2/huddle/token", // GET
				link: "/messagesv2/huddle/link", // GET
				user: "/messagesv2/huddle/user", // GET
				channel: "/messagesv2/huddle/channel", // GET
				status: "/messagesv2/huddle/status", // POST
				mattermostUser: "/messagesv2/huddle/mattermost-user", // GET
				rooms: "/messagesv2/agora/rooms",
				hook: "/messagesv2/agora/hook",
				invite: "/messagesv2/agora/invite",
				validate: "/messagesv2/agora/validate",
				addAttendee: "/messagesv2/agora/add-attendee",
			}
		},
		poll: {
			url: "/polls",
			submit: "/polls/submit",
			end: "/polls/end",
			download: "/polls/download",
		},
		survey: {
			url: "/surveys",
			submit: "/surveys/submit",
			end: "/surveys/end",
			download: "/surveys/download",
		},
		recommendation: {
			type: "/recommendations/type", //GET Call
			get: "/recommendations/get" //GET Call
		},
		community: {
			all: "/community", // ALL CRUD

			token: "/community/token",
			tokenData: "/community/token-data",

			specific: "/community/one", // ALL CRUD
			specificBrief: "/community/one/brief", // ALL CRUD
			getMattermostChannels: "/community/one/mattermost-channels", // GET
			feedback: "/community/one/feedback", // POST

			termsCondition: "/community/termsCondition",
			privacyPolicy: "/community/privacyPolicy",
			termsConditionprivacyPolicy: "/community/termsConditionprivacyPolicy",

			qni: "/community/one/qni",

			readableId: "/community/readable-id",
			checkReadableId: "/community/check-readable-id",

			unreadMentions: "/community/unread-mentions",

			pendingApprovalPosts: "/community/pending-approval-posts",
		},
		commnunnityAdmin: {
			communityRequests: "/admin/community/requests", //ALL CRUD
			communityRequestsExport: "/admin/community/requests/export",
			communityRequestPopUp: "/admin/community/requests/notification",

			communityNominations: "/admin/community/nomination",
			communityNominationsExport: "/admin/community/nomination/export",
			communityNominationPopUp: "/admin/community/nomination/notification",

			spaces: "/admin/community/spaces", //ALL CRUD

			// Get or set either community's or Space details
			communitySpaceDetails: "/admin/community/spaces/one", //ALL CRUD

			users: "/admin/community/users", //ALL CRUD
			exportUsers: "/admin/community/users/export",
			userRoles: "/admin/community/users/roles",
			removeUsers: "/admin/community/users/remove", //ALL CRUD

			feedbacks: "/admin/community/feedbacks", //ALL CRUD

			newAffiliatedTags: "/admin/community/tags/multiple",
			invites: "/admin/community/invites", //ALL CRUD


			outgoingInvites: "/admin/community/invites/outgoing",
			outgoingInvitesExport: "/admin/community/invites/outgoing/export",
			allOutgoingInvites: "/admin/community/invites/outgoing/all",
			communityInvitesnPopUp: "/admin/community/invites/outgoing/notification",

			classification: {
				exiting: "/admin/community/classification",
				new: "/admin/community/classification/multiple"
			},

			settings: {
				url: "/admin/community/settings",

				admin: "/admin/community/settings/admin", // In Use - But we be discarded
				morderatorGet: "/admin/community/settings/morderator",
				morderatorAdd: "/admin/community/settings/morderator",
				morderatorRemove: "/admin/community/settings/morderator",

				unblockUsers: "/admin/community/settings/unblockusers",
				blockedUsers: "/admin/community/settings/blockusers",

				permissions: "/admin/community/settings/permissions",
				spaceDefaultSettings: "/admin/community/space/settings/default",

				notifications: "/admin/community/settings/notifications",

				features: "/admin/community/settings/features",
				featuresBulkUpdate: "/admin/community/settings/features/bulk-update",

				roles: "/admin/community/settings/roles",
				rolePrivileges: "/admin/community/settings/roles/privileges",
				roleRestrictions: "/admin/community/settings/roles/restrictions",
				access: "/admin/community/settings/access",
			},

			communityAdminColour: "/admin/community/colour",
			nominations: {
				url: "/community/nomination", // CR
				existig: "/community/nomination/existing",
				request: "/community/nomination/request",
				bulk: "/community/nomination/bulk"
			},

			tagRestriction: "/admin/community/tagRestriction",

			// Invites and Bulk Invite below this

			invitesExisting: "/admin/community/invites/existing", //ALL CRUD
			invitesEmail: "/admin/community/invites/emails", //ALL CRUD
			invitesEmailCommonTagNote: "/admin/community/invites/emails/common",
			invitesBulk: "/admin/community/invites/bulk", //ALL CRUD

			invitesBulkValidation: "/admin/community/bulk-invites/validation/upload",
			invitesEmailBulkValidation: "/admin/community/bulk-invites/validation/email",
			inviteBulkTemplate: "/admin/community/bulk-invite/template",

			csv: {
				files: "/admin/community/bulk-invite/csvs",
				entries: "/admin/community/bulk-invite/csvs/entries",
				downloadFailed: "/admin/community/bulk-invite/csvs/download/failed"
			}
		},
		communityMorderator: {
			url: "/moderator",
			post: "/moderator/post",
			declinePost: "/moderator/post/decline",
			oppurtunity: "/moderator/oppurtunity",
			user: {
				url: "/moderator/user",
				block: "/moderator/user/block",
				invite: "/moderator/user/invite"
			}
		},

		platformAdmin: {
			dashboard: "/platform/admin/dashboard",
			community: "/platform/admin/community",
			communityEmailFlag: "/platform/admin/community/email",
			communityExploreFlag: "/platform/admin/community/explore",
			user: "/platform/admin/user",
			userPassword: "/platform/admin/user/password",
			communityFeture: "/platform/admin/community/feature",
			communityRequest: "/platform/admin/community/request",
			report: "/platform/admin/report",
			role: "/platform/admin/role",
			privilege: "/platform/admin/privilege",
			email: "/platform/admin/email",
			feature: "/platform/admin/feature",
			industry: {
				url: "/platform/admin/industry",
				communityCount: "/platform/admin/industry/community"
			},
			uploadUser: "/platform/admin/upload/user",
			uploadExperience: "/platform/admin/upload/experience",
			uploadExperienceUpdate: "/platform/admin/upload/experience/update",
			uploadEducation: "/platform/admin/upload/education",
			uploadEducationUpdate: "/platform/admin/upload/education/update",
			uploadMembership: "/platform/admin/upload/membership",
			uploadMembershipUpdate: "/platform/admin/upload/membership/update",
			uploadExpertise: "/platform/admin/upload/expertise",
			uploadExpertiseGCA: "/platform/admin/upload/expertisegca",
			uploadIndustry: "/platform/admin/upload/industry",
			uploadIndustryGCA: "/platform/admin/upload/industrygca",
			interestGCA: "/platform/admin/upload/interestmatch",
			uploadCommunity: "/platform/admin/upload/community",
			communityTag: "/platform/admin/community/tag",
			login: "/platform/admin/login",
			/** */
			termsConditionPrivacy: "/community/platform/termsCondition", //Shift this to community
			privacyPolicy: "/community/platform/privacyPolicy", //Shift this to community
			generateWeeklyReport: "/platform/admin/generateWeeklyReport",
			script: {
				sig: '/platform/admin/community/sig-data-mapping'
			}
		},
		oppurtunity: {
			events: "/oppurtunity/events",
			services: "/oppurtunity/services",
			questions: {
				url: "/oppurtunity/questions",
				follow: "/oppurtunity/questions/follow",
				answers: {
					url: "/oppurtunity/questions/answers",
					likes: "/oppurtunity/questions/answer/likes",
					follow: "/oppurtunity/questions/answer/follow",
					comments: {
						url: "/oppurtunity/questions/answer/comments",
						likes: "/oppurtunity/questions/answer/comments/likes"
					}
				}
			},
			fundraising: "/oppurtunity/fundraising",
			jobs: {
				url: "/oppurtunity/jobs",
				applicants: "/oppurtunity/jobs/applicants",
				applied: "/oppurtunity/jobs/applied",
				posted: "/oppurtunity/jobs/posted"
			},
			share: "/oppurtunity/share"
		},
		globalRoutes: {
			classifications: "/global/classifications/all",
			industries: "/global/industries/all",
			communities: "/global/comunities/all",
			getCommunities: "/global/get-communities",
			getEvents: "/global/get-events",
			featureUpdate: "/global/feature/update",
			membershipUpdate: "/global/membership/update",
			tagUpdate: "/global/tag/update",
			addUserToDefaultCommunity: "/global/user/defaultcom",
			addUCSUToCommunity: "/global/user/addcsu",
			invitationUpdate: "/global/invitation/update",
			invitationNomUpdate: "/global/invitationnom/update",
			userSettingsUpdate: "/global/usersettings/update",
			mcPUpdate: "/global/mcP/update",
			mcPUpdate2: "/global/mcP/update/prod",
			nameUpdate: "/global/name/update",
			// users: "/users/all", //Removed this routing due to security reasons
			// usersExceptCommunity: "/users/allNotInCommunity",
			usersNotInvited: "/global/users/notinvited/all",
			communityUsers: "/global/users/community",
			// communityAllUsers: "/users/community/all",
			features: "/global/features/all",

			tags: "/global/tags/all",
			communityTags: "/global/tags/community",
			eventTypes: "/global/eventtypes/all",
			jobTypes: "/global/jobtypes/all",
			roundTypes: "/global/roundtypes/all",
			salaryDurationTypes: "/global/salarydurationtypes/all",
			salaryTypes: "/global/salarytypes/all",
			startUpTypes: "/global/startuptypes/all",
			currencies: "/global/currencies/all",
			specialities: "/global/specialities/all",
			faq: "/global/faq",
			FaqExplanatoryVideos: "/global/faq/ExplainerVideos",
			explore: "/global/explore",
			explorePopUp: "/global/explore/notification",
			support: "/global/support",
			exploreOne: "/global/explore/one",
			encrptMobileNumber: "/global/user/mobile",
			usersMainPublicCommunityExceptCurrCmnty: "/global/users/PublicCommunityExceptCurrCmnty",
			convertExistingCommunityRequest: "/global/creq",
			platformFeedback: "/global/feedback",
			zoom: "/global/zoom",
			/** */

			createMobileLink: "/global/mobile/link",
			refer: "/global/refer/link",
			referSSO: "/global/refer/sso",

			referUpdate: "/global/refer/update",
			handlebar: "/global/handlebar",

			updateEvents: "/eventUpdate",

			sitemap: "/global/sitemap",

			tokens: {
				session: "/global/tokens/session",
				community: "/global/tokens/community",
				// group: "/global/tokens/group" // This will automatically be sufficed by the community token
			}
		},
		scrapeUrls: {
			url: "/global/scrape",
			v2: "/scrape"//This is without any /api/v1
		},
		shareable: {
			url: "/shareableLink"
		},
		fileUrl: {
			url: "/bucket"
		},

		//Can be removed later. Has duplicate in profile
		setting: {
			user: "/profile/setting"
		},

		profanity: {
			get: "/admin/community/profanity/",
			getPosts: "/admin/community/profanity/posts",
			getReportedBy: "/admin/community/profanity/reportedby"
		},
		oneRoutes: {
			service: "/global/services/one",
			job: "/global/jobs/one",
			fundraising: "/global/fundraisings/one",
			events: "/global/events/one",
			qna: "/global/qna/one",
			post: "/global/posts/one"
		},
		analytics: {
			popup: "/admin/analytics/popup",
			dashboard: "/admin/analytics/dashboard"
		},

		invitationUrl: {
			url: '/community/invitation',
			publicInfo: '/community/invitation/public',
			list: '/community/invitation/list',
			recent: '/community/invitation/recent',
			status: '/community/invitation/status',
			verify: '/community/invitation/verify'
		},

		// -------------------------------------------------- Plugin URLS

		mediaCenter: {
			base: "/mcenter",
			upload: "/mcenter/v1/upload",
			videoLink: "/mcenter/v1/link",
			generalLink: "/mcenter/v1/general/link",
			get: "/mcenter/v1/get",
			remove: "/mcenter/v1/remove",
			move: "/mcenter/v1/move",
			rename: "/mcenter/v1/rename",
			pinToTop: "/mcenter/v1/pintotop",
			share: "/mcenter/v1/share",
			folder: "/mcenter/v1/folder",
			download: "/mcenter/v1/folder/download",
			info: "/mcenter/v1/folder/info",
			size: "/mcenter/v1/folder/size",
			thumbnail: "/mcenter/v1/thumbnail"
		},


		plugin: {
			platformAdmin: "/plugin/platform",
			communityAdmin: {
				plugin: "/plugin/community",
				hubspot: "/plugin/community/hubspot",
				available: "/plugin/community/available",
				salesforceCallBack: "/plugin/community/salesforceCallBack",
				salesforceUserInfo: "/plugin/community/salesforceUserInfo",
				syncSalesforceContacts: "/plugin/community/syncSalesforceContacts",
			},
			user: {
				plugin: "/plugin/user",
				available: "/plugin/user/available"
			}
		},

		// -------------------------------------------------- PHASE 2 URLS

		childCommunity: {
			url: "/admin/community/child/v2"
		},

		stripe: { //appends "/stripe" automatically
			hooks: "/hook",
			accountHooks: "/hook/account",
			customerPortal: "/customer/portal",
			getConnectLink: '/get-connect-link',
			webhook: '/webhook',
			updateSubscription: '/update-subscription',
			checkPaymentMethod: '/payment-method',
			checkoutSessionUrl: '/checkout-session-url',
			getSubscriptionDetails: '/subscription-details',
			extendTrial: '/extend-trial',
			availablePlans: '/plans/available',
			cancelSubscription: '/cancel-subscription',
			toggleCommunityStatus: '/toggle-status',
			monetization: {
				account: "/monetization/accounts",
				plans: "/monetization/plans",
				spaces: "/monetization/spaces",
				dashboard: "/monetization/express",
				callback: "/monetization/callback",
				users: {
					// base: "/monetization/user",
					paymentMethod: "/monetization/user/payment-methods",
					customerPortal: "/monetization/user/portal",
					registerAndCharge: "/monetization/user/register"
				}
			}
		},

		events: {
			url: "/events", //ALL CRUD
			yourEvents: "/events/yourevents",
			otherEvents: "/events/otherevents",
			hostingEvents: "events/hosting",
			goingEvents: "/events/going",
			register: "/events/register",
			share: "/events/share",
			eventUsers: "/events/users",
			registeredUsers: "/events/users/going",
			interestedUsers: "/events/users/interested",
			userExport: "/events/users/export"
		},
		// /events/yourevents
		// /events/otherevents
		// /events/hosting
		// /events/going

		groups: {
			url: "/admin/community/groups",
			about: "/admin/community/groups/about",
			pin: "/admin/community/groups/pin",
			settings: "/admin/community/groups/settings",
			category: {
				url: "/admin/community/groups/category",
				assign: "/admin/community/groups/category/assign"
			},
			users: "/admin/community/groups/users",
			morderator: "/admin/community/groups/users/morderator"
		},
		agora: {
			base: 'https://api.agora.io/dev/v1'
		}
	};
	static formAppV1Url(url: string): string {
		return "/api/v1" + url;
	}

	static formFileV1Url(url: string): string {
		return "/files/v1" + url;
	}
}
