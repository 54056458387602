import React from 'react';
import { clone } from 'ramda';
import { employmentTypes } from '../../../constants/employment';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import { HttpMethod, HttpWrapper } from '../../../helpers/httpWrapper';
import { ICommunityRequest, ICommunityResponse, VCommunity } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/community';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import { head, isEmpty, range, reverse, join, contains } from 'ramda'
import moment from 'moment';
import { validateProfileExperienceForm } from './Validate/profileForm';
import { toastService } from '../../../services';
import { getFileName } from '../../../helpers';
import { getMonthDiff } from '../communities/helper/Timestap';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import ProfileImage from '../../../helpers/profileImage';


const AsyncTypeahead = withAsync(Typeahead);

export const initialFormData = {
	experienceId: '',
	title: '',
	employmentType: '',
	company: [],
	defaultCompany: [],
	communityId: '',
	communityName: '',
	location: '',
	startTime: 0,
	endTime: 0,
	from: '',
	from_from: '',
	from_to: '',
	to: '',
	to_from: '',
	to_to: '',
	description: '',
	media: '',
	mediaId: '',
	is_present: false,
	isEditable: false,
}

interface IState {
	formData: {
		experienceId: string,
		title: string,
		employmentType: string,
		company: any,
		defaultCompany: any,
		communityId?: string,
		communityName: string,
		location: string,
		startTime: number,
		endTime: number,
		from: string,
		from_from: string,
		from_to: string,
		to: string,
		to_from: string,
		to_to: string,
		description: string,
		media: string,
		mediaId?: string,
		is_present: boolean,
		isEditable: boolean,
	},
	formErrors: any,
	loading: boolean,
	comLoading: boolean,
	resError: boolean,
	submitted: boolean,
	section: string,
	communities: any,
	count: number,
	start: number,
	filter: string,
	nextStart: boolean,
	total: number,
	paginate: boolean
}

interface IProps {
	handleExperience: any,
	experiences: any,
	userID: any,
}
class AddandEditExperiance extends React.Component<Partial<IMapState> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			formData: clone(initialFormData),
			formErrors: [],
			loading: false,
			comLoading: false,
			resError: false,
			submitted: false,
			section: 'edit-experience-popup',
			communities: [],
			count: 100,
			start: 0,
			filter: '',
			nextStart: true,
			total: 0,
			paginate: true
		};
		this.handleLogoChange = this.handleLogoChange.bind(this);
		// this.handleChange = this.handleChange.bind(this);
		// this.handleSubmit = this.handleSubmit.bind(this);
		// this.handleCompanyChange = this.handleCompanyChange.bind(this);
		// this.paginateCommunities = this.paginateCommunities.bind(this);

	}

	getAllCommunities(query: string, start = 0, restart = false) {
		this.setState({ comLoading: true, filter: query });
		const { count, communities, total } = this.state,
			startCount = restart ? 0 : start * count;

		HttpWrapper.callUrl<ICommunityRequest, ICommunityResponse>(
			URLWrapper.urls.platformAdmin.community, HttpMethod.GET, {
			count: count,
			start: startCount,
			//@ts-ignore
			filter: query
		}, VCommunity)
			.then((response) => {
				let communityAll = [];
				if (response.content && response.content.length > 0) {
					communityAll = start === 0 ? response.content : [...communities, ...response.content];
				} else {
					communityAll = communities;
				}
				this.setState({
					comLoading: false,
					communities: communityAll,
					nextStart: response.nextStart,
					total: start === 0 ? response.count : total + response.count,
					paginate: (start === 0 && response.count < 30) ? false : true
				});
			}).catch((error) => {
				console.log(error);
			})
	}

	paginateCommunities(e: any, cnt) {
		if (!this.state.nextStart) {
			if (this.state.total <= cnt) {
				this.setState({ paginate: false })
			}
		} else {
			this.setState({ start: this.state.start + 1 })
			this.getAllCommunities(this.state.filter, this.state.start + 1);
		}
	}

	handleChange = (e: any) => {
		const { name, value } = e.target,
			{ formData } = this.state;

		formData[name] = (name === 'is_present') ? !formData.is_present : value;

		if (formData['from_from'] && formData['from_to'])
			formData['from'] = moment(join(' ', [formData['from_from'], formData['from_to']])).format('YYYY-MM-DD 00:00:00');
		if (formData['to_from'] && formData['to_to'])
			formData['to'] = formData['is_present'] ? 'present' : moment(join(' ', [formData['to_from'], formData['to_to']])).format('YYYY-MM-DD 00:00:00');

		this.setState({ formData });

		if (this.state.submitted)
			this.setState({ formErrors: validateProfileExperienceForm(formData) });
		console.log(this.state.formData, "experiences")
	}

	handleCompanyChange(selected: any) {
		const { formData } = this.state;
		this.setState({ formData: { ...formData, isEditable: true } })
		console.log(selected)
		if (!selected || selected.length === 0) {
			this.setState({ formData: { ...formData, company: [], defaultCompany: [] } });
			return;
		}
		const company = head(selected);

		if (company.customOption) {

			this.setState({
				formData: {
					...formData, defaultCompany: [company.name],
					company: company, communityId: '', communityName: company.name
				}
			});

		} else {
			this.setState({
				formData: {
					...formData, defaultCompany: [company.name],
					company: company, communityId: company.communityId, communityName: company.name
				}
			});
		}
	}

	handleSubmit = (e: any) => {
		e.preventDefault();

		const { formData } = this.state
		const errors = validateProfileExperienceForm(formData);

		this.setState({ submitted: true, formErrors: errors });

		if (!errors.hasError) {
			this.setState({ loading: true });

			const formFields = {
				title: formData.title,
				employmentType: formData.employmentType,
				communityName: formData.communityName,
				location: formData.location,
				startTime: formData.from ? moment(formData.from).valueOf() : formData.startTime,
				endTime: formData.is_present ? 0 : (formData.to ? moment(formData.to).valueOf() : formData.endTime),
				description: formData.description,
				media: formData.media,
				mediaId: formData.mediaId
			}

			if (!isEmpty(formData.mediaId)) {
				formFields['mediaId'] = formData.mediaId;
				formFields['media'] = formData.media;
			}

			if (!isEmpty(formData.communityId)) {
				formFields['communityId'] = formData.communityId;
			}

			if (!isEmpty(formData.experienceId)) {
				formFields['experienceId'] = formData.experienceId;
			}
			this.handleClose();
			this.props.handleExperience(formFields, formData);
		}
	}

	handleClose = () => {
		this.setState({ formErrors: [], submitted: false });
		document.getElementById('clsExperienceModal').click();
	}

	handleLogoChange(e: any) {
		const { formData, section } = this.state,
			file = e.target.files[0],
			fileType = file.type.split('/')[1];

		if (!contains(fileType, ['jpg', 'jpeg', 'png'])) {
			toastService.error('Sorry, this file type is not permitted for security reasons, it should be .jpg, .jpeg, .png');
			this.setState({ loading: false });
			return false;
		}

		// @ts-ignore
		if (parseInt(file.size / 1048576).toFixed(1) > 2) {
			toastService.error('The file is too large. Allowed maximum file size is 2 MB');
			this.setState({ loading: false });
			return false;
		}
		this.setState({ loading: true });
		// @ts-ignore
		HttpWrapper.uploadFile(document.getElementsByName('logo-upload')[0].files[0])
			.then((response) => {
				this.setState({
					formData:
						{ ...formData, mediaId: response.fileId, media: response.fileUrl },
					loading: false
				});
			}).catch((error) => {
				this.setState({ resError: true, submitted: false, loading: false });
				toastService.error(error.message, { id: section });
			})
	}

	setExperianceJson = (currentexp: any, index: number) => {
		const { formData } = this.state;
		formData.experienceId = currentexp.experienceId
		formData.title = currentexp.title
		formData.employmentType = currentexp.employmentType
		formData.company = { name: currentexp.communityName, communityId: currentexp.communityId || '', image: currentexp.media || '' }
		formData.defaultCompany = [{ name: currentexp.communityName, communityId: currentexp.communityId || '', image: currentexp.media || '' }]
		formData.communityId = currentexp.communityId || ''
		formData.communityName = currentexp.communityName
		formData.location = currentexp.location
		formData.startTime = currentexp.startTime
		formData.endTime = currentexp.endTime
		formData.from = ''
		formData.from_from = currentexp.startTime ? moment(currentexp.startTime).format('MMMM') : ''
		formData.from_to = currentexp.startTime ? String(moment(currentexp.startTime).year()) : ''
		formData.to = ''
		formData.to_from = currentexp.endTime ? moment(currentexp.endTime).format('MMMM') : ''
		formData.to_to = currentexp.endTime ? String(moment(currentexp.endTime).year()) : ''
		formData.description = currentexp.description
		formData.media = currentexp.media || ''
		formData.mediaId = ''
		formData.is_present = currentexp.endTime ? false : true
		formData.isEditable = false

		this.setState({ formData }, () => { document.getElementById(`openPop${index}`).click(); })
	}

	resetFormData = () => {
		this.setState({ formData: clone(initialFormData) }, () => { document.getElementById(`openAddNewExpPopup`).click(); });
	}

	render() {
		const { formData, formErrors } = this.state,
			year = reverse(range(1950, moment().year() + 1)),
			{ experiences } = this.props;
		console.log(experiences, 'formdataaaaa')
		return (
			<>
				<div className="form-group">
					<div className="card">
						<div className="card-body">
							<strong>Experience</strong>
							<div className="float-right">
								<button type="button" style={{ display: 'none' }} id="openAddNewExpPopup" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"></button>
								<button type="button" className="btn btn-primary" onClick={this.resetFormData}>
									<i className="fas fa-plus">Add New Experience</i>
								</button>

							</div>

							<div className="mt-5">
								<div className="float-right">
									<div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
										<div className="modal-dialog" role="document">
											<div className="modal-content" style={{ minWidth: '750px' }}>
												<div className="modal-header">
													<h5 className="modal-title" id="exampleModalLabel">{formData.experienceId ? 'Edit' : 'Add New'} Experience</h5>
													<button type="button" className="close" data-dismiss="modal" aria-label="Close" id="clsExperienceModal" onClick={this.handleClose}>
														<span aria-hidden="true">&times;</span>
													</button>

												</div>
												<div className="col-md-12 modal-body">

													<div className="form-row">
														<div className="form-group col-md-6">
															<label htmlFor="title">Title*</label>
															<input type="text" className="form-control input-lg"
																id="title" name="title"
																onChange={this.handleChange}
																value={formData.title}
															/>
															{formErrors.title && <div className="help-block" style={{ color: 'red' }}>{formErrors.title}</div>}
														</div>
														<div className="form-group col-md-6">
															<label htmlFor="employmentType">Employment type</label>
															<select name="employmentType"
																onChange={this.handleChange}
																className="form-control select-dropdown"
																value={formData.employmentType}>
																<option value="">Select your option</option>
																{employmentTypes.map((item, index) =>
																	(<option key={index} value={item.key}>{item.value}</option>))
																}

															</select>
														</div>
														<div className="form-group col-md-6">
															<label>Company*</label>
															<AsyncTypeahead
																allowNew
																newSelectionPrefix="Add a new company :  "
																isLoading={this.state.comLoading}
																labelKey="name"
																defaultSelected={formData.communityName ? [{ name: formData.communityName || '', communityId: formData.communityId || '', image: formData.media || '' }] : []}
																selected={formData.defaultCompany}
																minLength={2}
																maxResults={30}
																paginationText='Additional results...'
																onPaginate={(e, cnt) => this.paginateCommunities(e, cnt)}
																onChange={selected => {
																	this.setState({ formData: { ...formData, defaultCompany: selected } });
																	this.handleCompanyChange(selected);
																}}
																onSearch={query => { this.getAllCommunities(query, 0, true) }}
																options={this.state.communities}
																placeholder="Select your company"
																emptyLabel="No companies found"
																id={`compant${1}`}
															/>
															{formErrors.company && <div className="help-block" style={{ color: 'red' }}>{formErrors.company}</div>}
														</div>
														<div className="form-group col-md-6">
															<label>Location</label>
															<input type="text"
																className="form-control input-lg"
																onChange={this.handleChange}
																value={formData.location}
																name="location" placeholder="City, State, Country" />
														</div>

														<div className="form-group col-md-6 start-date form-group">
															<label>Start date</label>
															<div className="d-flex start-date-input">
																<select name="from_from" className="form-control select-dropdown mr-2"
																	onChange={this.handleChange}
																	value={formData.from_from}
																>
																	<option value="">Month</option>
																	<option value="January">January</option>
																	<option value="February">February</option>
																	<option value="March">March</option>
																	<option value="April">April</option>
																	<option value="May">May</option>
																	<option value="June">June</option>
																	<option value="July">July</option>
																	<option value="August">August</option>
																	<option value="September">September</option>
																	<option value="October">October</option>
																	<option value="November">November</option>
																	<option value="December">December</option>
																</select>
																<select name="from_to" className="form-control select-dropdown"
																	onChange={this.handleChange}
																	value={formData.from_to}
																>
																	<option value="">Year</option>
																	{year.map((y, i) => (<option value={y} key={i}>{y}</option>))}
																</select>
															</div>
														</div>

														<div className="form-group col-md-6 end-date form-group">
															<label>End date</label>
															<div className="d-flex end-date-input">
																<select name="to_from" className="form-control select-dropdown mr-2"
																	onChange={this.handleChange}
																	disabled={formData.is_present}
																	value={formData.to_from}
																>
																	<option value="">Month</option>
																	<option>January</option>
																	<option>February</option>
																	<option>March</option>
																	<option>April</option>
																	<option>May</option>
																	<option>June</option>
																	<option>July</option>
																	<option>August</option>
																	<option>September</option>
																	<option>October</option>
																	<option>November</option>
																	<option>December</option>
																</select>
																<select name="to_to" className="form-control select-dropdown" onChange={this.handleChange}
																	disabled={formData.is_present}
																	value={formData.to_to}
																// defaultValue={formData.to_to} onChange={this.handleChange} disabled={formData.is_present}
																>
																	<option value="">Year</option>
																	{year.map((y, i) => (<option key={i} value={y}>{y}</option>))}
																</select>
															</div>

															<div className="form-group col-md-6 checkbox-container mt-2">
																{!formData.is_present && formErrors.to && <div className="help-block">{formErrors.to}</div>}
																<div className="checkbox-container mt-2">
																	<div>
																		<input type="checkbox" className="mb-0" name="is_present" checked={formData.is_present ? true : false} onChange={this.handleChange}></input>
																		<label htmlFor="checkbox" className="mb-0">Present</label>
																	</div>
																</div>
															</div>
														</div>
														<div className="form-group col-md-12 description">
															<label>Description</label>
															<textarea value={formData.description}
																className="form-control input-lg"
																onChange={this.handleChange} name="description" placeholder="Enter description"></textarea>
														</div>
														<div className="form-row" style={{ display: "contents" }}>
															{formData.company && formData.company.customOption && !isEmpty(formData.company.customOption) && <div className="form-group col-md-6 upload-media height-40">
																{!formData.media && <label className="">Upload Logo Image</label>}
																<input type="file" className="form-control-file border-0"
																	id="inputUploadlogo"
																	onChange={this.handleLogoChange}
																	name="logo-upload"
																/>
																{formData.media && formData.mediaId &&
																	<label className="d-block p-2 mt-2 upload-tiggerBtn border-0">{getFileName(formData.media)}</label>
																}
															</div>}
														</div>

													</div>
												</div>
												<div className="modal-footer">
													<button type="button" style={{ display: 'none' }} className="btn btn-secondary" data-dismiss="modal" id="clsExperienceModal">Close</button>
													<button type="button" className="btn btn-secondary" onClick={this.handleClose}>Close</button>
													<button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Ok</button>
												</div>
											</div>
										</div>
									</div>
								</div>



								<div className="ml-2" >
									{
										experiences && experiences.map((eachExperiences: any, index: number) => {

											return (
												<div className="d-flex mb-5 " key={index}>
													{/* <img style={{ width: '68px', height: '68px' }}
														src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAe1BMVEWYAS7///+QAA/o0NWTABrXsbeWACaWACixXm2YAC6XACuVACKpSVyUAB/HkZuSABK+fIjhxcqeHz3q19uOAADRpq6SABiPAAiPAAD27O/Ag4789/m5cX7ev8X16u2fJkHZtrytVGWaFDO6doHKmaGiMkmoRlmkOk+0Z3QQE08iAAAC2UlEQVR4nO3a7XKiMBiGYVcpSJCigqBY60er6/kf4e6MCEENJkKhOvf1O6+TBzCBJL0eAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4ccKLg74G57LQtrTqxEWZ6/ixsnEQe5fta3LtaJ1uBhrCUSmiiK3jbKJTNy112fWiw/tK3XyT7ue222DCZBv+0TS0pX764k23rpTQidLFvYJwGDV2Hx1rpdvPUkI3GevXyQm99adOycS6+ks8GHB393reTOgm2jewnNDfatYsdo1EFLZJwCKhWUApoT3SLlrYDTyobqT9Fywn9A0eUTmhEAZVYVR/uPFTo47mCYVjVpcnTCYmZalfO2F/adbTc0LL6BktEjp/jcqW/boB7Q+zjuYJA8Mrc04Yb8zqPmx157UE0kQRvt23OZx6ag+LuuVGoy5L6EbSlZmoWktDwyqoF9BNipnpK4qt+7Lx2//O6wZaddktFOviwuwTRds4+spbfSY1xxorv6SryKROetgCkxHdKaaKsaVuFuWP1rKilQ5p6B57JoVBPiKGiUmd9HSvK66MV0xFot6UKI35Dyc0Gu2khFO9hM4PJBROhazNUycU+1GF6anRUyesnsuz+fC5E85I2COhGgkzJNTXZcKe5Sv1ixfTJ074/V6heO194vlQUzvvNIftSyW88V5q714r4Y1m0+dNqPd9WJnw0e/DdhLK3/hzdavrhI9+47eeUF6nOarXWwL3MqG0TrPSWqfxOkpYWmsbVCyVXSaUe7rQWWtLvY4StrZeOgk6Stjamvd1QpEESvPiwtdO2Na+xXXCqXCVmnvzbm/v6VZCda+aTPj/ZrSyf9hhwpb2gLtM2M4+fqcJWzmL8XDCmqv6567++Hmawey0meuMwtsnbMrs47nZoLEjJz99Juq8heQoT0mVfzU/HtVUQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA8Nv8A+cMRt74FGkyAAAAAElFTkSuQmCC'}
													/> */}
													<ProfileImage url={eachExperiences.media} alt={eachExperiences.mediaId} defaultClass="profile-user-img img-fluid img-square" imageType="circle" imageSize="xl" />
													<div style={{ display: 'grid', marginLeft: 5 }}>
														{/* <strong>{eachExperiences.title}</strong> */}
														<span>{eachExperiences.communityName}</span>
														<span>{eachExperiences.endTime !== 0 && getMonthDiff(eachExperiences.startTime, eachExperiences.endTime)}</span>
														<span>{eachExperiences.location}</span>
														<br />
													</div>
													<div className="ml-auto">
														<i className="fa fa-edit" onClick={() => this.setExperianceJson(eachExperiences, index)}>Edit</i>
														<i className="fa fa-edit" style={{ display: 'none' }} id={`openPop${index}`} data-toggle="modal" data-target="#exampleModal">Edit</i>
													</div>


												</div>
											)
										})
									}


								</div>

							</div>

						</div>
					</div>
				</div>





			</>
		)
	}
}

export default withRouter(AddandEditExperiance)

interface IMapState {
	global: any,
}

function mapState(state): IMapState {
	console.log(state, "state")
	const { global } = state;
	return { global };
}



const connectedAddandEditExperiance = withRouter(connect(mapState)(AddandEditExperiance));
export { connectedAddandEditExperiance as AddandEditExperiance };