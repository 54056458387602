import { IReducerAction } from "./actionInterface"
import { IndustriesActionEnum, ExpertisesActionEnum, ClassificationActionEnum, SpecialitiesActionEnum, InterestsActionEnum, AffilatedTagsActionEnum, FeatureActionEnum } from "../../constants/enums/globalActionEnum"

export type IGlobalReduxAction = IReducerAction<any> & {
	list?: any
}

//INDUSTRIES
interface IIndustriesResponseState {
	industryId: string,
	name: string
}

export type IIndustriesReduxAction = IReducerAction<IndustriesActionEnum> & {
	list: IIndustriesResponseState
}

//EXPERTISES
interface IExpertisesResponseState {
	id: string,
	name: string
}

export type IExpertisesReduxAction = IReducerAction<ExpertisesActionEnum> & {
	list: IExpertisesResponseState
}

//CLASSIFICATION
interface IClassificationResponseState {
	id: string,
	name: string
}

export type IClassificationReduxAction = IReducerAction<ClassificationActionEnum> & {
	list: IClassificationResponseState
}

//SPECIALITIES
interface ISpecialitiesResponseState {
	id: string,
	name: string
}

export type ISpecialitiesReduxAction = IReducerAction<SpecialitiesActionEnum> & {
	list: ISpecialitiesResponseState
}

//INTERESTS
interface IInterestsState {
	id: string,
	name: string,
	category: string
}

//AFFILATED_TAGS
interface IAffilatedState {
	id: string,
	name: string
}

export type IAffilatedReduxAction = IReducerAction<AffilatedTagsActionEnum> & {
	list: IAffilatedState
}

export type IInterestsReduxAction = IReducerAction<InterestsActionEnum> & {
	list: IInterestsState[]
}

//ALLFEATURE
interface IAFeatureState {
	id: string,
	name: string
}

export type IFeatureReduxAction = IReducerAction<FeatureActionEnum> & {
	list: IAFeatureState[]
}

//GLOBAL
export interface IGlobalCombinedReduxState {
	industries: { list: IIndustriesResponseState[] },
	expertises: { list: IExpertisesReduxAction[] },
	allClassification: { list: IClassificationReduxAction[] },
	allSpecialities: { list: ISpecialitiesReduxAction[] },
	allInterests: { list: ISpecialitiesReduxAction[] }
	AllAffilatedtags: { list: IAffilatedReduxAction[] }
	AllFeature: { list: IFeatureReduxAction[] }
}

export const globalInitialReduxState: IGlobalCombinedReduxState = {
	industries: { list: [] },
	expertises: { list: [] },
	allClassification: { list: [] },
	allSpecialities: { list: [] },
	allInterests: { list: [] },
	AllAffilatedtags: { list: [] },
	AllFeature: { list: [] },
}