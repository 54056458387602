import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { history } from '../helpers';
import { PublicLayout, PrivateLayout } from '../components/layout'
import {
	HomePage,
	LoginPage,
	CommunitiesList,
	NewCommunitiesList,
	CommunityAdd,
	ForgotPassword,
	Configure,
	UserList,
	AddUser,
	IndustryList
} from '../components/pages';

class App extends React.Component {
	constructor(props) {
		super(props);

		history.listen((location, action) => {
			// clear alert on location change
		});
	}

	render() {
		return (
			<>
				<ToastContainer className="toast-block" />
				<Router history={history}>
					<Switch>
						<PublicLayout path="/login" component={LoginPage} />
						<PublicLayout path="/forgotpassword" component={ForgotPassword} />

						<PrivateLayout path="/home" component={HomePage} />
						<PrivateLayout path="/communities" component={CommunitiesList} />
						<PrivateLayout path="/new-communities" component={NewCommunitiesList} />
						<PrivateLayout path="/community-add" component={CommunityAdd} />
						<PrivateLayout path="/community/:id" component={CommunityAdd} />
						<PrivateLayout path="/configure" component={Configure} />
						<PrivateLayout path="/user-list" component={UserList} />
						<PrivateLayout path="/user-add" component={AddUser} />
						<PrivateLayout path="/user/:id" component={AddUser} />
						<PrivateLayout path="/industries" component={IndustryList} />
						<Redirect from="*" to="/login" />
					</Switch>
				</Router>
			</>
		);
	}
}

export default App;