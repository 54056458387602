import React from 'react';
import './Accordion.css';

class Accordion extends React.Component {
	render() {
		return (
			<div className="container-fluid pr-0 pl-0">
				<section className="d-flex flex-column">
					<div className="my-auto">
						<div id="accordion" role="tablist" aria-multiselectable="true" >
							<div className="card">
								<div className="card-header" role="tab" id="accordionHeadingOne">
									<div className="mb-0 row">
										<div className="col-12 no-padding accordion-head">
											<a data-toggle="collapse" data-parent="#accordion" href="#accordionBodyOne" aria-expanded="true" aria-controls="accordionBodyOne"
												className="collapsed ">
												<i className="fa fa-angle-down" aria-hidden="true"></i>
												<h3 className="c-black" style={{ color: 'black', fontSize: '16px' }}>Search</h3>
											</a>
										</div>
									</div>
								</div>

								<div id="accordionBodyOne" className="collapse show" role="tabpanel" aria-labelledby="accordionHeadingOne" aria-expanded="false" data-parent="#accordion">
									<div className="card-block col-12">
										{this.props.children}

									</div>
								</div>
							</div>

						</div>

					</div>
				</section>



			</div>
		)
	}
}

export default Accordion;