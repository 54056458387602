import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NavLink from './NavLink';
import { contains } from 'ramda';
import { history, authHeader } from '../../../helpers';
import { globalActions } from '../../../actions/';
import { HttpWrapper, HttpMethod } from '../../../helpers/httpWrapper';
import { IProfileInterestGetAllResponse } from '../../../networked-shared-interfaces/profile-routes/sub-routes/profile-interest-route';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import { IIndustriesResponse, IClassificationResponse } from '../../../networked-shared-interfaces/global-routes/constant-routes';
import { IProfileExpertiseGetAllResponse } from '../../../networked-shared-interfaces/profile-routes/sub-routes/profile-expertise-routes';
import { ISpecialitiesRequest, ISpecialitiesResponse } from '../../../networked-shared-interfaces/global-routes/specialities-routes';
import { ITagRequest, ITagResponse } from '../../../networked-shared-interfaces/global-routes/tag-routes';
import { IFeatureRequest, IFeatureResponse, VFeature } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/feature';
import { cache } from '../../../helpers/cacheHandler';

interface IState {
	loading: boolean
}

class Header extends React.Component<Partial<IDispatchState>, IState> {
	constructor(props: Readonly<Partial<IDispatchState>>) {
		super(props);

		this.state = {
			loading: false
		};
	}

	componentDidMount() {
		this.getAllInterests();
		this.getAllIndustries();
		this.getAllExpertises();
		this.getAllSpecialities();
		this.getAllClassifications();
		this.getAllAffilatedTags();
		this.getAllFeature()
	}

	getAllFeature = () => {
		HttpWrapper.callUrl<IFeatureRequest, IFeatureResponse>(URLWrapper.urls.platformAdmin.feature, HttpMethod.GET, {
		}, VFeature)
			.then((response: any) => {
				this.props.saveFeature(response);
			}).catch((error) => {
				//Custom Error Handler
				console.log("error in get all Feature ", error)
			})
	}

	getAllClassifications = () => {
		HttpWrapper.callUrl<any, IClassificationResponse>(URLWrapper.urls.globalRoutes.classifications, HttpMethod.GET, {

		}, {}, authHeader())
			.then((response) => {
				this.props.saveClassifications(response);
			}).catch((error) => {
				//Custom Error Handler
				console.log("error in get all interests ", error)
			})
	}

	getAllInterests = () => {
		HttpWrapper.callUrl<any, IProfileInterestGetAllResponse>(URLWrapper.urls.profile.interestsAll, HttpMethod.GET, {
		}, {}, authHeader())
			.then((response) => {
				this.props.saveIntrestsList(response);
			}).catch((error) => {
				//Custom Error Handler
				console.log("error in get all interests ", error)
			})
	}

	getAllIndustries = () => {
		HttpWrapper.callUrl<any, IIndustriesResponse>(URLWrapper.urls.globalRoutes.industries, HttpMethod.GET, {
		}, {}, authHeader())
			.then((response) => {
				this.props.saveIndustriesList(response);
			}).catch((error) => {
				//Custom Error Handler
				console.log("error in get all interests ", error)
			})
	}

	getAllExpertises = () => {
		HttpWrapper.callUrl<any, IProfileExpertiseGetAllResponse>(URLWrapper.urls.profile.expertiseAll, HttpMethod.GET, {
		}, {}, authHeader())
			.then((response) => {
				this.props.saveExpertisesList(response);
			}).catch((error) => {
				//Custom Error Handler
				console.log("error in get all Expertises ", error)
			})
	}

	getAllSpecialities = () => {
		HttpWrapper.callUrl<ISpecialitiesRequest, ISpecialitiesResponse>(URLWrapper.urls.globalRoutes.specialities, HttpMethod.GET, {
		}, {}, authHeader())
			.then((response) => {
				this.props.saveSpecialitiesList(response);
			}).catch((error) => {
				//Custom Error Handler
				console.log("error in get all Specialities ", error)
			})
	}

	getAllAffilatedTags = () => {
		HttpWrapper.callUrl<ITagRequest, ITagResponse>(URLWrapper.urls.globalRoutes.tags, HttpMethod.GET, {
		}, {}, authHeader())
			.then((response) => {
				this.props.saveAffilatedTags(response);
			}).catch((error) => {
				console.log("error in get all interests ", error)
			})
	}

	logout() {
		localStorage.removeItem('user');
		localStorage.removeItem('sessionToken');
		localStorage.removeItem('communityToken');
		localStorage.removeItem('status');
		cache.setupCache();
		history.push('/login');
	}

	render() {
		return (
			<>
				<nav className="main-header navbar navbar-expand navbar-white navbar-light">

					<ul className="navbar-nav">
						<li className="nav-item">
							<a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
						</li>
					</ul>
					<ul className="navbar-nav ml-auto">
						<li className="nav-item">
							<a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button"><i
								className="fas fa-th-large"></i></a>
						</li>
					</ul>
				</nav>

				<aside className="main-sidebar sidebar-dark-primary elevation-4">

					<a href="/home" className="brand-link">
						<img src="../dist/img/networked-logo.png" alt="Networked Logo" className="brand-image elevation-3" style={{ "opacity": .8 }} />
						{/* <span className="brand-text font-weight-light">Networked Admin</span> */}
					</a>

					<div className="sidebar">
						<div className="user-panel mt-3 pb-3 mb-3 d-flex">
							<div className="image">
								<img src="../dist/img/platformadmin.jpg" className="img-circle elevation-2" alt="User Image" />
							</div>
							<div className="info">
								<a href="/home" className="d-block">Platform Admin</a>
							</div>
						</div>

						<nav className="mt-2">
							<ul className="nav nav-pills nav-sidebar flex-column nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
								<li className="nav-item has-treeview menu-open">
									<a href="#" className="nav-link">
										<i className="nav-icon fas fa-users"></i>
										<p>
											Communities
											<i className="right fas fa-angle-left"></i>
										</p>
									</a>
									<ul className="nav nav-treeview">
										<li className="nav-item">
											<NavLink clsname="nav-link" to="/communities" isActive={(match, location) => contains('/communities', location.pathname)}>
												<i className="far fa-circle nav-icon"></i>
												<p>List</p>
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink clsname="nav-link" to="/new-communities">
												<i className="far fa-circle nav-icon"></i>
												<p>New Requests</p>
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink
												clsname="nav-link"
												to="/community-add"
												activehref='/community/'
												isActive={(match, location) => {
													// const demoClasses = document.querySelectorAll(".leftMenu");
													// demoClasses.forEach(element => {
													// 	if (element.classList.contains("active") && !contains('/community/', location.pathname)) {
													// 		element.classList.remove('active');
													// 		console.log(demoClasses, 'demoClassesssss')
													// 	}
													// });
													return contains('/community/', location.pathname) || contains('/community-add', location.pathname);
												}}
											>
												<i className="far fa-circle nav-icon"></i>
												<p>Add Community</p>
											</NavLink>
										</li>
									</ul>
								</li>

								<li className="nav-item has-treeview menu-open">
									<a href="#" className="nav-link">
										<i className="nav-icon fas fa-users"></i>
										<p>
											Users
											<i className="right fas fa-angle-left"></i>
										</p>
									</a>
									<ul className="nav nav-treeview">
										<li className="nav-item">
											<NavLink clsname="nav-link" to="/user-list" isActive={(match, location) => contains('/user-list', location.pathname)}>
												<i className="far fa-circle nav-icon"></i>
												<p>List</p>
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink clsname="nav-link" to="/user-add" activehref='/user/' isActive={(match, location) => contains('/user-add', location.pathname) || contains('/user/', location.pathname)}>
												<i className="far fa-circle nav-icon"></i>
												<p>Add User</p>
											</NavLink>
										</li>
									</ul>
								</li>

								<li className="nav-item has-treeview menu-open">
									<a href="#" className="nav-link">
										<i className="nav-icon fas fa-users"></i>
										<p>
											Global
											<i className="right fas fa-angle-left"></i>
										</p>
									</a>
									<ul className="nav nav-treeview">
										<li className="nav-item">
											<NavLink clsname="nav-link" to="/industries" isActive={(match, location) => contains('/industries', location.pathname)}>
												<i className="far fa-circle nav-icon"></i>
												<p>Industries</p>
											</NavLink>
										</li>
									</ul>
								</li>

								<li className="nav-item">
									<NavLink clsname="nav-link" to="/configure">
										<i className="nav-icon fas fa-cogs"></i>
										<p>Configure</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<a href="/" className="nav-link" onClick={this.logout}>
										<i className="nav-icon fa fa-power-off"></i>
										<p>Logout</p>
									</a>
								</li>
							</ul>
						</nav>

					</div>

				</aside>

			</>
		);
	}
}

interface IDispatchState {
	saveIndustriesList: (p1: any) => any,
	saveExpertisesList: (p1: any) => any,
	saveSpecialitiesList: (p1: any) => any,
	saveClassifications: (p1: any) => any,
	saveIntrestsList: (p1: any) => any,
	saveAffilatedTags: (p1: any) => any,
	saveFeature: (p1: any) => any,
}

//Common api calls need to maitain allover the app
const actionCreators: IDispatchState = {
	saveExpertisesList: globalActions.saveExpertisesList,
	saveIndustriesList: globalActions.saveIndustriesList,
	saveClassifications: globalActions.saveClassifications,
	saveSpecialitiesList: globalActions.saveSpecialitiesList,
	saveIntrestsList: globalActions.saveIntrestsList,
	saveAffilatedTags: globalActions.saveAffilatedTags,
	saveFeature: globalActions.saveFeature
}

const connectedPrivateHeader: any = connect(null, actionCreators)(Header);
export { connectedPrivateHeader as Header };