import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { history } from '../helpers';
import { isString, isArray } from 'util';
import { cache } from './cacheHandler';
import { URLWrapper } from "../networked-shared-interfaces/url-wrapper";
import { ValidationUtility } from "../networked-shared-interfaces/validation-utility";
import { IValidatorInterface } from "../networked-shared-interfaces/validator-interface";
import { ResponseCodes } from "../networked-shared-interfaces/response-codes";
import { IUploadFileResponse } from '../networked-shared-interfaces/file-routes/file-routes';

const cacheUrls = [
	'/api/v1/global/classifications/all',
	"/api/v1/global/industries/all",
	"/api/v1/global/comunities/all",
	"/api/v1/global/eventtypes/all",
	"/api/v1/global/jobtypes/all",
	"/api/v1/global/roundtypes/all",
	"/api/v1/global/salarydurationtypes/all",
	"/api/v1/global/salarytypes/all",
	"/api/v1/global/startuptypes/all",
	"/api/v1/global/currencies/all",
	"/api/v1/global/specialities/all"
];

function isURLInCacheList(url: string) {
	return cacheUrls.includes(url);
}

function requestHandler(request: AxiosRequestConfig) {
	if (request.method.toLowerCase() === 'get') {
		const checkIsValidResponse = cache.isValid(request.url || '');
		if (checkIsValidResponse.isValid) {
			request.headers.cached = true;
			request.data = JSON.parse(checkIsValidResponse.value || '{}');
			return Promise.reject(request);
		}
	}
	return request;
}

function responseHandler(response: AxiosResponse<any>): AxiosResponse<any> {
	if (response.config.method.toLowerCase() === 'get') {
		if (response.config.url && isURLInCacheList(response.config.url)) {
			cache.store(response.config.url, JSON.stringify(response.data));
		}
	}
	return response;
}

function errorHandler(error: any) {
	if (error.headers && error.headers.cached === true) {
		return Promise.resolve(error);
	}
	return Promise.reject(error);
}

axios.interceptors.request.use((request) => requestHandler(request));
axios.interceptors.response.use(
	(response) => responseHandler(response),
	(error) => errorHandler(error),
);

export enum HttpMethod {
	GET,
	POST,
	PUT,
	DELETE
}

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`; //env variable
axios.defaults.baseURL = BASE_URL;

const handleResponse = (response) => {
	const rBody = response.data;

	if (response.status === 200) {
		if(response.headers['content-type'].startsWith("text/csv")) {
			return rBody;
		}
		return rBody.data
	} else if (response.status === ResponseCodes.sessionTokenError) {
		//Session Handler
		localStorage.removeItem('user');
		localStorage.removeItem('sessionToken');
		localStorage.removeItem('communityToken');
		history.push('/login');
	} else {
		return Promise.reject(rBody)
	}
}

const handleError = (error, reject, resolve) => {
	if (error.error !== undefined && error.error === false && error.data !== undefined) {
		return resolve(error.data);
	}

	if (axios.isCancel(error)) {
		//cancel previous request based on param
		return reject({ message: error.message, status: 1001 });
	}

	if (!error.response)
		return reject({ message: 'Server error' });

	let rBody = error.response;

	if (error.response.status === 404)
		rBody.message = 'Error: Invalid API Request';
	else
		rBody.message = (rBody && rBody.data && typeof (rBody.data.message) === 'string') ? rBody.data.message : 'Error: Invalid Request';

	if (error.response.status === ResponseCodes.sessionTokenError) {
		//Session Handler
		localStorage.removeItem('user');
		localStorage.removeItem('sessionToken');
		localStorage.removeItem('communityToken');
		history.push('/login');
	} else {
		return reject({ ...rBody, status: error.response.status });
	}
}

export class HttpWrapper {

	private static getDefaultHeader() {
		return {
			sessionToken: localStorage.getItem("sessionToken"),
			communityToken: localStorage.getItem("communityToken")
		}
	}

	public static formAbsoluteURL(url: string): string {
		if (!url || !isString(url))
			return "";
		if (url.startsWith("http://") || url.startsWith("https://"))
			return url;
		return BASE_URL + url
	}

	public static callUrl<TReq, TRes>(url: string, method: HttpMethod, body: TReq, validator: IValidatorInterface, optionalHeader: any = {}, cancelToken: any = undefined, ): Promise<TRes> {
		return new Promise<TRes>((resolve, reject) => {
			if (validator !== undefined) {
				let mReq = ValidationUtility.wrapper(body, validator)
				if (!mReq.validState) {
					reject({
						error: true,
						message: mReq.errorMessage
					});
					return;
				}
			}

			const stripeRoutes = Object.values(URLWrapper.urls.stripe);

			if (stripeRoutes.includes(url)) {
				url = "/stripe" + url;
			} else {
				url = URLWrapper.formAppV1Url(url);
			}
			if (method === HttpMethod.GET || method === HttpMethod.DELETE) {
				let first = true;
				let b: any = body;
				for (let i in b) {
					if (b[i] === undefined) {
						continue
					}
					if (isArray(b[i]) && b[i].length === 0)
						continue
					if (first) {
						url += "?"
						first = false;
					}
					else
						url += "&"


					if (isArray(b[i])) {
						for (let j in b[i]) {
							url += encodeURIComponent(i) + "[" + j + "]" + "=" + encodeURIComponent(b[i][j])
							//@ts-ignore
							if (j < (b[i].length - 1)) {
								url += "&"
							}
						}
					} else {
						url += encodeURIComponent(i) + "=" + encodeURIComponent(b[i]);
					}

				}
			}
			let headers = { ...HttpWrapper.getDefaultHeader(), ...optionalHeader }

			//axios call
			switch (method) {
				case HttpMethod.GET:
					axios.get(url, { headers: headers, cancelToken: cancelToken ? cancelToken : '' })
						.then(handleResponse)
						.then(data => {
							resolve(data);
						})
						.catch(error => handleError(error, reject, resolve))
					break;
				case HttpMethod.POST:
					axios.post(url, body, { headers: headers })
						.then(handleResponse)
						.then(data => {
							resolve(data);
						})
						.catch(error => handleError(error, reject, resolve))
					break;
				case HttpMethod.PUT:
					axios.put(url, body, { headers: headers })
						.then(handleResponse)
						.then(data => {
							resolve(data);
						})
						.catch(error => handleError(error, reject, resolve))
					break;
				case HttpMethod.DELETE:
					axios.delete(url, { headers: headers })
						.then(handleResponse)
						.then(data => {
							resolve(data);
						})
						.catch(error => handleError(error, reject, resolve))
					break;
				//TODO All other Method
			}

		})
	}

	public static uploadFile(file: any, isPrivate: number = 0, optionalHeader?: any, fileProgress?: any): Promise<IUploadFileResponse> {
		return new Promise<IUploadFileResponse>((resolve, reject) => {

			let url = URLWrapper.formFileV1Url(URLWrapper.urls.fileUrl.url);

			let headers = { ...HttpWrapper.getDefaultHeader(), ...optionalHeader }

			let form = new FormData();
			form.append("file", file);
			form.append("isPrivate", String(isPrivate));

			const config = {
				headers: headers,
				onUploadProgress: fileProgress
			};

			axios.post(url, form, config)
				.then(handleResponse)
				.then(data => {
					resolve(data);
				})
				.catch(error => handleError(error, reject, resolve))
		})
	}
}