import React from 'react';
import { toastService } from '../../../services/toast.service';
import { isEmpty } from 'ramda';
import { HttpMethod, HttpWrapper } from '../../../helpers/httpWrapper';
import { ICommunityUploadRequest } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/upload';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';

interface IState {
	loading: boolean,
	bulkLoading: boolean,
	errors?: any
	fileName?: string,
	imageName?: string
	file?: any,
}

class Bulkupload extends React.Component<any, IState> {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			bulkLoading: false,
			fileName: "",
			imageName: '',
			file: '',
		};
	}

	uploadBulk = (e) => {
		this.setState({ bulkLoading: true });
		let file = e.target.files[0];
		if (isEmpty(file)) return;

		let checkfileType = file.type.split('.')[1] || file.name.split('.')[1]
		if (checkfileType === "csv" || checkfileType === 'ms-excel') {
			let form = new FormData();
			form.append("file", file);
			this.setState({ bulkLoading: false, fileName: file.name, imageName: file.name, file: file });
		} else {
			toastService.error('Only csv/xslx files are allowed!');
			this.setState({ bulkLoading: false })
		}
	}

	submitFile = (e) => {
		e.preventDefault()
		let form = new FormData();
		form.append("file", this.state.file);
		if (this.state.file) {
			this.setState({ bulkLoading: true });
			HttpWrapper.callUrl<any, ICommunityUploadRequest>(URLWrapper.urls.platformAdmin.uploadCommunity, HttpMethod.POST, form, undefined)
				.then((uploadBulkResponse: any) => {
					this.setState({ fileName: '', bulkLoading: false });
					toastService.success(uploadBulkResponse)

				})
				.catch((error) => {
					this.setState({ bulkLoading: false });
					toastService.error(error.message)
				})
		}
	}

	removeFile = () => {
		const { fileName } = this.state;
		this.setState({ fileName: this.state.fileName.slice(fileName.length), imageName: '' });
	}

	render() {
		return (
			<>
				<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
					Bulk Upload
				</button>
				<div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">Bulk Upload Communities</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body d-flex">
								<div>
									<label className="btn btn-primary my-file-selector-btn" htmlFor="my-file-selector">
										<i className="fa fa-upload" aria-hidden="true"></i><input id="my-file-selector" type="file" accept=".csv" multiple className="d-none" onChange={(e) => this.uploadBulk(e)} />     Upload  file
                                </label>
									<div className="border-0 ml-2 cart-heading1 rounded text-left mh-100 w-100 upld-filename">
										{this.state.bulkLoading ? <div className="spinner-border m-5" role="status">
											<span className="sr-only">Loading...</span>
										</div> : <> {this.state.fileName ? this.state.fileName : 'No file chosen'}
												<button type="button" className="close border-0" aria-label="Close" onClick={this.removeFile} style={{ display: isEmpty(this.state.fileName) ? 'none' : ' block' }}>
													<span aria-hidden="true">&times;</span>
												</button>

											</>
										}

									</div>
								</div>
								<div className="col-sm-6">
									<a href="assets/doc/community.csv" download="community.csv">
										<button type="button" value="Download" className="btn btn-primary float-left ml-2 download-sample">
											<i className="fa fa-download" aria-hidden="true"></i> &nbsp;&nbsp; Download sample file</button>
									</a>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-se condary" data-dismiss="modal">Close</button>
								<button type="button" className="btn btn-primary" onClick={this.submitFile} data-dismiss="modal">Submit</button>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}
export default Bulkupload;