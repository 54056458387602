import React from 'react';
import { Link } from 'react-router-dom';
import { pluck } from 'ramda';
import Pagination from "react-js-pagination";
import { HttpWrapper, HttpMethod } from '../../../helpers/httpWrapper';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import DeleteCareerOpportunities from './Delete';
import Searchbox from './Search';
import { ICommunityRequest, ICommunityResponse, VCommunity, ICommunityUpdateRequest, ICommunityUpdateResponse, VCommunityUpdate } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/community';
import { getMonthDiff } from './helper/Timestap';
import { toastService } from '../../../services';
import { Loader } from '../common/Loader';
import { ModelStatus } from '../../../networked-shared-interfaces/constants/model-status';
import { CommunityVisiblity } from '../../../networked-shared-interfaces/constants/community-visiblity';

interface IState {
	loading: boolean,
	communityStatusLoading: boolean,
	count: number,
	totalCount: number,
	paged: number,
	filter: {
		query: string,
		status: ModelStatus,
		layer: number,
		visibility: CommunityVisiblity,
		classification: any
	},
	list: any[],
	currentPage: number,
	downloadContent: any,
	currentEditCommunity: string,
	currentDeleteCommunity: string
}

class NewCommunitiesList extends React.Component<any, IState> {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			communityStatusLoading: false,
			count: 10,
			totalCount: 0,
			paged: 1,
			list: [],
			filter: {
				query: undefined,
				status: undefined,
				layer: undefined,
				visibility: undefined,
				classification: []
			},
			currentPage: 1,
			downloadContent: '',
			currentEditCommunity: '',
			currentDeleteCommunity: ''
		};
	}

	componentDidMount() {
		this.getCommunities();
	}

	getCommunities = () => {
		const { paged, count, filter: { query, layer, visibility, classification } } = this.state;
		this.setState({ loading: true });
		HttpWrapper.callUrl<ICommunityRequest, ICommunityResponse>(URLWrapper.urls.platformAdmin.community,
			HttpMethod.GET, {
			start: (paged - 1) * count,
			count: count,
			query: query,
			status: ModelStatus.Waiting,
			layer: layer,
			visibility: visibility,
			classification: classification.length > 0 ? pluck(['classificationId'], classification).join(',') : undefined
		}, VCommunity)
			.then((response) => {
				this.setState({ loading: false, list: response.content, totalCount: response.totalCount });
			}).catch((error) => {
				this.setState({ loading: false });
				toastService.error(error.message);
			})
	}

	changeCommunityStatus = (communityId: any, status: any) => {
		this.setState({ communityStatusLoading: true, currentEditCommunity: communityId });
		HttpWrapper.callUrl<ICommunityUpdateRequest, ICommunityUpdateResponse>
			(URLWrapper.urls.platformAdmin.community, HttpMethod.PUT, {
				communityId: communityId,
				status: status
			}, VCommunityUpdate)
			.then((response: any) => {
				this.setState({ communityStatusLoading: false, currentEditCommunity: '' }, () => this.getCommunities());
				toastService.success("Success");
			}).catch(error => {
				this.setState({ communityStatusLoading: false });
				toastService.error(error.message);
			})
	}

	handleFilterChange = (field, value) => {
		const { filter } = this.state;
		filter[field] = value;

		this.setState({ filter: filter });
	}

	handleReset = () => {
		this.setState({
			filter: {
				query: undefined,
				status: undefined,
				layer: undefined,
				visibility: undefined,
				classification: []
			}
		}, () => this.handleSearch())
	}

	handleSearch = () => {
		this.setState({
			paged: 1,
			list: []
		}, () => this.getCommunities());
	}

	paginate = (paged) => {
		this.setState({ currentPage: paged, paged: paged }, () => this.getCommunities());
	}

	render() {
		const {
			list, loading, count, totalCount, currentPage, communityStatusLoading,
			currentEditCommunity, currentDeleteCommunity
		} = this.state;

		return (
			<>
				<div className="content-wrapper">
					<section className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<div className="col-sm-6">
									<h1>New Community Requests</h1>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<li className="breadcrumb-item"><Link to="/home">Home</Link></li>
										<li className="breadcrumb-item active">Community Requests</li>
									</ol>
								</div>
							</div>
						</div>
					</section>
					<section className="content">
						<Searchbox
							handleFilterChange={this.handleFilterChange}
							handleSearch={this.handleSearch}
							handleReset={this.handleReset}
							showStatus={false}
						/>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Communities ({totalCount})</h3>
								<div className="card-tools">
									<div className='d-flex'>
										{list.length > 0 && !loading && <div className="mr-1 mt-1">
											<Pagination
												activePage={currentPage}
												itemsCountPerPage={count}
												totalItemsCount={totalCount}
												pageRangeDisplayed={count}
												onChange={this.paginate}
												itemClass="page-item"
												linkClass="page-link"
											/>
										</div>}
									</div>
								</div>
							</div>
							<div className="card-body p-0">
								{loading && <Loader />}
								{list.length === 0 && !loading && <div className="alert alert-info alert-dismissible">
									<h5><i className="icon fas fa-info"></i> No communities Found!</h5>
								</div>}
								{list.length > 0 && !loading && <table className="table table-striped projects">
									<thead>
										<tr>
											<th style={{ "width": "20%" }}> Community Name </th>
											<th style={{ "width": "20%" }}> Community Classification </th>
											<th style={{ "width": "20%" }}> Requested Date</th>
											<th style={{ "width": "20%" }}> Assigned Date </th>
											<th style={{ "width": "20%" }}> Status </th>
										</tr>
									</thead>
									<tbody>
										{list && list.length > 0 && list.map((community: any, index) => <tr key={index}>
											<td>{community.name}</td>
											<td>{community.classifications.map((name: any, index: number) => <p key={index}>{name.name}</p>)}</td>
											<td><span>{getMonthDiff(community.createTimestamp, undefined)}</span></td>
											<td><span>{getMonthDiff(community.createTimestamp, undefined)}</span></td>
											<td className="align-item-center">
												{communityStatusLoading && currentEditCommunity === community.communityId ? <div className='d-flex'><Loader /></div>
													: <div className='d-flex'>
														<button type="button" className="btn btn-primary mb-2 mr-2" onClick={() => this.changeCommunityStatus(community.communityId, ModelStatus.Accepted)}>Accept</button>
														<button type="button" className="btn btn-primary mb-2 mr-2" onClick={() => this.changeCommunityStatus(community.communityId, ModelStatus.Deleted)}>Decline</button>
													</div>}
											</td>
										</tr>)}
									</tbody>
								</table>}
								<DeleteCareerOpportunities communityId={currentDeleteCommunity} callback={this.getCommunities} />
							</div>
						</div>
					</section>
				</div>
			</>
		);
	}
}

export { NewCommunitiesList }