const R = require('ramda');

const createPredicate = ([test, errorMsg]) => a => test(a) ? '' : errorMsg;
const createPredicates = R.map(createPredicate);
const runPredicates = ([input, validations]) => R.map(predFn => predFn(input), createPredicates(validations));
const validate = (fields, allerrors = false) => {
	let errors = R.map(data => R.reject(R.isEmpty, runPredicates(data)), fields),
		hasError = R.compose(R.sum, R.values, R.map(q => q.length))(errors)
	errors = allerrors ? errors : R.map(err => R.head(err), errors)
	errors.hasError = R.gt(hasError, 0)

	return errors;
};

// validations
const notEmpty = data => {
	return R.not(R.either(R.isNil, R.isEmpty)(data))
},
	isEmail = R.test(/\S+@\S+\.\S+/),
	isNumber = R.test(/^([1-9]\d*)$/),
	isEqual = R.curry((data2, data) => {
		return R.equals(data, data2)
	}),
	isGreaterThan = R.curry((len, data) => {
		return data.length > len
	}),
	isLesserThan = R.curry((len, data) => {
		return data.length < len
	}),
	passwordRules = R.curry((data) => {
		const test1 = R.test(/[A-Z]/),
			test2 = R.test(/[a-z]/),
			test3 = R.test(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/),
			test4 = R.test(/[0-9]/),
			checkAllCases = R.allPass([test1, test2, test3, test4]);

		return checkAllCases(data)
	}),
	compareDate = R.curry((data2, data) => {
		const d1 = new Date(data),
			d2 = new Date(data2);
		return d1.toString() === "Invalid Date" ? true : d1.getTime() > d2.getTime()
	}),
	dateNotEmpty = (data) => {
		if (data === "present")
			return true;
		return (data.toString().split(" ").length === 2)
	}

export {
	validate,
	isEqual,
	notEmpty,
	isEmail,
	isNumber,
	isGreaterThan,
	isLesserThan,
	passwordRules,
	compareDate,
	dateNotEmpty
}