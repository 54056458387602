import { ValidationUtility } from "../../../validation-utility";
import { IValidatorType } from "../../../validator-interface";
import { IFeature } from "../platform-admin";


//Req [GET] /platform/admin/feature
export interface IFeatureRequest { }

//Val [GET] /platform/admin/feature
export const VFeature: IValidatorType<IFeatureRequest> = {}

//Res [GET] /platform/admin/feature
export type IFeatureResponse = IFeature[]
