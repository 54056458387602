import React from 'react';

function Loader() {
	return (
		<div className="overlay dark">
			<i className="fas fa-2x fa-sync-alt fa-spin"></i>
		</div>
	);
}

export { Loader }