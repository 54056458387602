import React from 'react';
import { Link } from 'react-router-dom';

import { validateForgotPasswordForm } from '../../validate/loginForm';
import { toastService } from '../../../services';
import { history } from '../../../helpers';
import '../../../assets/css/login.css';
import { HttpWrapper, HttpMethod } from '../../../helpers/httpWrapper';
import { IForgotPasswordRequest, IForgotPasswordResponse, VForgotPassword } from '../../../networked-shared-interfaces/auth/auth-routes';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import { Loader } from '../common/Loader';

interface IState {
	formData: {
		email: string
	},
	formErrors: any,
	loading: boolean,
	submitted: boolean
}

export class ForgotPassword extends React.Component<any, IState> {
	constructor(props) {
		super(props);

		this.state = {
			formData: {
				email: ''
			},
			formErrors: [],
			loading: false,
			submitted: false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(e) {
		const { name, value } = e.target,
			{ formData } = this.state
		formData[name] = value;
		this.setState({ formData });
		if (this.state.submitted)
			this.setState({ formErrors: validateForgotPasswordForm(formData) });
	}

	handleSubmit(e) {
		e.preventDefault();

		const { formData } = this.state,
			{ email } = formData,
			errors = validateForgotPasswordForm(formData);

		this.setState({ submitted: true, formErrors: errors });

		if (!errors.hasError) {
			this.setState({ loading: true });
			HttpWrapper.callUrl<IForgotPasswordRequest, IForgotPasswordResponse>(
				URLWrapper.urls.auth.password.forgotPassword,
				HttpMethod.POST, {
				email: email
			}, VForgotPassword)
				.then((response) => {
					this.setState({ submitted: false, loading: false });
					toastService.success("Check your email for the confirmation link.");
					history.push('/login');
				}).catch((error) => {
					this.setState({ submitted: false, loading: false });
					toastService.error(error.message);
				})
		}
	}

	render() {
		const { submitted, formData: { email }, formErrors, loading } = this.state

		return (
			<div className="login-box">
				<div className="login-logo">
					<b>Admin</b> NETWORKED
				</div>
				<div className="card">
					{loading && <Loader />}
					<div className="card-body login-card-body">
						<p className="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>
						<form>
							<div className="input-group mb-3">
								<input type="email" name="email" value={email} className={'form-control' + (formErrors.email ? ' is-invalid' : '')} placeholder="Email" onChange={this.handleChange} />
								<div className="input-group-append">
									<div className="input-group-text">
										<span className="fas fa-envelope"></span>
									</div>
								</div>

							</div>
							<div className="row">
								<div className="col-12">
									<button type="submit" className="btn btn-primary btn-block" onClick={this.handleSubmit}>Request new password</button>
								</div>

							</div>
						</form>
						<p className="mt-3 mb-1"><Link to="/login">Login</Link></p>
					</div>
				</div>
			</div>
		);
	}
}