import React from 'react';
import { CSVLink } from 'react-csv'

interface IProps {
	data: any,
	className: string,
	filename: string,
	fields: any,
	style: any,
	text: string,
	headers: any
}

const ExportCSV = (props: IProps) => {
	const { data, className, filename, fields, style, text, headers } = props;

	return (
		<button type="button" className="btn btn-primary">
			<CSVLink data={data} className={className} headers={headers} filename={filename}><i className="fa fa-download" aria-hidden="true"></i> &nbsp;&nbsp; Download</CSVLink>
		</button>
	)
}

export { ExportCSV } 