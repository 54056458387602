import { validate, notEmpty, compareDate } from './rules'

export function validateProfileAboutForm(data) {
	const errors = validate({
		name: [
			data.name,
			[
				[notEmpty, 'Name is Required.']
			],
		],
		designation: [
			data.designation,
			[
				[notEmpty, 'Designation is Required.']
			],
		]
	})

	return errors;
}

export function validateProfileSummaryForm(data) {
	const errors = validate({
		about: [
			data.about,
			[
				[notEmpty, 'About is Required.']
			],
		]
	})

	return errors;
}

export function validateProfileExperienceForm(data) {
	const errors = validate({
		title: [
			data.title,
			[
				[notEmpty, 'Title is Required.']
			],
		],
		company: [
			data.company,
			[
				[notEmpty, 'Company is Required.']
			],
		],
		to: [
			data.to,
			[
				[compareDate(data.from), 'End date should be greater than Start date.']
			],
		]
	})

	return errors;
}

export function validateProfileEducationForm(data) {
	const errors = validate({
		school: [
			data.school,
			[
				[notEmpty, 'School is Required.']
			],
		],
		degree: [
			data.degree,
			[
				[notEmpty, 'Degree is Required.']
			],
		],
		fieldOfStudy: [
			data.fieldOfStudy,
			[
				[notEmpty, 'Field of Study is Required.']
			],
		],
		to: [
			data.to,
			[
				[compareDate(data.from), 'End date should be greater than Start date.']
			],
		]
	})

	return errors;
}

export function validateProfileMembershipForm(data) {
	const errors = validate({
		title: [
			data.title,
			[
				[notEmpty, 'Title is Required.']
			],
		],
		organization: [
			data.organization,
			[
				[notEmpty, 'Organization is Required.']
			],
		],
		to: [
			data.to,
			[
				[compareDate(data.from), 'End date should be greater than Start date.']
			],
		]
	})

	return errors;
}