import React from "react";
import { HttpMethod, HttpWrapper } from "src/helpers/httpWrapper";
import { URLWrapper } from "src/networked-shared-interfaces/url-wrapper";
import { toastService } from "src/services";

interface Props {
  communityId: string;
  trialEndTime: number;
  loading: boolean;
  isOpen: boolean;
  onClose: () => void;
  subscriptionStatus: string;
}

interface State {
  numberOfDays: string;
  selectedDate: string;
  loading: boolean;
}

class ExtendTrialForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      numberOfDays: this.calculateDays(this.props.trialEndTime),
      selectedDate: "",
      loading: this.props.loading,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot?: any): void {
    if (prevProps.trialEndTime !== this.props.trialEndTime) {
      //   let current = new Date().getTime();
      let whatcame = this.props.trialEndTime - 3 * 24 * 60 * 60;
      //   if (whatcame * 1000 < current) {
      //     toastService.error("plan's trail already ended");
      //     this.props.onClose();
      //     return;
      //   }
      this.setState({
        numberOfDays: this.calculateDays(whatcame),
        selectedDate: this.getFormattedDate(whatcame * 1000),
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  calculateDays(timestamp: number): string {
    const milliSeconds = Math.abs(
      // @ts-ignore
      new Date() - new Date(timestamp * 1000)
    );
    const diff = Math.ceil(milliSeconds / (1000 * 60 * 60 * 24));
    return diff + "";
  }

  handleDateChange = (e) => {
    let currentDate = new Date();
    let selectedDate = new Date(e.target.value);
    // let tEndDate = new Date(this.props.trialEndTime * 1000);

    if (selectedDate < currentDate) {
      toastService.error("selected date cannot be in past");
      return;
    }
    // if (selectedDate < tEndDate) {
    //   toastService.error(
    //     `selected date cannot be before the trial End date which is ${
    //       new Date(this.props.trialEndTime * 1000)
    //         .toLocaleString()
    //         .split(",")[0]
    //     }`
    //   );
    // }
    // @ts-ignore
    const diffTime = Math.abs(selectedDate - currentDate); // in milliseconds
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // in days
    this.setState({
      numberOfDays: diffDays + "",
      selectedDate: e.target.value,
    });
  };

  handleSubmit = () => {
    let selectedTs = new Date(this.state.selectedDate).getTime() / 1000;

    if (selectedTs < this.props.trialEndTime - 3 * 60 * 60 * 24) {
      toastService.error(
        `selected Date must be greater than current trail end date which is ${
          new Date(this.props.trialEndTime * 1000)
            .toLocaleString()
            .split(",")[0]
        }`
      );
      return;
    }

    this.setState({
      loading: true,
    });

    HttpWrapper.callUrl<any, any>(
      URLWrapper.urls.stripe.extendTrial,
      HttpMethod.POST,
      {
        communityId: this.props.communityId,
        extendTill: new Date(this.state.selectedDate).getTime() / 1000,
      },
      {}
    )
      .then(() => {
        toastService.success(`Plan's trial extended successfully`);
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        toastService.error(err ? err.message : "Failed to extend plan");
        this.setState({
          loading: false,
        });
      });
  };

  getFormattedDate = (date) => {
    if (date) {
      let temp = new Date(date);
      return `${(temp.getFullYear() + "").padStart(2, "0")}-${(
        temp.getMonth() +
        1 +
        ""
      ).padStart(2, "0")}-${(temp.getDate() + "").padStart(2, "0")}`;
    }
  };

  render() {
    const { subscriptionStatus, isOpen, loading, trialEndTime, onClose } =
      this.props;

    const selectedDateTemp = new Date((trialEndTime - 3 * 24 * 60 * 60) * 1000);

    const selectedDateTemp2 = new Date(
      (this.props.trialEndTime + 3 * 31 * 24 * 60 * 60) * 1000
    );
    // const numberOfDays = this.calculateDays(trialEndTime);

    if (!isOpen) return false;

    return (
      <div
        className='modal d-block'
        data-show='true'
        style={{ background: "rgba(0,0,0,0.4)" }}
        role='dialog'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Extend Trial</h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
                onClick={() => onClose()}
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='input-group mb-3'>
                {!loading &&
                  (subscriptionStatus === "canceled" ||
                    subscriptionStatus === "paused") && (
                    <p className='text-danger'>
                      {subscriptionStatus === "canceled"
                        ? "This subscription was cancelled, Extending trail will create a new subscription."
                        : "This subscription is paused, Extending trail will resume it."}
                    </p>
                  )}
                <input
                  type='text'
                  className='form-control'
                  readOnly
                  value={this.state.numberOfDays}
                />
                <div className='input-group-append'>
                  <span className='input-group-text' id='basic-addon2'>
                    Days
                  </span>
                </div>
              </div>
              <input
                type='date'
                disabled={loading}
                min={this.getFormattedDate(selectedDateTemp)}
                max={this.getFormattedDate(selectedDateTemp2)}
                value={this.getFormattedDate(this.state.selectedDate)}
                className='form-control'
                onChange={(e) => this.handleDateChange(e)}
              />
            </div>
            <div className='modal-footer'>
              {this.state.loading && (
                <div className='spinner-border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              )}
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => onClose()}
              >
                Close
              </button>
              <button
                type='button'
                disabled={loading}
                className='btn btn-primary'
                onClick={() => this.handleSubmit()}
              >
                Extend Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { ExtendTrialForm };
