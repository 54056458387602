import React, { Component } from 'react';  
import { Route, Redirect } from 'react-router-dom';
import { Private } from './private/Private';
  
const PrivateLayout = ({component: Component, ...rest}) => {  
  return (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Private><Component {...props} /></Private>
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
  )  
};  
  
export { PrivateLayout };