import React from 'react'
import { HttpMethod, HttpWrapper } from '../../../helpers/httpWrapper'
import { ModelStatus } from '../../../networked-shared-interfaces/constants/model-status'
import { IUserUpdateRequest, IUserUpdateResponse, VUserUpdate } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/user'
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper'
import { toastService } from '../../../services'
// import { HttpWrapper, HttpMethod } from '../../../../helpers/httpWrapper';
// import { toastService } from '../../../../services';
// import { customMessages } from '../../../../constants/custom-messages';
// import { IOpportunityJobDeleteRequest, IOpportunityJobDeleteResponse, VOpportunityJobDelete, IOpportunityJobGetOneResponse } from '../../../../networked-shared-interfaces/opportunity-routes/sub-routes/opportunity-job-routes';
// // import { URLWrapper } from '../../../../networked-shared-interfaces/url-wrapper';

interface IProps {
	userId: any,
	callback: any
}

export default class DeleteUser extends React.Component<IProps, any> {

	DeleteUser = (userId: string) => {
		let payload: any = {
			userId: userId,
			status: ModelStatus.Deleted
		}
		HttpWrapper.callUrl<IUserUpdateRequest, IUserUpdateResponse>
			(URLWrapper.urls.platformAdmin.user, HttpMethod.PUT, payload, VUserUpdate).then(() => {
				toastService.success("User Deleted");
				this.setState({ loading: false });
				this.props.callback();
			}).catch(error => {
				this.setState({ loading: false });
				toastService.success(`Error: ${error.message}`)
			})
	}

	render() {
		return (
			<div className="modal fade deletePost-modal" id={`DeleteUser`} role="dialog" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered justify-content-center m-auto" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Are you sure you want to delete this user?</h5>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
							<button type="button" className="btn btn-primary" data-dismiss="modal"
								onClick={() => { this.DeleteUser(this.props.userId) }}
							>Yes, Delete</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
