import React from 'react';
import { Typeahead, } from 'react-bootstrap-typeahead';

interface IProps {
	options: any,
	multiple: boolean,
	handleClassificationChange: any,
	selected?: any,
	defaultSelected?: any
}

class ClassificationTypehead extends React.Component<IProps, any> {
	render() {
		return (
			<>
				<Typeahead
					allowNew
					id="Classification"
					style={{ border: "none" }}
					defaultSelected={this.props.defaultSelected}
					// isLoading={this.state.loading}
					selected={this.props.selected}
					labelKey={'name'}
					filterBy={['name']}
					// onChange={selected => {
					// 	this.onChangeForm("classification", selected)
					// }}
					onChange={this.props.handleClassificationChange}
					options={this.props.options}
					placeholder="Enter Classification"
					emptyLabel="No Classification Found"
					multiple={this.props.multiple}
				/>
			</>

		)
	}
}

export default ClassificationTypehead;