import React from 'react';
import Accordion from '../communities/Accordion';
import { userStatusEnums } from '../../../constants/common';

interface Iprops {
	handleFilterChange: any,
	handleSearch: any,
	handleReset: any
}

interface IState {
	formData: {
		keyword: any,
		status: any,
		onboardDate: any
	}
}

class Searchuser extends React.Component<Iprops, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			formData: {
				keyword: '',
				status: '',
				onboardDate: ''
			}
		}
	}

	resetFilter = () => {
		this.setState({
			formData: {
				keyword: '',
				status: '',
				onboardDate: ''
			}
		}, () => this.props.handleReset());
	}

	submit = () => {
		this.props.handleSearch()
	}

	render() {
		const { formData } = this.state;

		return (
			<Accordion>
				<div style={{ minHeight: '200px' }}>
					<div className="basicInfoFields p-3">
						<div className="form-row">
							<div className="form-group col-md-3">
								<label htmlFor="Classification">Onboarded Date</label>
								<input className="form-control" aria-label="Search"
									placeholder=""
									type="date"
									value={formData.onboardDate}
									onChange={(e) => { this.setState({ formData: { ...formData, onboardDate: e.target.value } }, () => this.props.handleFilterChange('onboardDate', this.state.formData.onboardDate)) }}
								/>
							</div>
							<div className="form-group col-md-4">
								<label htmlFor="userStatus">Status</label>
								<select placeholder="Select user status" className="form-control" id="userStatus"
									onChange={(e) => { this.setState({ formData: { ...formData, status: e.target.value } }, () => this.props.handleFilterChange('status', this.state.formData.status)) }}
									value={formData.status}
								>
									<option value="">Select status</option>
									{userStatusEnums.map((val, index) => <option value={index} key={index}>{val}</option>)}
								</select>
							</div>
							<div className="form-group col-md-5">
								<label htmlFor="Classification">Keyword</label>
								<input className="form-control" type="search" aria-label="Search"
									placeholder="Keyword"
									value={formData.keyword}
									onChange={(e) => { this.setState({ formData: { ...formData, keyword: e.target.value } }, () => this.props.handleFilterChange('query', this.state.formData.keyword)) }} />
							</div>
						</div>
					</div>
					<div className='float-right pr-3'>
						<button type="button" className="btn btn-primary mr-2" onClick={() => { this.resetFilter(); }}>Reset</button>
						<button type="button" className="btn btn-success mr-17" onClick={this.props.handleSearch}>Search</button>
					</div>
				</div>
			</Accordion>

		)
	}
}

export default Searchuser;