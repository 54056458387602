import React from 'react';
import { clone } from 'ramda';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import { HttpMethod, HttpWrapper } from '../../../helpers/httpWrapper';
import { ICommunityRequest, ICommunityResponse, VCommunity } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/community';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import { head, isEmpty, range, reverse, join, contains } from 'ramda'
import moment from 'moment';
import { validateProfileMembershipForm } from './Validate/profileForm';
import { toastService } from '../../../services';
import { getMonthDiff } from '../communities/helper/Timestap';
import { getFileName } from '../../../helpers';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import ProfileImage from '../../../helpers/profileImage';

const AsyncTypeahead = withAsync(Typeahead);

export const initialFormData = {
	membershipId: '',
	title: '',
	organization: [],
	defaultMembership: [],
	communityId: '',
	communityName: '',
	location: '',
	startTime: 0,
	endTime: 0,
	from: '',
	from_from: '',
	from_to: '',
	to: '',
	to_from: '',
	to_to: '',
	description: '',
	media: '',
	mediaId: '',
	is_present: false,
	isEditable: false

}

interface IState {
	formData: {
		membershipId: string,
		title: string,
		organization: any,
		defaultMembership: any,
		communityId?: string,
		communityName: string,
		location: string,
		startTime: number,
		endTime: number,
		from: string,
		from_from: string,
		from_to: string,
		to: string,
		to_from: string,
		to_to: string,
		description: string,
		media: string,
		mediaId?: string,
		is_present: boolean,
		isEditable: boolean
	},
	formErrors: any,
	loading: boolean,
	memLoading: boolean,
	resError: boolean,
	submitted: boolean,
	section: string,
	communities: any,
	count: number,
	start: number,
	filter: string,
	nextStart: boolean,
	total: number,
	paginate: boolean
}

interface IProps {
	handleMembership: any,
	memberships?: any
}

class AddandEditMembership extends React.Component<Partial<IMapState> & IProps, IState> {
	constructor(props) {
		super(props);

		this.state = {
			formData: clone(initialFormData),
			formErrors: [],
			loading: false,
			memLoading: false,
			resError: false,
			submitted: false,
			section: 'edit-membership-popup',
			communities: [],
			count: 100,
			start: 0,
			filter: '',
			nextStart: true,
			total: 0,
			paginate: true
		};

		this.handleLogoChange = this.handleLogoChange.bind(this);

	}

	getAllCommunities = (query, start = 0, restart = false) => {
		this.setState({ memLoading: true, filter: query });
		const { count, communities, total } = this.state,
			startCount = restart ? 0 : start * count;

		HttpWrapper.callUrl<ICommunityRequest, ICommunityResponse>(
			URLWrapper.urls.platformAdmin.community, HttpMethod.GET, {
			count: count,
			start: startCount,
			//@ts-ignore
			filter: query
		}, VCommunity)
			.then((response) => {
				let communityAll = [];
				if (response.content && response.content.length > 0) {
					communityAll = start === 0 ? response.content : [...communities, ...response.content];
				} else {
					communityAll = communities;
				}
				this.setState({
					memLoading: false,
					communities: communityAll,
					nextStart: response.nextStart,
					total: start === 0 ? response.count : total + response.count,
					paginate: (start === 0 && response.count < 30) ? false : true
				});
			}).catch((error) => {
				console.log(error);
			})
	}

	paginateCommunities = (e: any, cnt) => {
		if (!this.state.nextStart) {
			if (this.state.total <= cnt) {
				this.setState({ paginate: false })
			}
		} else {
			this.setState({ start: this.state.start + 1 })
			this.getAllCommunities(this.state.filter, this.state.start + 1);
		}
	}

	handleChange = (e: any) => {
		const { name, value } = e.target,
			{ formData } = this.state;
		formData[name] = (name === 'is_present') ? !formData.is_present : value;

		if (formData['from_from'] && formData['from_to'])
			formData['from'] = moment(join(' ', [formData['from_from'], formData['from_to']])).format('YYYY-MM-DD 00:00:00');
		if (formData['to_from'] && formData['to_to'])
			formData['to'] = formData['is_present'] ? 'present' : moment(join(' ', [formData['to_from'], formData['to_to']])).format('YYYY-MM-DD 00:00:00');

		this.setState({ formData });

		if (this.state.submitted)
			this.setState({ formErrors: validateProfileMembershipForm(formData) });
	}

	handleCompanyChange(selected: any) {
		const { formData } = this.state;
		this.setState({ formData: { ...formData, isEditable: true } })

		if (!selected || selected.length === 0) {
			this.setState({ formData: { ...formData, organization: [], defaultMembership: [] } });
			return;
		}

		const organization = head(selected);

		if (organization.customOption) {
			this.setState({
				formData: {
					...formData, defaultMembership: [organization.name],
					organization: organization, communityId: '', communityName: organization.name
				}
			});
		} else {
			this.setState({
				formData: {
					...formData, defaultMembership: [organization.name],
					organization: organization, communityId: organization.communityId, communityName: organization.name
				}
			});

		}
	}

	handleSubmit = (e: any) => {
		e.preventDefault();

		const { formData } = this.state
		const errors = validateProfileMembershipForm(formData);

		this.setState({ submitted: true, formErrors: errors });

		if (!errors.hasError) {
			this.setState({ loading: true });

			const formFields = {
				title: formData.title,
				communityName: formData.communityName,
				startTime: formData.from ? moment(formData.from).valueOf() : formData.startTime,
				endTime: formData.is_present ? 0 : (formData.to ? moment(formData.to).valueOf() : formData.endTime),
				description: formData.description ? formData.description : '',
				media: formData.media,
				mediaId: formData.mediaId
			}

			if (!isEmpty(formData.mediaId)) {
				formFields['mediaId'] = formData.mediaId;
				formFields['media'] = formData.media;
			}

			if (!isEmpty(formData.communityId)) {
				formFields['communityId'] = formData.communityId;
			}

			if (!isEmpty(formData.membershipId)) {
				formFields['membershipId'] = formData.membershipId;
			}
			// console.log(formFields, "formFields")
			this.handleClose();
			this.props.handleMembership(formFields, formData);

		}
	}

	handleClose = () => {
		this.setState({ formErrors: [], submitted: false });
		document.getElementById('clsMembershipModal').click();
	}

	handleLogoChange = (e: any) => {
		const { formData, section } = this.state,
			file = e.target.files[0],
			fileType = file.type.split('/')[1];

		if (!contains(fileType, ['jpg', 'jpeg', 'png'])) {
			toastService.error('Invalid File Format. it should be .jpg, .jpeg, .png');
			this.setState({ loading: false });
			return false;
		}

		// @ts-ignore
		if (parseInt(file.size / 1048576).toFixed(1) > 2) {
			toastService.error('File size should not be greaterthan 2 MB');
			this.setState({ loading: false });
			return false;
		}

		this.setState({ loading: true });
		// @ts-ignore
		HttpWrapper.uploadFile(document.getElementsByName('logo-upload')[0].files[0])
			.then((response) => {
				this.setState({
					formData:
						{ ...formData, mediaId: response.fileId, media: response.fileUrl },
					loading: false
				});
			}).catch((error) => {
				this.setState({ resError: true, submitted: false, loading: false });
				toastService.error(error.message, { id: section });
			})
	}

	closeModal = (e: any) => {
		e.preventDefault();

		let form = {
			membershipId: '',
			title: '',
			organization: [],
			defaultMembership: [],
			communityId: '',
			communityName: '',
			location: '',
			startTime: 0,
			endTime: 0,
			from: '',
			from_from: '',
			from_to: '',
			to: '',
			to_from: '',
			to_to: '',
			description: '',
			media: '',
			mediaId: '',
			is_present: false,
			isEditable: false

		};
		this.setState({ formData: form, formErrors: [], submitted: false });
		// @ts-ignore

	}

	setMembershipJson = (currentMembership: any, index: number) => {
		const { formData } = this.state;
		formData.membershipId = currentMembership.membershipId
		formData.title = currentMembership.title
		formData.organization = { name: currentMembership.communityName, communityId: currentMembership.communityId || '', image: currentMembership.media || '' }
		formData.defaultMembership = [{ name: currentMembership.communityName, communityId: currentMembership.communityId || '', image: currentMembership.media || '' }]
		formData.communityId = currentMembership.communityId
		formData.communityName = currentMembership.communityName
		formData.location = currentMembership.location
		formData.startTime = currentMembership.startTime
		formData.endTime = currentMembership.endTime
		formData.from = ''
		formData.from_from = currentMembership.startTime ? moment(currentMembership.startTime).format('MMMM') : ''
		formData.from_to = currentMembership.startTime ? String(moment(currentMembership.startTime).year()) : ''
		formData.to = ''
		formData.to_from = currentMembership.endTime ? moment(currentMembership.endTime).format('MMMM') : ''
		formData.to_to = currentMembership.endTime ? String(moment(currentMembership.endTime).year()) : ''
		formData.description = currentMembership.description
		formData.media = currentMembership.media
		formData.mediaId = ''
		formData.is_present = currentMembership.endTime ? false : true
		formData.isEditable = false

		this.setState({ formData }, () => { document.getElementById(`openAddNewMemPopup`).click(); });

	}

	resetFormData = () => {
		this.setState({ formData: clone(initialFormData) }, () => { document.getElementById(`openAddNewMemPopup`).click(); });
	}

	render() {
		const { formData, formErrors } = this.state,
			year = reverse(range(1950, moment().year() + 1)),
			{ memberships } = this.props;

		return (
			<>
				<div className="form-group">
					<div className="card">
						<div className="card-body">
							<strong>Memberships</strong>
							<div className="float-right">
								<button type="button" style={{ display: 'none' }} id="openAddNewMemPopup" className="btn btn-primary" data-toggle="modal" data-target="#Memberships"></button>
								<button type="button" className="btn btn-primary" onClick={this.resetFormData}>
									<i className="fas fa-plus">Add New Memberships</i>
								</button>
							</div>
							<div className="mt-5">
								<div className="float-right">

									<div className="modal fade" id="Memberships" tabIndex={-1} role="dialog" aria-labelledby="MembershipsLabel" aria-hidden="true">
										<div className="modal-dialog" role="document">
											<div className="modal-content" style={{ minWidth: '750px' }}>
												<div className="modal-header">
													<h5 className="modal-title" id="MembershipsLabel">{formData.membershipId ? 'Edit' : 'Add New'} Memberships</h5>
													<button type="button" className="close" data-dismiss="modal" aria-label="Close" id="clsMembershipModal" onClick={this.handleClose}>
														<span aria-hidden="true">&times;</span>
													</button>
												</div>
												<div className="col-md-12 modal-body">
													<div className="form-row">
														<div className="form-group col-md-6">
															<label>Title*</label>
															<input type="text" className="form-control input-lg"
																value={formData.title} onChange={this.handleChange} name="title" placeholder="Enter title" />
															{formErrors.title && <div className="help-block" style={{ color: 'red' }}>{formErrors.title}</div>}
														</div>
														<div className="form-group col-md-6">
															<label>Organization*</label>
															<AsyncTypeahead
																allowNew
																newSelectionPrefix="Add a new Organization: "
																isLoading={this.state.memLoading}
																labelKey="name"
																selected={formData.defaultMembership}
																defaultselected={formData.communityName ? [{ name: formData.communityName || '', communityId: formData.communityId || '', image: formData.media || '' }] : []}
																minLength={2}
																//paginate={this.state.paginate}
																maxResults={30}
																paginationText='Additional results...'
																onPaginate={(e, cnt) => this.paginateCommunities(e, cnt)}
																onChange={selected => {
																	this.setState({ formData: { ...formData, defaultMembership: selected } });
																	this.handleCompanyChange(selected);
																}}
																onSearch={query => { this.getAllCommunities(query, 0, true) }}
																options={this.state.communities}
																id={'profile_membership' + 1}
																//inputProps={{ name: 'communityId' }}
																placeholder="Select your organization"
																emptyLabel="No organizations found"
															/>
															{formErrors.organization && <div className="help-block" style={{ color: 'red' }}>{formErrors.organization}</div>}
														</div>


														<div className="form-group col-md-6 start-date form-group">
															<label>Start date</label>
															<div className="d-flex start-date-input">
																<select name="from_from" className="form-control select-dropdown mr-2"
																	onChange={this.handleChange}
																	value={formData.from_from}
																>
																	<option value="">Month</option>
																	<option value="January">January</option>
																	<option value="February">February</option>
																	<option value="March">March</option>
																	<option value="April">April</option>
																	<option value="May">May</option>
																	<option value="June">June</option>
																	<option value="July">July</option>
																	<option value="August">August</option>
																	<option value="September">September</option>
																	<option value="October">October</option>
																	<option value="November">November</option>
																	<option value="December">December</option>
																</select>
																<select name="from_to" className="form-control select-dropdown mr-2"
																	onChange={this.handleChange}
																	value={formData.from_to}
																>
																	<option value="">Year</option>
																	{year.map((y, i) => (<option value={y} key={i}>{y}</option>))}
																</select>
															</div>
														</div>

														<div className="form-group col-md-6 end-date form-group">
															<label>End date</label>
															<div className="d-flex end-date-input">
																<select name="to_from" className="form-control select-dropdown mr-2"
																	onChange={this.handleChange}
																	disabled={formData.is_present}
																	value={formData.to_from}
																>
																	<option value="">Month</option>
																	<option>January</option>
																	<option>February</option>
																	<option>March</option>
																	<option>April</option>
																	<option>May</option>
																	<option>June</option>
																	<option>July</option>
																	<option>August</option>
																	<option>September</option>
																	<option>October</option>
																	<option>November</option>
																	<option>December</option>
																</select>
																<select name="to_to" className="form-control select-dropdown" onChange={this.handleChange}
																	disabled={formData.is_present}
																	value={formData.to_to}
																// defaultValue={formData.to_to} onChange={this.handleChange} disabled={formData.is_present}
																>
																	<option value="">Year</option>
																	{year.map((y, i) => (<option key={i} value={y}>{y}</option>))}
																</select>
															</div>



															<div className="form-group col-md-6 checkbox-container mt-2">
																{!formData.is_present && formErrors.to && <div className="help-block">{formErrors.to}</div>}
																<div className="checkbox-container mt-2">
																	<div>
																		<input type="checkbox" className="mb-0" name="is_present" checked={formData.is_present ? true : false} onChange={this.handleChange}></input>
																		<label htmlFor="checkbox" className="mb-0">Present</label>
																	</div>
																</div>
															</div>
														</div>
														<div className="form-group col-md-12 description">
															<label>Description</label>
															<textarea value={formData.description}
																className="form-control input-lg"
																onChange={this.handleChange} name="description" placeholder="Enter description"></textarea>
														</div>
														<div className="form-row" style={{ display: "contents" }}>
															{formData.organization && formData.organization.customOption && !isEmpty(formData.organization.customOption) && <div className="form-group col-md-6 upload-media height-40">
																{!formData.media && <label className="">Upload Logo Image</label>}
																<input type="file" className="form-control-file border-0"
																	id="inputUploadlogo" name="logo-upload"
																	onChange={this.handleLogoChange}
																/>
																{formData.media && formData.mediaId &&
																	<label className="d-block p-2 mt-2 upload-tiggerBtn border-0">{getFileName(formData.media)}</label>
																}
															</div>}
														</div>

													</div>
												</div>
												<div className="modal-footer">
													<button type="button" style={{ display: 'none' }} className="btn btn-secondary" data-dismiss="modal" id="clsMembershipModal">Close</button>
													<button type="button" className="btn btn-secondary" onClick={this.handleClose}>Close</button>
													<button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Ok</button>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="ml-2" >
									{
										memberships && memberships.map((eachMembership: any, index: number) => {

											return (
												<div className="d-flex mb-5" key={index}>
													{/* <img style={{ width: '68px', height: '68px' }}
													src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAe1BMVEWYAS7///+QAA/o0NWTABrXsbeWACaWACixXm2YAC6XACuVACKpSVyUAB/HkZuSABK+fIjhxcqeHz3q19uOAADRpq6SABiPAAiPAAD27O/Ag4789/m5cX7ev8X16u2fJkHZtrytVGWaFDO6doHKmaGiMkmoRlmkOk+0Z3QQE08iAAAC2UlEQVR4nO3a7XKiMBiGYVcpSJCigqBY60er6/kf4e6MCEENJkKhOvf1O6+TBzCBJL0eAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4ccKLg74G57LQtrTqxEWZ6/ixsnEQe5fta3LtaJ1uBhrCUSmiiK3jbKJTNy112fWiw/tK3XyT7ue222DCZBv+0TS0pX764k23rpTQidLFvYJwGDV2Hx1rpdvPUkI3GevXyQm99adOycS6+ks8GHB393reTOgm2jewnNDfatYsdo1EFLZJwCKhWUApoT3SLlrYDTyobqT9Fywn9A0eUTmhEAZVYVR/uPFTo47mCYVjVpcnTCYmZalfO2F/adbTc0LL6BktEjp/jcqW/boB7Q+zjuYJA8Mrc04Yb8zqPmx157UE0kQRvt23OZx6ag+LuuVGoy5L6EbSlZmoWktDwyqoF9BNipnpK4qt+7Lx2//O6wZaddktFOviwuwTRds4+spbfSY1xxorv6SryKROetgCkxHdKaaKsaVuFuWP1rKilQ5p6B57JoVBPiKGiUmd9HSvK66MV0xFot6UKI35Dyc0Gu2khFO9hM4PJBROhazNUycU+1GF6anRUyesnsuz+fC5E85I2COhGgkzJNTXZcKe5Sv1ixfTJ074/V6heO194vlQUzvvNIftSyW88V5q714r4Y1m0+dNqPd9WJnw0e/DdhLK3/hzdavrhI9+47eeUF6nOarXWwL3MqG0TrPSWqfxOkpYWmsbVCyVXSaUe7rQWWtLvY4StrZeOgk6Stjamvd1QpEESvPiwtdO2Na+xXXCqXCVmnvzbm/v6VZCda+aTPj/ZrSyf9hhwpb2gLtM2M4+fqcJWzmL8XDCmqv6567++Hmawey0meuMwtsnbMrs47nZoLEjJz99Juq8heQoT0mVfzU/HtVUQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA8Nv8A+cMRt74FGkyAAAAAElFTkSuQmCC'} 
													/> */}
													<ProfileImage url={eachMembership.media} alt={eachMembership.mediaId} defaultClass="profile-user-img img-fluid img-square" imageType="circle" imageSize="xl" />
													<div style={{ display: 'grid', marginLeft: 5 }}>
														<strong>{eachMembership.title}</strong>
														<span>{eachMembership.communityName}</span>
														<span>{eachMembership.endTime !== 0 && getMonthDiff(eachMembership.startTime, eachMembership.endTime)}</span>
														<span>{eachMembership.location}</span>
														<br />
													</div>
													<div className="ml-auto">
														<i className="fa fa-edit" onClick={() => this.setMembershipJson(eachMembership, index)}>Edit</i>
														<i className="fa fa-edit" style={{ display: 'none' }} id={`openPop${index}`} data-toggle="modal" data-target="#Memberships">Edit</i>
													</div>


												</div>
											)
										})
									}


								</div>
							</div>
						</div>
					</div>
				</div>




			</>
		)
	}
}


export default withRouter(AddandEditMembership)

interface IMapState {
	global: any,
}

function mapState(state): IMapState {
	console.log(state, "state")
	const { global } = state;
	return { global };
}



const connectedAddandEditMembership = withRouter(connect(mapState)(AddandEditMembership));
export { connectedAddandEditMembership as AddandEditMembership };