import { ValidationUtility } from "../validation-utility";
import { IValidatorInterface } from "../validator-interface";


export const VExtendSubscription: IValidatorInterface = {
	communityId: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Community ID is required!" },
	extendTill: { function: ValidationUtility.validateNumber, params: [], required: true, customMessage: "Please select the date to extend trial till." }
}

export const VCancelSubscription: IValidatorInterface = {
	communityId: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Community ID is required!" },
	reasonType: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false },
	reason: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false }
}

export const VToggleCommunityStatus: IValidatorInterface = {
	communityId: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Community ID is required!" },
	isPaid: { function: ValidationUtility.validateBoolean, params: [], required: true, customMessage: "please also include the status to change" }
}
export interface IGetSubscriptionUrlResponse {
	url: string,
}

export interface IPutUpdateSubscriptionResponse {
	updated: boolean,
	message: string
}
export interface IPutUpdateSubscription {
	priceId: string;
	planId: string;
	paymentMethod?: string;
	address?: IStripeSubscriptionAddress
}

export const VUpdateSubscription: IValidatorInterface = {
	priceId: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Price Id not valid!" },
	planId: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Plan Id not valid!" },
	paymentMethod: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false, customMessage: "paymentMethod not valid!" },
	address: { function: ValidationUtility.validateStripeAddressObject, params: [], required: false, customMessage: "missing fields in address" }
}

export interface IExtendSubscription {
	communityId: string;
	extendTill: number;
}
export interface ICancelSubscription {
	communityId: string;
	reasonType?: string;
	reason?: string;
}
export interface IToggleCommunityStatus {
	communityId: string;
	isPaid: boolean
}

export interface IStripeSubscriptionAddress {
	country: string;
	state: string;
	city: string;
	line1: string;
	line2?: string;
	postal_code: string;
}
