import { isEmpty } from 'ramda';
import { isEmail } from '../../validate/rules';

export function validate(formData?: any) {
	let input = formData;
	let errors = {};
	let isValid = true;
	if (!input["name"]) {
		isValid = false;
		errors["name"] = "Please enter your user name";
	}

	if (!input["title"]) {
		isValid = false;
		errors["title"] = "Please enter your title";
	}

	if (!isEmail(input["email"])) {
		isValid = false;
		errors["email"] = "Please enter valid email address.";
	}

	if (input["company"] && input["company"].length < 3) {
		console.log("Company shold have minimum 3 letters")
		isValid = false;
		errors["company"] = "Company shold have minimum 3 letters";
	}

	if (input["industry"].length === 0) {
		isValid = false;
		errors["industry"] = "Please enter your industry";
	}

	/*if (input["linkedinUrl"].length === 0) {
		isValid = false;
		errors["linkedinUrl"] = "Please enter your linkedin url";
	}*/



	// if (!input['role']) {
	// 	isValid = false;
	// 	errors["role"] = "Please enter your  role";
	// }


	if (input["communityId"].length === 0) {
		isValid = false;
		errors["communityId"] = "Please select community";
	}

	if (!input["email"]) {
		isValid = false;
		errors["email"] = "Please enter your email";
	}

	if (!input["about"]) {
		isValid = false;
		errors["about"] = "Please enter about";
	}


	let errorPayload = {
		errors,
		isValid
	};

	return errorPayload;
}