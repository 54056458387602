import React from 'react';
import { Link } from 'react-router-dom';

import { validateLoginForm } from '../../validate/loginForm';
import { toastService } from '../../../services';
import { history } from '../../../helpers';
import '../../../assets/css/login.css';
import { HttpWrapper, HttpMethod } from '../../../helpers/httpWrapper';
import { IUserLoginRequest, IUserLoginResponse, VUserLogin } from '../../../networked-shared-interfaces/auth/login-routes';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import { Loader } from '../common/Loader';

interface IState {
	formData: {
		email: string,
		password: string
	},
	formErrors: any,
	loading: boolean,
	submitted: boolean
}

class LoginPage extends React.Component<any, IState> {
	constructor(props) {
		super(props);

		this.state = {
			formData: {
				email: '',
				password: ''
			},
			formErrors: [],
			loading: false,
			submitted: false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		document.body.classList.add('login-page');
	}

	componentWillUnmount() {
		document.body.classList.remove('login-page');
	}

	handleChange(e) {
		const { name, value } = e.target,
			{ formData, submitted } = this.state
		formData[name] = value;
		this.setState({ formData });
		if (submitted)
			this.setState({ formData, formErrors: validateLoginForm(formData) });
	}

	handleSubmit(e) {
		e.preventDefault();

		const { formData, formErrors } = this.state,
			{ email, password } = formData,
			errors = validateLoginForm(formData);

		this.setState({ submitted: true, formErrors: errors });

		if (!errors.hasError) {
			this.setState({ loading: true });

			HttpWrapper.callUrl<IUserLoginRequest, IUserLoginResponse>(URLWrapper.urls.platformAdmin.login, HttpMethod.POST, {
				email: email,
				password: password
			}, VUserLogin)
				.then((response) => {
					this.setState({ submitted: false, loading: false });
					const { user, sessionToken } = response;

					localStorage.setItem('user', JSON.stringify(user));
					localStorage.setItem('sessionToken', sessionToken);

					history.push('/communities');
				}).catch((error) => {

					this.setState({ submitted: false, loading: false });
					toastService.error(error.message);
				})
		}
	}

	render() {
		const { formData: { email, password }, formErrors, loading } = this.state

		return (
			<div className="login-box">
				<div className="login-logo">
					<b>Admin</b> NETWORKED
				</div>
				<div className="card">
					{loading && <Loader />}
					<div className="card-body login-card-body">
						<p className="login-box-msg">Sign in to start your session</p>

						<form action="../../index3.html" method="post">
							<div className="input-group mb-3">
								<input type="email" name="email" value={email} className={'form-control' + (formErrors.email ? ' is-invalid' : '')} placeholder="Email" onChange={this.handleChange} />
								<div className="input-group-append">
									<div className="input-group-text">
										<span className="fas fa-envelope"></span>
									</div>
								</div>
								{formErrors.email && <span id="exampleInputEmail1-error" className="error invalid-feedback">{formErrors.email}</span>}
							</div>
							<div className="input-group mb-3">
								<input type="password" name="password" value={password} className={'form-control' + (formErrors.password ? ' is-invalid' : '')} placeholder="Password" onChange={this.handleChange} />
								<div className="input-group-append">
									<div className="input-group-text">
										<span className="fas fa-lock"></span>
									</div>
								</div>
								{formErrors.password && <span id="exampleInputEmail1-error" className="error invalid-feedback">{formErrors.password}</span>}
							</div>
							<div className="row">
								{/* <div className="col-8">
									<div className="icheck-primary">
										<input type="checkbox" id="remember" />
										<label htmlFor="remember">
											Remember Me
                      					</label>
									</div>
								</div> */}

								<div className="col-12">
									<a href="#" className="btn btn-block btn-primary" onClick={this.handleSubmit}>Sign In</a>
									{/* <button type="submit" className="btn btn-primary btn-block" onClick={this.handleSubmit}>Sign In</button> */}
								</div>

							</div>
						</form>

						{/* <div className="social-auth-links text-center mb-3">
							<p>- OR -</p>
							<a href="#" className="btn btn-block btn-primary">
								<i className="fab fa-facebook mr-2"></i> Sign in using Facebook
                			</a>
							<a href="#" className="btn btn-block btn-danger">
								<i className="fab fa-google-plus mr-2"></i> Sign in using Google+
                			</a>
						</div> */}


						<p className="mb-1">
							<Link to="/forgotpassword">I forgot my password</Link>
						</p>
						{/* <p className="mb-0">
							<a href="register.html" className="text-center">Register a new membership</a>
						</p> */}
					</div>

				</div>
			</div>
		);
	}
}
export { LoginPage };