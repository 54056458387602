import React from "react";
import { HttpMethod, HttpWrapper } from "src/helpers/httpWrapper";
import { URLWrapper } from "src/networked-shared-interfaces/url-wrapper";
import { toastService } from "src/services";

interface Props {
  userId: string;
  loading: boolean;
  isOpen: boolean;
  onClose: () => void;
}

interface State {
  password: string;
  loading: boolean;
}

class PasswordForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
	  password: "",
      loading: this.props.loading,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot?: any): void {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  handleSubmit = () => {
    if(!this.state.password) {
		toastService.error("Please enter password")
		return; 
	}
    this.setState({
      loading: true,
    });

    HttpWrapper.callUrl<any, any>(
      URLWrapper.urls.platformAdmin.userPassword,
      HttpMethod.POST,
      {
        userId: this.props.userId,
		password: this.state.password
	  },
      {}
    )
      .then(() => {
        toastService.success(`Password changed successfully`);
      })
      .catch((err) => {
        toastService.error(err ? err.message : "Failed to update password");
	})
	.finally(() => {
		// this.props.
		this.setState({
		  loading: false,
		});
		this.props.onClose();
	  })
	  ;
  };
  render() {
    const { isOpen, loading, onClose } = this.props;
    if (!isOpen) return false;

    return (
      <div
        className='modal d-block'
        data-show='true'
        style={{ background: "rgba(0,0,0,0.4)" }}
        role='dialog'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Password</h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
                onClick={() => onClose()}
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='input-group mb-3'>
                <input
                  type='text'
                  className='form-control'
                  value={this.state.password}
				  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
            </div>
            <div className='modal-footer'>
              {this.state.loading && (
                <div className='spinner-border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              )}
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => onClose()}
              >
                Close
              </button>
              <button
                type='button'
                disabled={loading}
                className='btn btn-primary'
                onClick={() => this.handleSubmit()}
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { PasswordForm };
