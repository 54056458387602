import React, { Component } from 'react';
import { HttpWrapper } from './httpWrapper';

interface IProps {
	url: string,
	alt: string
	style?: any,
	defaultClass?: any,
	imageType?: string,
	imageSize?: any,
	title?: string
}

export default class ProfileImage extends Component<IProps, any> {
	render() {
		const { imageType, imageSize, defaultClass, title } = this.props,
			image = this.props.url ? HttpWrapper.formAbsoluteURL(this.props.url) : '',
			alt = this.props.alt ? this.props.alt.match(/\b(\w)/g).join('').slice(0, 2) : 'NW',
			imgFrame = imageSize !== '' ? "-" + imageSize : "";
		const classes = defaultClass && defaultClass !== undefined ? defaultClass : '';
		return (
			<div className={`${imageType === 'circle' ? 'image-container' + imgFrame : imageType === 'square' ? 'imageFrame' + imgFrame : ''}`} title={title}>
				{this.props.url && <img src={image} alt={this.props.alt} className={classes} style={this.props.style} />}
				{!this.props.url && <div className={`textImage text-center ${classes}`}>{alt.toUpperCase()}</div>}
			</div>
		)
	}
}
