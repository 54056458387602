import React from 'react';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { HttpMethod, HttpWrapper } from '../../../helpers/httpWrapper';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import Searchuser from './SearchUser';
import { IUserRequest, IUserResponse, VUser, IUserUpdateRequest, IUserUpdateResponse, VUserUpdate } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/user';
import { getMonthDuration } from '../communities/helper/Timestap';
import { toastService } from '../../../services';
import { ModelStatus } from '../../../networked-shared-interfaces/constants/model-status';
import { ExportCSV } from '../../../helpers';
import { Loader } from '../common/Loader';
import { userStatusEnums } from '../../../constants/common';
import './userlist.css';
import DeleteUser from './DeleteUser';
import UserBlukupload from './UserBlukUpload';
import { OtherActionsButton } from './OtherActionsButton';

interface IState {
	loading: boolean,
	userStatusLoading: boolean,
	count: number,
	totalCount: number,
	paged: number,
	filter: {
		query: string,
		status: ModelStatus,
		onboardDate: string
	},
	list: any[],
	currentPage: number,
	downloadContent: any,
	currentEditUser: string,
	currentDeleteUser: string
}

class UserList extends React.Component<any, IState> {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			userStatusLoading: false,
			count: 10,
			totalCount: 0,
			paged: 1,
			list: [],
			filter: {
				query: undefined,
				status: undefined,
				onboardDate: undefined
			},
			currentPage: 1,
			downloadContent: '',
			currentEditUser: '',
			currentDeleteUser: ''
		};
	}

	componentDidMount() {
		this.getUsers();
	}

	getUsers = () => {
		const { paged, count, filter: { query, status, onboardDate } } = this.state;
		this.setState({ loading: true });

		HttpWrapper.callUrl<IUserRequest, IUserResponse>(URLWrapper.urls.platformAdmin.user, HttpMethod.GET, {
			start: (paged - 1) * count,
			count: count,
			query: query || undefined,
			status: status || undefined,
			onboardDate: onboardDate || undefined
		}, VUser)
			.then((response: any) => {
				this.setState({ loading: false, list: response.content, totalCount: response.totalCount });
			}).catch(error => {
				this.setState({ loading: false });
				toastService.error(error.message);
			})
	}

	downloadLists = (e) => {
		e.preventDefault();
		const { userStatusLoading, filter: { query, status, onboardDate } } = this.state;
		if (userStatusLoading) return;
		this.setState({ userStatusLoading: true });
		HttpWrapper.callUrl<IUserRequest, IUserResponse>(URLWrapper.urls.platformAdmin.user,
			HttpMethod.GET, {
			start: 0,
			count: -1,
			query: query,
			status: status,
			onboardDate: onboardDate
		}, VUser)
			.then((response: any) => {
				const data = response.content.map((list) => {
					return {
						userId: list.userId,
						userName: list.name,
						company: list.company,
						location: list.location
					}
				}),
					headers = [
						{ label: "user Id", key: "userId" },
						{ label: "Name", key: "userName" },
						{ label: "company", key: "company" },
						{ label: "Location", key: "location" }
					];

				//@ts-ignore
				this.setState({ userStatusLoading: false, downloadContent: <ExportCSV data={data} className="download-users" headers={headers} filename='users.csv' /> });
				//@ts-ignore
				document.getElementsByClassName('download-users')[0].click();

			}).catch((error) => {
				this.setState({ userStatusLoading: false });
				toastService.error(error.message);
			})
	}

	changeUserStatus = (userId: any) => {
		this.setState({ userStatusLoading: true });
		HttpWrapper.callUrl<IUserUpdateRequest, IUserUpdateResponse>
			(URLWrapper.urls.platformAdmin.user, HttpMethod.PUT, {
				userId: this.state.currentEditUser,
				status: Number((document.getElementById('user_status_' + userId) as HTMLInputElement).value)
			}, VUserUpdate)
			.then((response: any) => {
				this.setState({ userStatusLoading: false, currentEditUser: '' });
				toastService.success("Status Updated");
			}).catch(error => {
				this.setState({ userStatusLoading: false });
				toastService.error(error.message);
			})
	}

	handleStatusChange = (e, userId) => {
		const { list } = this.state;
		const value = e.target.value;
		let finalList = list.map((data) => {
			if (data.userId === userId)
				data.status = value;
			return data;
		});
		this.setState({ list: finalList });
	}

	handleFilterChange = (field, value) => {
		const { filter } = this.state;
		filter[field] = value;

		this.setState({ filter: filter });
	}

	handleReset = () => {
		this.setState({
			filter: {
				query: undefined,
				status: undefined,
				onboardDate: undefined
			}
		}, () => this.handleSearch())
	}

	handleSearch = () => {
		this.setState({
			paged: 1,
			list: []
		}, () => this.getUsers());
	}

	paginate = (paged) => {
		this.setState({ currentPage: paged, paged: paged }, () => this.getUsers());
	}


	render() {
		const {
			list, loading, count, totalCount, currentPage, downloadContent, userStatusLoading,
			currentEditUser, currentDeleteUser
		} = this.state;

		return (
			<>
				<div className="content-wrapper">
					<section className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<div className="col-sm-6">
									<h1>Users</h1>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<li className="breadcrumb-item"><Link to="/home">Home</Link></li>
										<li className="breadcrumb-item active">Users</li>
									</ol>
								</div>
							</div>
						</div>
					</section>
					<section className="content">
						<Searchuser
							handleFilterChange={this.handleFilterChange}
							handleSearch={this.handleSearch}
							handleReset={this.handleReset}
						/>
						<span style={{ display: 'none' }}>{downloadContent}</span>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Users ({totalCount})</h3>
								<div className="card-tools">
									<div className='d-flex'>
										{list.length > 0 && !loading && <button type="button" className="btn btn-primary mr-2" ><a href="/" style={{ color: 'white' }} onClick={this.downloadLists}><i className="fa fa-download" aria-hidden="true"></i> &nbsp;&nbsp; Download</a></button>}
										{list.length > 0 && !loading && <div className="mr-1 mt-1">
											<Pagination
												activePage={currentPage}
												itemsCountPerPage={count}
												totalItemsCount={totalCount}
												pageRangeDisplayed={count}
												onChange={this.paginate}
												itemClass="page-item"
												linkClass="page-link"
											/>
										</div>}
										<UserBlukupload />
									</div>
								</div>
							</div>
							<div className="card-body p-0">
								{loading && <Loader />}
								{list.length === 0 && !loading && <div className="alert alert-info alert-dismissible">
									<h5><i className="icon fas fa-info"></i> No Users Found!</h5>
								</div>}
								{list.length > 0 && !loading && <table className="table table-striped projects">
									<thead>
										<tr>
											<th> User Name </th>
											<th> Community </th>
											<th> On Boarded Date </th>
											<th> Title </th>
											<th> Location </th>
											<th> Status </th>
											<th> </th>
											<th> </th>
										</tr>
									</thead>
									<tbody>
										{list && list.length > 0 && list.map((user: any, index) => <tr key={index}>
											<td>{user.name}</td>
											<td>{user.onboardCompany}</td>
											<td><span>{getMonthDuration(user.onboardTime, undefined)}</span></td>
											<td>{user.designation}</td>
											<td>{user.location}</td>
											<td>
												{user.userId !== currentEditUser && <a href="/"
													onClick={(e) => { e.preventDefault(); this.setState({ currentEditUser: user.userId }) }}
													id={`user_edit_${user.userId}`} >
													<i className="fas fa-pencil-alt"> Edit Status</i>
												</a>}
												{user.userId === currentEditUser && <a href="/"
													onClick={(e) => { e.preventDefault(); this.changeUserStatus(user.userId) }}
													id={`user_save_${user.userId}`}>
													<i className="fas fa-save"> Save</i>
												</a>}
												<select placeholder="Select Community Level" className="form-control" id={"user_status_" + user.userId}
													value={user.status}
													disabled={user.userId !== currentEditUser}
													onChange={(e) => this.handleStatusChange(e, user.userId)}
												>
													{userStatusEnums.map((val, index) => <option value={index} key={index}>{val}</option>)}
												</select>
												{user.userId === currentEditUser && userStatusLoading && <Loader />}
											</td>
											<td className="project-actions text-right">
												<div className='d-flex'>
													<Link className="btn btn-info btn-sm mr-2"
														to={{ pathname: `/user/${user.userId}`, communityId: user.userId, user: user }}>
														<i className="fas fa-pencil-alt">
														</i> Edit</Link>
													<a className="btn btn-danger btn-sm" href="#DeleteUser" data-toggle="modal"
														onClick={() => this.setState({ currentDeleteUser: user.userId })}
														data-target={`#DeleteUser`}>
														<i className="fas fa-trash">
														</i> Delete </a>
												</div>
											</td>
											<td><OtherActionsButton userId={user.userId} /></td>
										</tr>)}
									</tbody>
								</table>}
								<DeleteUser userId={currentDeleteUser} callback={this.getUsers} />
							</div>
						</div>
					</section>
				</div>
			</>
		)
	}

}

export { UserList }