import moment from 'moment';
import { split } from 'ramda';
import { monthDiff } from './date';

export function getMonthDuration(from, to) {
	const fromTime = from ? moment(from).format('MMM YYYY') : '',
		toTime = to ? moment(to).format('MMM YYYY') : 'present',
		interval = monthDiff(from, to),
		exYear = Math.trunc(interval / 12),
		exMonth = exYear === 0 ? interval : interval - (exYear * 12),
		yearLabel = exYear === 1 ? ' year ' : ' years ',
		monthLabel = (interval === 1) ? ' month' : ' months';

	let output = '';
	if (exYear >= 1)
		output = exYear + yearLabel + (exMonth > 0 ? ' ' + (exMonth + monthLabel) : '');
	else
		output = interval + monthLabel;

	return fromTime + ' | ' + output;
}

export function getMonthDiff(from, to) {
	const fromTime = from ? moment(from).format('MMM YYYY') : '',
		toTime = to ? moment(to).format('MMM YYYY') : 'present',
		interval = monthDiff(from, to),
		exYear = Math.trunc(interval / 12),
		exMonth = exYear === 0 ? interval : interval - (exYear * 12),
		yearLabel = exYear === 1 ? 'year ' : 'years ',
		monthLabel = (interval === 1) ? 'month' : 'months';

	let output = '';
	if (exYear >= 1)
		output = exYear + yearLabel + (exMonth > 0 ? ' ' + (exMonth + monthLabel) : '');
	else
		output = interval + monthLabel;

	return fromTime + ' - ' + toTime + ' | ' + output;
}

export function getFileName(url) {
	if (!url) return;
	const fileName = url.split('/')[2];
	return fileName.split('_')[2];
}