import React from 'react';
import { pluck } from 'ramda';
import { authHeader } from '../../../helpers/auth-header';
import { HttpMethod, HttpWrapper } from '../../../helpers/httpWrapper';
import { CommunityVisiblity } from '../../../networked-shared-interfaces/constants/community-visiblity';
import { IClassificationResponse } from '../../../networked-shared-interfaces/global-routes/constant-routes';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import Accordion from './Accordion';
import ClassificationTypehead from './helper/Classification';
import VisibilityTypehead from './helper/Visibility';
import { map } from 'ramda'
import { ICommunityRequest, ICommunityResponse, VCommunity } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/community';
import { communityStatusEnums } from '../../../constants/common';

interface IProps {
	handleFilterChange: any,
	handleSearch: any,
	handleReset: any,
	showStatus: boolean
}

interface IState {
	AllClassifications: any[],
	formData: {
		keyword: any,
		classifications: any[]
		communityLevel: any,
		visibility: any,
		status: any
	}
}
class Searchbox extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			AllClassifications: [],
			formData: {
				classifications: [],
				keyword: '',
				communityLevel: '',
				visibility: '',
				status: ''
			}
		}
	}

	componentDidMount() {
		this.getAllClassifications()
	}
	getAllClassifications = () => {
		HttpWrapper.callUrl<any, IClassificationResponse>(URLWrapper.urls.globalRoutes.classifications, HttpMethod.GET, {
		}, {}, authHeader())
			.then((response) => {
				this.setState({
					AllClassifications: response
				})
			}).catch((error) => {
				//Custom Error Handler
				console.log("error in get all interests ", error)
			})
	}

	resetFilter = () => {
		this.setState({
			formData: {
				classifications: [],
				keyword: '',
				communityLevel: '',
				visibility: '',
				status: ''
			}
		}, () => this.props.handleReset());
	}

	submit = () => {
		this.props.handleSearch()

	}
	render() {

		const { formData } = this.state,
			{ showStatus } = this.props;

		return (
			<Accordion>
				<div style={{ minHeight: '200px' }}>
					<div className="basicInfoFields p-3">

						<div className="form-row">
							{/* <div className="form-group col-md-3">
								<label htmlFor="Classification">Community Level</label>
								<select placeholder="Select Community Level" className="form-control" id="exampleFormControlSelect1"
									onChange={(e) => { this.setState({ formData: { ...formData, communityLevel: e.target.value } }, () => this.props.handleFilterChange('layer', this.state.formData.communityLevel)) }}
									value={formData.communityLevel}>
									<option>Select community level</option>
									<option value={0}>Main</option>
									<option value={1}>Sub</option>
									<option value={2}>Affiliated community</option>
								</select>
							</div> */}
							{showStatus && <div className="form-group col-md-3">
								<label htmlFor="userStatus">Status</label>
								<select placeholder="Select user status" className="form-control" id="communityStatus"
									value={formData.status}
									onChange={(e) => { this.setState({ formData: { ...formData, status: e.target.value } }, () => this.props.handleFilterChange('status', this.state.formData.status)) }}
								>
									<option value="">Select status</option>
									{communityStatusEnums.map((val, index) => <option value={index} key={index}>{val}</option>)}
								</select>
							</div>}
							<div className="form-group col-md-3">
								<label htmlFor="Classification">Visibility</label>
								<select className="form-control" id="exampleFormControlSelect1"
									onChange={(e) => { this.setState({ formData: { ...formData, visibility: e.target.value } }, () => this.props.handleFilterChange('visibility', this.state.formData.visibility)) }}
									value={formData.visibility}>
									<option>Select visibility</option>
									<option value={0}>Public</option>
									<option value={1}>Private</option>
									<option value={2}>Hidden</option>
								</select>
								{/* <VisibilityTypehead
									options={this.state.visibilityEnum}
									// options={CommunityVisiblity}
									multiple={true}
									handleVisibilityChange={selected => { this.handleVisibilityChange(selected, CommunityVisiblity) }}

								/> */}
							</div>

							<div className="form-group col-md-3">
								<label htmlFor="Classification">Classification</label>
								<ClassificationTypehead
									//defaultSelected={this.state.formData.classificationsNames}
									selected={formData.classifications}
									options={this.state.AllClassifications}
									multiple={true}
									handleClassificationChange={selected => {
										this.setState({ formData: { ...formData, classifications: selected } }, () => {
											this.props.handleFilterChange('classification', selected.length > 0 ? this.state.formData.classifications : []);
										})
									}}
								/>
							</div>
							<div className="form-group col-md-3">
								<label htmlFor="Classification">Keyword</label>
								<input className="form-control" type="search" aria-label="Search"
									placeholder="Keyword"
									value={formData.keyword}
									onChange={(e) => { this.setState({ formData: { ...formData, keyword: e.target.value } }, () => this.props.handleFilterChange('query', this.state.formData.keyword)) }} />
							</div>

						</div>
					</div>
					<div className='float-right pr-3'>
						<button type="button" className="btn btn-primary mr-2" onClick={() => { this.resetFilter(); }}>Reset</button>
						<button type="button" className="btn btn-success mr-17" onClick={this.props.handleSearch}>Search</button>
					</div>

				</div>
			</Accordion>
		)
	}
}

export default Searchbox;