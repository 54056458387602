import React from 'react';
import { Link } from 'react-router-dom';
import { pluck } from 'ramda';
import Pagination from "react-js-pagination";
import { HttpWrapper, HttpMethod } from '../../../helpers/httpWrapper';
import { ExportCSV } from '../../../helpers';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import DeleteCareerOpportunities from './Delete';
import Bulkupload from './UploadBulk';
import Searchbox from './Search';
import { ICommunityRequest, ICommunityResponse, VCommunity, ICommunityUpdateRequest, ICommunityUpdateResponse, VCommunityUpdate, ICommunityUpdateEmailSettingRequest, ICommunityUpdateEmailSettingResponse, VCommunityEmailSettingUpdate, ICommunityUpdateExploreFlagRequest, ICommunityUpdateExploreFlagResponse, VCommunityExplorFlagUpdate } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/community';
import { getMonthDuration } from './helper/Timestap';
import { toastService } from '../../../services';
import { Loader } from '../common/Loader';
import { ModelStatus } from '../../../networked-shared-interfaces/constants/model-status';
import { CommunityVisiblity } from '../../../networked-shared-interfaces/constants/community-visiblity';
import { communityStatusEnums } from '../../../constants/common';
import { OtherActionsButton } from './OtherActionsButton';
import { IToggleCommunityStatus, VToggleCommunityStatus } from 'src/networked-shared-interfaces/stripe-route/stripe-route';

interface IState {
	loading: boolean,
	communityStatusLoading: boolean,
	count: number,
	totalCount: number,
	paged: number,
	filter: {
		query: string,
		status: ModelStatus,
		layer: number,
		visibility: CommunityVisiblity,
		classification: any
	},
	list: any[],
	currentPage: number,
	downloadContent: any,
	currentEditCommunity: string,
	currentDeleteCommunity: string,
	emailNotificationLoading: boolean,
	exploreFlagLoading: boolean,
	toggleCommunityLoading: boolean,
	currentEditENCommunity: string,
	currentToggleCommunity: string,
	currentEditExploreCommunity: string
}

class List extends React.Component<any, IState> {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			communityStatusLoading: false,
			count: 10,
			totalCount: 0,
			paged: 1,
			list: [],
			filter: {
				query: undefined,
				status: undefined,
				layer: undefined,
				visibility: undefined,
				classification: []
			},
			currentPage: 1,
			downloadContent: '',
			currentEditCommunity: '',
			currentDeleteCommunity: '',
			emailNotificationLoading: true,
			exploreFlagLoading: true,
			toggleCommunityLoading: true,
			currentEditENCommunity: '',
			currentToggleCommunity: '',
			currentEditExploreCommunity: ''
		};
	}

	componentDidMount() {
		this.getCommunities();
	}

	getCommunities = () => {
		const { paged, count, filter: { query, status, layer, visibility, classification } } = this.state;
		this.setState({ loading: true });
		HttpWrapper.callUrl<ICommunityRequest, ICommunityResponse>(URLWrapper.urls.platformAdmin.community,
			HttpMethod.GET, {
			start: (paged - 1) * count,
			count: count,
			query: query,
			status: status,
			layer: layer,
			visibility: visibility,
			classification: classification.length > 0 ? pluck(['classificationId'], classification).join(',') : undefined
		}, VCommunity)
			.then((response) => {
				this.setState({ loading: false, list: response.content, totalCount: response.totalCount });
			}).catch((error) => {
				this.setState({ loading: false });
				toastService.error(error.message);
			})
	}

	downloadLists = (e) => {
		e.preventDefault();
		const { communityStatusLoading, filter: { query, status, layer, visibility } } = this.state;
		if (communityStatusLoading) return;
		this.setState({ communityStatusLoading: true });
		HttpWrapper.callUrl<ICommunityRequest, ICommunityResponse>(URLWrapper.urls.platformAdmin.community,
			HttpMethod.GET, {
			start: 0,
			count: -1,
			query: query,
			status: status,
			layer: layer,
			visibility: visibility
		}, VCommunity)
			.then((response: any) => {
				const data = response.content.map((list) => {
					return {
						communityId: list.communityId,
						communityName: list.name,
						location: list.location,
						visibility: list.visibility
					}
				}),
					headers = [
						{ label: "community Id", key: "communityId" },
						{ label: "community Name", key: "communityName" },
						{ label: "Location", key: "location" },
						{ label: "Visibility", key: "visibility" }
					];
				//@ts-ignore
				this.setState({ communityStatusLoading: false, downloadContent: <ExportCSV data={data} className="download-communities" headers={headers} filename='communities.csv' /> });
				//@ts-ignore
				document.getElementsByClassName('download-communities')[0].click();

			}).catch((error) => {
				this.setState({ communityStatusLoading: false });
				toastService.error(error.message);
			})
	}

	changeCommunityStatus = (communityId: any) => {
		this.setState({ communityStatusLoading: true });
		HttpWrapper.callUrl<ICommunityUpdateRequest, ICommunityUpdateResponse>
			(URLWrapper.urls.platformAdmin.community, HttpMethod.PUT, {
				communityId: this.state.currentEditCommunity,
				status: Number((document.getElementById('community_status_' + communityId) as HTMLInputElement).value)
			}, VCommunityUpdate)
			.then((response: any) => {
				this.setState({ communityStatusLoading: false, currentEditCommunity: '' });
				toastService.success("Status Updated")
			}).catch(error => {
				this.setState({ communityStatusLoading: false });
				toastService.error(error.message)
			})

	}

	handleStatusChange = (e, communityId) => {
		const { list } = this.state;
		const value = e.target.value;
		let finalList = list.map((data) => {
			if (data.communityId === communityId)
				data.status = value;
			return data;
		});
		this.setState({ list: finalList });
	}

	handleFilterChange = (field, value) => {
		const { filter } = this.state;
		filter[field] = value;

		this.setState({ filter: filter });
	}

	handleReset = () => {
		this.setState({
			filter: {
				query: undefined,
				status: undefined,
				layer: undefined,
				visibility: undefined,
				classification: []
			},
			currentEditCommunity: ''
		}, () => this.handleSearch())
	}

	handleSearch = () => {
		this.setState({
			paged: 1,
			list: [],
			currentEditCommunity: ''
		}, () => this.getCommunities());
	}

	paginate = (paged) => {
		this.setState({ currentPage: paged, paged: paged, currentEditCommunity: '' }, () => this.getCommunities());
	}

	changeEmailNotification = (communityId, flag) => {
		this.setState({ currentEditENCommunity: communityId, emailNotificationLoading: true }, () => {
			HttpWrapper.callUrl<ICommunityUpdateEmailSettingRequest, ICommunityUpdateEmailSettingResponse>
				(URLWrapper.urls.platformAdmin.communityEmailFlag, HttpMethod.PUT, {
					communityId: communityId,
					platformAdminEmailFlag: flag
				}, VCommunityEmailSettingUpdate)
				.then((response: any) => {
					this.setState({ emailNotificationLoading: false, currentEditENCommunity: '' });

					const { list } = this.state;
					const value = flag;
					let finalList = list.map((data) => {
						if (data.communityId === communityId)
							data.emailFlag = value;
						return data;
					});
					this.setState({ list: finalList });

					toastService.success("Status Updated")
				}).catch(error => {
					this.setState({ emailNotificationLoading: false, currentEditENCommunity: '' });
					toastService.error(error.message)
				})
		})
	}

	changeExploreFlag = (communityId, flag) => {
		this.setState({ currentEditExploreCommunity: communityId, exploreFlagLoading: true }, () => {
			HttpWrapper.callUrl<ICommunityUpdateExploreFlagRequest, ICommunityUpdateExploreFlagResponse>
				(URLWrapper.urls.platformAdmin.communityExploreFlag, HttpMethod.PUT, {
					communityId: communityId,
					isVisibleOnExplore: flag
				}, VCommunityExplorFlagUpdate)
				.then((response: any) => {
					this.setState({ exploreFlagLoading: false, currentEditExploreCommunity: '' });

					const { list } = this.state;
					const value = flag;
					let finalList = list.map((data) => {
						if (data.communityId === communityId)
							data.isVisibleOnExplore = value;
						return data;
					});
					this.setState({ list: finalList });

					toastService.success("Status Updated")
				}).catch(error => {
					this.setState({ exploreFlagLoading: false, currentEditExploreCommunity: '' });
					toastService.error(error.message)
				})
		})
	}

	togglePaidCommunity = (communityId, flag) => {
		this.setState({ currentToggleCommunity: communityId, toggleCommunityLoading: true }, () => {
			HttpWrapper.callUrl<IToggleCommunityStatus, {}>
				(URLWrapper.urls.stripe.toggleCommunityStatus, HttpMethod.POST, {
					communityId: communityId,
					isPaid: flag
				}, VToggleCommunityStatus)
				.then(() => {
					this.setState({ toggleCommunityLoading: false, currentToggleCommunity: '' });

					const { list } = this.state;
					const value = flag;
					let finalList = list.map((data) => {
						if (data.communityId === communityId)
							data.paidCommunity = value;
						return data;
					});
					this.setState({ list: finalList });

					toastService.success("Status Updated")
				}).catch(error => {
					this.setState({ toggleCommunityLoading: false, currentToggleCommunity: '' });
					toastService.error(error.message)
				})
		})
	}


	render() {
		const {
			list, loading, count, totalCount, currentPage, downloadContent, communityStatusLoading,
			currentEditCommunity, currentDeleteCommunity, emailNotificationLoading, exploreFlagLoading, currentEditENCommunity,
			currentEditExploreCommunity,
			toggleCommunityLoading, currentToggleCommunity
		} = this.state;

		return (
			<>
				<div className="content-wrapper">
					<section className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<div className="col-sm-6">
									<h1>Community</h1>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<li className="breadcrumb-item"><Link to="/home">Home</Link></li>
										<li className="breadcrumb-item active">Community Requests</li>
									</ol>
								</div>
							</div>
						</div>
					</section>
					<section className="content">
						<Searchbox
							handleFilterChange={this.handleFilterChange}
							handleSearch={this.handleSearch}
							handleReset={this.handleReset}
							showStatus={true}
						/>
						<span style={{ display: 'none' }}>{downloadContent}</span>
						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Communities ({totalCount})</h3>
								<div className="card-tools">
									<div className='d-flex'>
										{list.length > 0 && !loading && <button type="button" className="btn btn-primary mr-2" ><a href="/" style={{ color: 'white' }} onClick={this.downloadLists}><i className="fa fa-download" aria-hidden="true"></i> &nbsp;&nbsp; Download</a></button>}
										{list.length > 0 && !loading && <div className="mr-1 mt-1">
											<Pagination
												activePage={currentPage}
												itemsCountPerPage={count}
												totalItemsCount={totalCount}
												pageRangeDisplayed={count}
												onChange={this.paginate}
												itemClass="page-item"
												linkClass="page-link"
											/>
										</div>}
										<Bulkupload />
									</div>
								</div>
							</div>
							<div className="card-body p-0 table-responsive">
								{loading && <Loader />}
								{list.length === 0 && !loading && <div className="alert alert-info alert-dismissible">
									<h5><i className="icon fas fa-info"></i> No communities Found!</h5>
								</div>}
								{list.length > 0 && !loading && <table className="table table-striped projects">
									<thead>
										<tr>
											<th> Community Name </th>
											<th> Community Admin </th>
											<th> On Boarded Date </th>
											<th> Users </th>
											<th> Classification </th>
											<th> Location </th>
											<th> Status </th>
											<th> </th>
											<th> Email notification</th>
											<th> Explore View</th>
											<th> Paid Community</th>
											<th> </th>
										</tr>
									</thead>
									<tbody>
										{list && list.length > 0 && list.map((community: any, index) => <tr key={index}>
											<td>{community.name}</td>
											<td>{community.adminUser !== undefined && community.adminUser.name}</td>
											<td><span>{getMonthDuration(community.createTimestamp, undefined)}</span></td>
											<td>{community.totalUser}</td>
											<td>{community.classifications.map((name: any, index: number) => <p key={index}>{name.name}</p>)}</td>
											<td>{community.location}</td>
											<td>
												{community.communityId !== currentEditCommunity && <a href="/"
													onClick={(e) => { e.preventDefault(); this.setState({ currentEditCommunity: community.communityId }) }}
													id={`user_edit_${community.communityId}`} >
													<i className="fas fa-pencil-alt"> Edit Status</i>
												</a>}
												{community.communityId === currentEditCommunity && <a href="/"
													onClick={(e) => { e.preventDefault(); this.changeCommunityStatus(community.communityId) }}
													id={`user_save_${community.communityId}`}>
													<i className="fas fa-save"> Save</i>
												</a>}
												<select placeholder="Select Community Level" className="form-control" id={"community_status_" + community.communityId}
													value={community.status}
													disabled={community.communityId !== currentEditCommunity}
													onChange={(e) => this.handleStatusChange(e, community.communityId)}
												>
													{communityStatusEnums.map((val, index) => <option value={index} key={index}>{val}</option>)}
												</select>
												{community.communityId === currentEditCommunity && communityStatusLoading && <Loader />}
											</td>
											<td className="project-actions text-right">
												<div className='d-flex'>
													<Link className="btn btn-info btn-sm mr-2"
														to={{ pathname: `/community/${community.communityId}`, communityId: community.communityId, community: community }}>
														<i className="fas fa-pencil-alt">
														</i> Edit</Link>
													<a className="btn btn-danger btn-sm" href="#DeleteCommunity" data-toggle="modal"
														onClick={() => this.setState({ currentDeleteCommunity: community.communityId })}
														data-target={`#DeleteCommunity`}>
														<i className="fas fa-trash">
														</i> Delete </a>
												</div>
											</td>
											<td>
												<div className="custom-control custom-switch text-center">
													<input type="checkbox" checked={community.emailFlag} onChange={() => this.changeEmailNotification(community.communityId, !community.emailFlag)} id={"customENSwitch" + community.communityId} className="custom-control-input" />
													{(community.communityId === currentEditENCommunity && emailNotificationLoading) && <Loader />}
													<label className="custom-control-label" htmlFor={"customENSwitch" + community.communityId}></label>
												</div>
											</td>
											<td>
												<div className="custom-control custom-switch text-center">
													<input type="checkbox" checked={community.isVisibleOnExplore} onChange={() => this.changeExploreFlag(community.communityId, !community.isVisibleOnExplore)} id={"customExploreSwitch" + community.communityId} className="custom-control-input" />
													{(community.communityId === currentEditExploreCommunity && exploreFlagLoading) && <Loader />}
													<label className="custom-control-label" htmlFor={"customExploreSwitch" + community.communityId}></label>
												</div>
											</td>
											<td>
												<div className="custom-control custom-switch text-center">
													<input type="checkbox" checked={community.paidCommunity} onChange={() => this.togglePaidCommunity(community.communityId, !community.paidCommunity)} id={"togglePaid" + community.communityId} className="custom-control-input" />
													{(community.communityId === currentToggleCommunity && toggleCommunityLoading) && <Loader />}
													<label className="custom-control-label" htmlFor={"togglePaid" + community.communityId}></label>
												</div>
											</td>
											<OtherActionsButton communityId={community.communityId} />
										</tr>)}
									</tbody>
								</table>}
								<DeleteCareerOpportunities communityId={currentDeleteCommunity} callback={this.getCommunities} />
							</div>
						</div>
					</section>
				</div>
			</>
		);
	}
}

export { List }