import { ModelStatus } from "../../../constants/model-status";
import { ValidationUtility } from "../../../validation-utility";
import { IValidatorType } from "../../../validator-interface";
import { IFeature, IIndustry } from "../platform-admin";

//Req [GET] /platform/admin/industry/community
export interface IIndustryCommunityCountGetRequest { }

//Val [GET] /platform/admin/industry/community
export const VIndustryComGet: IValidatorType<IIndustryCommunityCountGetRequest> = {}

//Res [GET] /platform/admin/industry/community
export type IIndustryCommunityCountGetResponse = {
    id: any,
    name: string,
    communityCount: number
}[]

//--------------------------------------------------------------------------------------------------

//Req [GET] /platform/admin/industry
export interface IIndustryGetRequest {
    start: number,
	count: number,
	keyword?: any,
	status?: ModelStatus
 }

//Val [GET] /platform/admin/industry
export const VIndustryGet: IValidatorType<IIndustryGetRequest> = {
    start: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	count: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	keyword: { function: ValidationUtility.validateStringFields, params: [0, -1], required: false },
    status: { function: ValidationUtility.validateNumber, params: [0, -1], required: false }
}

//Res [GET] /platform/admin/industry
export interface IIndustryGetResponse {
	start: number,
	count: number,
	nextStart?: boolean,
    totalCount: number,
	content: IIndustry[]
}

//--------------------------------------------------------------------------------------------------


//Req [POST] /platform/admin/industry
export interface IIndustryPostRequest {
    name:string
 }

//Val [POST] /platform/admin/industry
export const VIndustryPost: IValidatorType<IIndustryPostRequest> = {
    name: { function: ValidationUtility.validateStringFields, params: [0, -1], required: true },
}

//Res [POST] /platform/admin/industry
export type IIndustryPostResponse = IIndustry

//--------------------------------------------------------------------------------------------------


//Req [PUT] /platform/admin/industry
export interface IIndustryPutRequest {
    id:any,
    name:string
 }

//Val [PUT] /platform/admin/industry
export const VIndustryPut: IValidatorType<IIndustryPutRequest> = {
    id: { function: ValidationUtility.validateObjectId, params: [], required: true },
    name: { function: ValidationUtility.validateStringFields, params: [0, -1], required: true },
}

//Res [PUT] /platform/admin/industry
export type IIndustryPutResponse = IIndustry

//--------------------------------------------------------------------------------------------------


//Req [DELETE] /platform/admin/industry
export interface IIndustryDeleteRequest {
    id: any
 }

//Val [DELETE] /platform/admin/industry
export const VIndustryDelete: IValidatorType<IIndustryDeleteRequest> = {
    id: { function: ValidationUtility.validateObjectId, params: [], required: true }
}

//Res [DELETE] /platform/admin/industry
export interface IIndustryDeleteResponse {}