import { replace, slice, length, isEmpty } from 'ramda';
import { communityStatusEnums, userStatusEnums } from '../constants/common';
const querystring = require('querystring');

export const formatGetUrl = (url, params) => {
	return url + '?' + querystring.stringify(params);
}


// const querystring = require('querystring');

// export const formatGetUrl = (url, params) => {
// 	return url + '?' + querystring.stringify(params);
// }

export const replaceUrlParam = (url, params) => {
	//Need to change dynamically, for now staic :id
	return replace(':id', params.id, url);
}

export const handleResponse = (response) => {
	if (response.text) //Handle fetch() errors
	{
		return response.text().then(text => {
			const data = text && JSON.parse(text);

			if (!response.ok) {
				// if (response.status >= 400) {
				//     // auto logout if 401 response returned from api
				//     localStorage.removeItem('user'); //logout
				//     window.location.reload(true);
				// }

				const error = (data && data.message) || response.statusText;
				return Promise.reject(error);
			}

			return data;
		});
	}
	else if (!response) {
		localStorage.removeItem('user'); //logout
		window.location.reload();
	} else { //Handle axios() errors
		if (response.status === 200) {
			const { data = [] } = response
			if (data && data.message) {
				return Promise.reject((data && data.message) || response.statusText);
			}
			return response.data
		}
		// if (response.status >= 400) {
		//     // auto logout if 401 response returned from api
		//     localStorage.removeItem('user'); //logout
		//     window.location.reload(true);
		// }

		const error = (response.data && response.data.message) || response.statusText;
		return Promise.reject(error);
	}

}

export const handleError = (response) => {
	// const error = (response.data && response.data.message) || response.statusText;
	// return Promise.reject(error);
}

export const stripHtml = (htmlContent = '') => {
	return htmlContent.replace(/<[^>]+>/g, '');
}

export const getScreenResolution = () => {
	var screenWith = window.screen.width;//* window.devicePixelRatio;
	var returnVal = 0;
	/* 1280 = 1, 1368 = 2, 1536 = 3, 1600 = 4, 1920 = 5*/
	//var screenHeight = screen.height;
	if (screenWith === 1280) {
		returnVal = 1;
	} else if (screenWith === 1368) {
		returnVal = 2;
	}
	else if (screenWith === 1536) {
		returnVal = 3;
	} else if (screenWith === 1600) {
		returnVal = 4;
	} else if (screenWith === 1920) {
		returnVal = 5;
	}
	return returnVal;
}

export const getFileName = (url) => {
	if (!url) return;
	const fileName = url.split('/')[2];
	return fileName.split('_').slice(2).join('_');
}

export const textTruncate = (inputText, textLen) => {
	const defineLen = textLen || 50,
		hasMoreText = length(inputText) > defineLen,
		shortContent = slice(0, defineLen, inputText);
	return (
		hasMoreText ? shortContent + '...' : inputText
	)
}

export const getCommutniyStatus = (status) => {
	if (isEmpty(status)) return '';
	return communityStatusEnums[status];
}

export const getUserStatus = (status) => {
	if (isEmpty(status)) return '';
	return userStatusEnums[status];
}