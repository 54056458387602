import React from 'react';
import { contains, head, clone, innerJoin, forEachObjIndexed, isEmpty } from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import { history } from '../../../helpers';
import { HttpMethod, HttpWrapper } from '../../../helpers/httpWrapper';
import { IUserCreateRequest, IUserCreateResponse, IUserRequest, IUserResponse, IUserUpdateRequest, IUserUpdateResponse, VUser, VUserCreate, VUserUpdate } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/user';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import { validate } from './AddUserValidate';
import { toastService } from '../../../services';
import AddandEditExperiance from './EditExperiance';
import AddandEditEducation from './EditEducation';
import AddandEditMembership from './EditMemebership';
import { ICommunityRequest, ICommunityResponse, VCommunity } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/community';
import './userlist.css';
import moment from 'moment';
import { Loader } from '../common/Loader';
import ProfileImage from '../../../helpers/profileImage';


const AsyncTypeahead = withAsync(Typeahead);
interface IState {
	formData: {
		name: string,
		title: any,
		education: any,
		experience: any,
		membership: any,
		company?: string,
		role: any,
		linkedinUrl: string,
		industry?: any,
		communityId: any,
		email: string,
		affiliatedHow?: any,
		image: string,
		about: string,
		emailFlag?: boolean,
		defaultExpetises: any[],
		expertise?: any,
		communityName: string,
		defaultCommunity: any[],
		Community: any[],
		defaultIndustries: any[],
		editExperience: any,
		editMembership: any,
		editEducation: any,
		location?: string,
		imageUrl?: string
	},

	errors: any,
	userId: string,
	submitted: boolean,
	isEmailValid: boolean,
	AllIndustries: any[],
	AllExpertise: any[],
	loading: boolean,
	fileLoading: boolean,
	fileUrl: string,
	imageId: string,
	comLoading: boolean,
	filter: string,
	count: number,
	communities: any[],
	total: number,
	nextStart: boolean,
	paginate: boolean,
	defaultCompany: any[],
	isEditable: boolean,
	start: number,
	AllCommunites: any[],
	UpdateRespose: any,
	editEducation: any,
	editMembership: any,
}

class AddUser extends React.Component<Partial<IMapState> & any, IState> {
	constructor(props: any) {
		super(props);
		const { match: { params } } = this.props;
		this.state = {
			isEditable: false,
			userId: params.id !== undefined ? params.id : '',
			start: 0,
			paginate: true,
			total: 0,
			communities: [],
			comLoading: false,
			submitted: false,
			loading: false,
			nextStart: true,
			count: 100,
			filter: '',
			formData: {
				communityId: [],
				communityName: '',
				name: '',
				title: '',
				defaultExpetises: [],
				defaultIndustries: [],
				education: [],
				experience: [],
				membership: [],
				company: '',
				role: '',
				linkedinUrl: '',
				industry: [],
				email: '',
				affiliatedHow: '',
				image: '',
				about: '',
				emailFlag: false,
				defaultCommunity: [],
				Community: [],
				editExperience: [],
				editMembership: [],
				editEducation: [],
				location: '',
				imageUrl: ''
			},
			errors: {},
			isEmailValid: true,
			AllIndustries: [],
			AllExpertise: [],
			AllCommunites: [],
			fileLoading: false,
			fileUrl: '',
			imageId: '',
			defaultCompany: [],
			UpdateRespose: [],
			editEducation: [],
			editMembership: [],
		}

		this._setUserData = this._setUserData.bind(this);
	}

	componentDidMount() {
		const { match: { params } } = this.props;

		if (params.id) {
			this.getUserInfo();
		}
	}

	getUserInfo = () => {
		const { match: { params } } = this.props;
		this.setState({ loading: true });

		HttpWrapper.callUrl<IUserRequest, IUserResponse>(URLWrapper.urls.platformAdmin.user, HttpMethod.GET,
			{ start: 0, count: 1, userId: String(params.id) }, VUser)
			.then(response => {
				if (response.content && response.content.length > 0) {
					this._setUserData(response.content[0]);
					// console.log(response.content, "response content")
				}
				this.setState({ loading: false });
			})
			.catch(err => {
				console.log(err)
				this.setState({ loading: false });
			})
	}

	_setUserData(userData) {
		const defaultIndustry: any = innerJoin(
			(indOpt, industry) => indOpt.industryId === industry.id, this.props.industries,
			userData.industry) || [];

		const { expertiseIds = [], rawExpertise = [] } = userData.expertise,
			defaultSelectedExp = innerJoin((expOpt, selected) => {
				return expOpt.expertiseId === selected
			}, this.props.expertises, expertiseIds || []),
			defaultCustomExp = rawExpertise.map((exp, key) => {
				return { customOption: true, id: "new-id-" + key, name: exp }
			}),
			defaultExpetises = [...defaultSelectedExp, ...defaultCustomExp];

		this.setState({
			//@ts-ignore
			formData: {
				...this.state.formData,
				...userData,
				title: userData.designation,
				//@ts-ignore
				role: userData.role,
				image: userData.image,
				imageUrl: userData.imageUrl,
				defaultIndustries: defaultIndustry,
				defaultExpetises: defaultExpetises,
				experience: userData.experiences,
				education: userData.education,
				membership: userData.memberships,
				location: userData.location,
				communityId: (userData.communities && userData.communities[0]) ? userData.communities[0].communityId : '',
				defaultCommunity: (userData.communities &&
					userData.communities[0]) ? [
					userData.communities[0].communityName
				] : [userData.company ? userData.company : '']
			},
			//@ts-ignore
			UpdateRespose: userData
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { match: { params } } = this.props;
		const { match: { params: pp } } = prevProps;

		if (params.id !== pp.id) {
			this.setState({
				formData: {
					communityName: '',
					name: '',
					title: '',
					education: [],
					experience: [],
					membership: [],
					company: '',
					role: '',
					linkedinUrl: '',
					industry: [],
					communityId: '',
					email: '',
					affiliatedHow: '',
					image: '',
					about: '',
					defaultExpetises: [],
					defaultIndustries: [],
					expertise: [],
					defaultCommunity: [],
					Community: [],
					editExperience: [],
					editMembership: [],
					editEducation: [],
					location: '',
					imageUrl: ''
				},
				userId: params.id !== undefined ? params.id : '',
				errors: {},
				isEmailValid: true,
				AllIndustries: [],
				AllExpertise: [],
				fileLoading: false,
				fileUrl: '',
				imageId: '',
				editEducation: {}

			});
		}

		if (prevProps.expertises !== this.props.expertises) {
			//@ts-ignore
			const { expertise } = this.state.UpdateRespose;
			let defaultSelectedExp = [];
			let defaultCustomExp = [];
			if (expertise && expertise.expertiseIds !== undefined)
				defaultSelectedExp = expertise.expertiseIds ? innerJoin((expOpt, selected) => expOpt.expertiseId === selected, this.props.expertises, expertise.expertiseIds) : [];
			if (expertise && expertise.defaultCustomExp !== undefined) {
				defaultCustomExp = expertise.rawExpertise ? expertise.rawExpertise.map((exp, key) => {
					return { customOption: true, id: "new-id-" + key, name: exp }
				}) : [];
			}
			const defaultExpetises = [...defaultSelectedExp, ...defaultCustomExp];
			this.setState({ formData: { ...this.state.formData, defaultExpetises: defaultExpetises } })
		}
	}

	removeFile = (field) => {
		this.setState((prev: any) => {
			prev.formData[field] = '';
			return prev;
		})
	}

	handleChange = (name: any, value: any) => {
		this.setState((prev: any) => {
			prev.formData[name] = (name === "role") ? Number(value) : value;
			// console.log(prev.formData, "form data");
			return prev;
		}, () => {
			if (this.state.submitted) {
				let validationFlag = validate(this.state.formData)
				console.log(validationFlag.errors)
				this.setState({ errors: validationFlag.errors })
			}
		});
	}

	handleExperience = (experience?: any, dummy?: any) => {
		const formFields = {
			title: dummy.title,
			employmentType: dummy.employmentType,
			communityName: dummy.communityName,
			location: dummy.location,
			startTime: dummy.from ? moment(dummy.from).valueOf() : dummy.startTime,
			endTime: dummy.is_present ? 0 : (dummy.to ? moment(dummy.to).valueOf() : dummy.endTime),
			description: dummy.description,
			media: dummy.media,
			mediaId: dummy.mediaId
		}
		// console.log(formFields, "dummy")
		let experiences = clone(this.state.formData.experience)
		if (experience.experienceId) {
			experiences = experiences.map(exp => {
				formFields['experienceId'] = experience.experienceId;
				return (exp.experienceId === experience.experienceId) ? formFields : exp;
			})
		} else {
			experience.experienceId = 'new' + new Date().getTime();
			experiences.push(experience)
		}
		this.setState({ formData: { ...this.state.formData, experience: experiences, editExperience: formFields } })
	}

	handleEducation = (education?: any, dummy?: any) => {
		const formFields: any = {
			communityId: dummy.communityId,
			degree: dummy.degree,
			fieldOfStudy: dummy.fieldOfStudy,
			communityName: dummy.communityName,
			location: dummy.location,
			startTime: dummy.from ? moment(dummy.from).valueOf() : dummy.startTime,
			endTime: dummy.is_present ? 0 : (dummy.to ? moment(dummy.to).valueOf() : dummy.endTime),
			description: dummy.description,
			media: dummy.media,
			mediaId: dummy.mediaId
		}
		let educations = clone(this.state.formData.education)
		if (education.educationId) {
			educations = educations.map(exp => {
				formFields['educationId'] = education.educationId;
				return (exp.educationId === education.educationId) ? formFields : exp;
			})
		} else {
			education.educationId = 'new' + new Date().getTime();
			educations.push(education)
		}
		this.setState({ formData: { ...this.state.formData, education: educations, editEducation: formFields } })
	}

	handleMembership = (membership?: any, dummy?: any) => {
		// console.log(dummy, "dummy")
		const formFields = {
			title: dummy.title,
			membershipId: dummy.membershipId,
			communityName: dummy.communityName,
			startTime: dummy.from ? moment(dummy.from).valueOf() : dummy.startTime,
			endTime: dummy.is_present ? 0 : (dummy.to ? moment(dummy.to).valueOf() : dummy.endTime),
			description: dummy.description ? dummy.description : '',
			media: dummy.media,
			mediaId: dummy.mediaId
		}

		let memberships = clone(this.state.formData.membership)
		if (membership.membershipId) {
			memberships = memberships.map(exp => {
				formFields['membershipId'] = membership.membershipId;
				return (exp.membershipId === membership.membershipId) ? formFields : exp;
			})
		} else {
			membership.membershipId = 'new' + new Date().getTime();
			memberships.push(membership)
		}
		this.setState({ formData: { ...this.state.formData, membership: memberships, editMembership: formFields } })

	}

	handleSubmit = () => {
		let userId: any = window.location.pathname.split('/')[2];
		const { experience, education, membership } = clone(this.state.formData);
		const { formData, loading } = this.state;

		if (loading) return;
		this.setState({ submitted: true });
		let payload: any = {
			name: formData.name,
			title: formData.title,
			education: education.map(edu => {
				if (edu.educationId.indexOf('new') !== -1) delete edu.educationId;
				return edu;
			}),
			experience: experience.map(exp => {
				if (exp.experienceId.indexOf('new') !== -1) delete exp.experienceId;
				return exp;
			}),
			membership: membership.map(mem => {
				if (mem.membershipId.indexOf('new') !== -1) delete mem.membershipId;
				return mem;
			}),
			expertise: formData.expertise,
			company: formData.company,
			role: formData.role,
			linkedinUrl: formData.linkedinUrl,
			industry: formData.defaultIndustries.length > 0 ? formData.defaultIndustries.map(id => id.industryId) : [],
			communityId: formData.communityId,
			email: formData.email,
			affiliatedHow: formData.affiliatedHow,
			image: isEmpty(formData.imageUrl) ? '' : (isEmpty(formData.image) ? undefined : formData.image),
			emailFlag: formData.emailFlag,
			about: formData.about,
			location: formData.location,
			imageUrl: formData.imageUrl
		}
		let validationFlag = validate(payload);
		// console.log("payload", payload);
		this.setState({ errors: validationFlag.errors });

		if (validationFlag.isValid !== true) return;

		this.setState({ loading: true });

		if (!userId) {
			HttpWrapper.callUrl<IUserCreateRequest, IUserCreateResponse>(URLWrapper.urls.platformAdmin.user,
				HttpMethod.POST, payload, VUserCreate)
				.then((response) => {
					toastService.success("User Created ");
					history.push('/user-list');
				}).catch(err => console.log(err))
				.finally(() => {
					this.setState({ loading: false })
				})
		} else {
			payload.userId = userId;
			HttpWrapper.callUrl<IUserUpdateRequest, IUserUpdateResponse>(URLWrapper.urls.platformAdmin.user,
				HttpMethod.PUT, payload, VUserUpdate)
				.then((response) => {
					toastService.success("User Updated ");
					history.push('/user-list');

				}).catch(err => toastService.error(err.message))
				.finally(() => {
					this.setState({ loading: false })
				})
		}
	}

	getAllCommunities(query: string, start = 0, restart = false) {
		this.setState({ comLoading: true, filter: query });
		const { count, communities, total } = this.state,
			startCount = restart ? 0 : start * count;
		HttpWrapper.callUrl<ICommunityRequest, ICommunityResponse>(
			URLWrapper.urls.platformAdmin.community, HttpMethod.GET, {
			count: count,
			start: startCount,
			query: query
		}, VCommunity)
			.then((response) => {
				let communityAll = [];
				if (response.content && response.content.length > 0) {
					communityAll = start === 0 ? response.content : [...communities, ...response.content];
				} else {
					communityAll = communities;
				}
				this.setState({
					comLoading: false,
					communities: communityAll,
					nextStart: response.nextStart,
					total: start === 0 ? response.count : total + response.count,
					paginate: (start === 0 && response.count < 30) ? false : true
				});
			}).catch((error) => {
				console.log(error);
			})
	}

	paginateCommunities = (e: any, cnt) => {
		if (!this.state.nextStart) {
			if (this.state.total <= cnt) {
				this.setState({ paginate: false })
			}
		} else {
			this.setState({ start: this.state.start + 1 })
			this.getAllCommunities(this.state.filter, this.state.start + 1);
		}
	}

	handleExpertiseChange = (selected: any) => {
		const { formData } = this.state;
		if (!selected || selected.length === 0) {
			this.setState(formData => ({ ...formData, expertise: [], defaultExpetises: [] }));
			return;
		}
		let expertisesIds = [], expertisesRaw = [];
		forEachObjIndexed(ex => {
			ex.customOption ? expertisesRaw.push(ex.name) : expertisesIds.push(ex.expertiseId || ex.id);
		}, selected)

		this.setState({
			formData: {
				...formData,
				expertise: {
					expertiseIds: expertisesIds,
					rawExpertise: expertisesRaw
				},
				defaultExpetises: selected
			}
		});
	}

	uploadFile = (name, files: FileList) => {
		// console.log(files[0], "files")
		let imageType = files[0].type.split('/')[1];
		const { formData } = this.state


		if (!contains(imageType, ['jpg', 'jpeg', 'png'])) {
			toastService.error('Invalid file format. File should be in jpg/jpeg or png format');
			return false;
		}
		if (imageType === "jpg" || imageType === "jpeg" || imageType === "png") {
			if (files.length !== 1)
				return;

			let file = files[0];
			let fName = file.name;

			this.setState({ fileLoading: true });
			HttpWrapper.uploadFile(file, 0).then(response => {
				//@ts-ignore
				this.setState({
					formData:
						{ ...formData, image: response.fileId, imageUrl: response.fileUrl },
					loading: false
				});

				// this.setState({ fileName: fName, fileUrl: response.fileUrl, imageId: response.fileId });
				this.handleChange("image", response.fileId);
				this.handleChange("imageUrl", response.fileUrl);
				// console.log("uploaded file", response.fileId, fName, response.fileUrl);
			}).catch(err => {
				// console.log("couldn't upload");

			}).finally(() => {
				this.setState({ fileLoading: false });
			})
			return
		}
		toastService.error('Only jpg/jpeg and png files are allowed!');
		return;
	}

	handleCompanyChange = (selected: any) => {
		const { formData } = this.state;
		if (!selected || selected.length === 0) {
			this.setState({ formData: { ...formData, Community: [], defaultCommunity: [], communityId: '' } });
			return;
		}
		const school = head(selected);

		if (school.customOption) {
			this.setState({
				formData: {
					...formData, defaultCommunity: [school.name],
					Community: school, communityId: '', communityName: school.name
				}
			});
		} else {
			this.setState({ formData: { ...formData, defaultCommunity: [school.name], Community: school, communityId: school.communityId, communityName: school.name } });
		}
	}

	render() {
		const { errors, formData, loading, userId } = this.state;

		return (
			<>
				<div className="content-wrapper">
					<section className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<div className="col-sm-6">
									<h1>User {userId ? 'Edit' : 'Add'}</h1>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<li className="breadcrumb-item"><Link to="/home">Home</Link></li>
										<li className="breadcrumb-item active"> {userId ? 'Edit' : 'Add'} User</li>
									</ol>
								</div>
							</div>
						</div>
					</section>
					<section className="content">
						<div className="row">
							<div className="col-md-12">
								<div className="card card-primary">
									<div className="card-header">
										<h3 className="card-title">{formData.name || 'User'}</h3>
									</div>
									{loading && <Loader />}
									<div>
										<div className="col basicInfoSection mb-3 pb-3 bg-white">
											<div className="d-flex">
												<div className="col-md-12">
													<div className="basicInfoFields p-3">
														<form>
															<div className="form-row">
																<div className="form-group col-md-4">
																	<label htmlFor="useName">Full Name*</label>
																	<input type="text" className="form-control input-lg"
																		id="useName" name="useName" value={formData.name}
																		onChange={(e) => { this.handleChange("name", e.target.value) }}
																	/>
																	{errors.name && <div style={{ color: 'red' }} >
																		{errors.name}
																	</div>}
																</div>
																<div className="form-group col-md-4">
																	<label htmlFor="Titles">Title*</label>
																	<input type="text" className="form-control input-lg"
																		id="Titles" name="title"
																		value={formData.title}
																		onChange={(e) => { this.handleChange("title", e.target.value) }}
																	/>
																	{errors.title && <div style={{ color: 'red' }} >
																		{errors.title}
																	</div>}
																</div>
																<div className="form-group col-md-4">
																	<label htmlFor="Company">Company</label>
																	<input type="text" className="form-control input-lg"
																		id="Company" name="Company"
																		value={formData.company}
																		onChange={(e) => { this.handleChange("company", e.target.value) }}
																	/>
																	{errors.company && <div style={{ color: 'red' }} >
																		{errors.company}
																	</div>}
																</div>
															</div>
															<div className="form-row">
																<div className="form-group col-md-4">
																	<label htmlFor="email">Email Address*</label>
																	<input type="email" className="form-control input-lg"
																		id="Email" name="Email"
																		value={formData.email}
																		onChange={(e) => { this.handleChange("email", e.target.value) }}
																	/>
																	<p style={{ fontSize: '10px', marginTop: 2, marginLeft: '5px' }}>Note: Not Allowed Duplicate Email ID</p>
																	{errors.email && <div style={{ color: 'red' }} >
																		{errors.email}
																	</div>}
																</div>
																<div className="form-group col-md-4">
																	<label htmlFor="Community" >Community*</label>
																	<AsyncTypeahead
																		allowNew
																		newSelectionPrefix="Add a new Community: "
																		isLoading={this.state.comLoading}
																		labelKey="name"
																		selected={formData.defaultCommunity}
																		minLength={2}
																		//paginate={this.state.paginate}
																		maxResults={30}
																		paginationText='Additional results...'
																		onPaginate={(e, cnt) => this.paginateCommunities(e, cnt)}
																		onChange={selected => {
																			this.setState({ formData: { ...formData, defaultCommunity: selected } });
																			this.handleCompanyChange(selected)
																		}}
																		onSearch={query => { this.getAllCommunities(query, 0, true) }}
																		options={this.state.communities}
																		id={'profile_education' + 1}
																		placeholder="Enter Community name"
																		emptyLabel="No Community found"
																	/>

																	{errors.communityId && <div style={{ color: 'red' }} >
																		{errors.communityId}
																	</div>}
																</div>
																<div className="form-group col-md-4 ">
																	<label htmlFor="Industry">Industry*</label>
																	<Typeahead
																		options={this.props.industries.list !== undefined ? [] : this.props.industries}
																		id="Industry"
																		style={{ border: "none" }}
																		selected={formData.defaultIndustries}
																		labelKey={'name'}
																		filterBy={['name']}
																		onChange={selected => {
																			console.log(selected);
																			this.setState({ formData: { ...formData, defaultIndustries: selected } });
																			this.handleChange('defaultIndustries', selected)

																		}}
																		emptyLabel="No Industry Found"
																		multiple={true}
																	/>

																	{errors.industry && <div style={{ color: 'red' }} >
																		{errors.industry}
																	</div>}
																</div>
															</div>
															<div className="form-row">
																<div className="form-group col-md-4 ">
																	<label htmlFor="Affilalted" >Affiliated How</label>
																	<input type="Affilalted" className="form-control" id="Affilalted"
																		value={formData.affiliatedHow}
																		onChange={(e) => { this.handleChange("affiliatedHow", e.target.value) }}
																	/>
																</div>
																<div className="form-group col-md-8">
																	<label htmlFor="Expertise" >Expertise</label>
																	<Typeahead
																		isLoading={this.props.expertises.list === undefined && this.props.expertises.length === 0}
																		options={this.props.expertises.list !== undefined ? [] : this.props.expertises}
																		// allowNew
																		id="Expertise"
																		style={{ border: "none" }}
																		labelKey={'name'}
																		filterBy={['name']}
																		selected={formData.defaultExpetises}
																		onChange={selected => {
																			console.log(selected);
																			this.setState({ formData: { ...formData, defaultExpetises: selected } });
																			this.handleExpertiseChange(selected)
																		}}
																		emptyLabel="No Expertise Found"
																		multiple={true}
																	/>
																</div>

																<div className="form-group col-md-4">
																	<label htmlFor="Location">Location</label>
																	<input type="text" className="form-control input-lg"
																		id="Locations" name="location"
																		value={formData.location}
																		onChange={(e) => { this.handleChange("location", e.target.value) }}
																	/>

																</div>

																{/* <div className="form-group col-md-4">
																	<label htmlFor="Role">Role*</label>
																	<select className="form-control" id="exampleFormControlSelect1"
																		onChange={(e) => { this.handleChange("role", e.target.value) }} value={formData.role}>
																		<option></option>
																		<option value={0}>Member</option>
																		<option value={1}>Moderator</option>
																		<option value={2}>CommunityAdmin</option>
																		<option value={3}>MainCommunityAdmin</option>
																	</select>
																	{errors.role && <div style={{ color: 'red' }} >
																		{errors.role}
																	</div>}
																</div>
																
																<div className="form-group col-md-4">
																	<label htmlFor="linkedprofileiurl">Linked Profile Url*</label>
																	<input type="text" className="form-control input-lg"
																		id="linkedprofileiurl" name="linkedprofileiurl"
																		value={formData.linkedinUrl}
																		onChange={(e) => { this.handleChange("linkedinUrl", e.target.value) }}
																	/>
																	{errors.linkedinUrl && <div style={{ color: 'red' }} >
																		{errors.linkedinUrl}
																	</div>}
																</div> */}
															</div>
															<div className="form-row">
																<div className="form-group col-md-6">
																	<label htmlFor="About">About*</label>
																	<textarea rows={5} className="form-control input-lg"
																		id="About" name="about" value={formData.about}
																		onChange={(e) => { this.handleChange("about", e.target.value) }}
																	/>
																	{errors.about && <div style={{ color: 'red' }} >
																		{errors.about}
																	</div>}
																</div>
															</div>
															<div className="form-row mt-3">
																<div className="form-group col-md-3 d-flex mt-4">
																	<label htmlFor="inputUploadlogo" className='w-25'>Upload Photo</label>
																	<input type="file" accept=".jpg, .jpeg, .png" className="form-control-file  border-0"
																		id="inputUploadlogo2" name="inputUploadlogo2"
																		onChange={(e) => {
																			this.uploadFile('image', e.target.files)
																		}}
																	/>
																</div>
																{!isEmpty(formData.imageUrl) && (formData.imageUrl !== undefined) && <div className="form-group col-md-3 d-flex mt-4">
																	<ProfileImage url={formData.imageUrl} alt={formData.image} defaultClass="profile-user-img img-fluid img-square" imageType="circle" imageSize="xl" />
																	<button type="button" onClick={() => this.removeFile('imageUrl')} className="btn btn-sm bg-white" title="Remove">
																		<i className="fas fa-times"></i></button>
																</div>}
															</div>
															{/* 																
																<div className="form-group col-md-6 d-flex ">
																	<label htmlFor="inputUploadlogo" className='w-50 ml'>Upload Photo
																	<p className="d-flex bg-primary" style={{
																			padding: '8px',
																			borderRadius: '13px',
																			textAlign: 'center'
																		}}>{formData.image.split('-')[1] ? formData.image.split('-')[1] : 'Upload Photo'}  </p>
																	</label>

																	<input type="file" className="form-control-file border-0"
																		id="inputUploadlogo" name="inputUploadlogo"
																		style={{ visibility: "hidden" }}
																		onChange={(e) => {
																			this.uploadFile('image', e.target.files)
																		}}
																	/>
																	<ProfileImage url={formData.imageUrl} alt={formData.image} defaultClass="profile-user-img img-fluid img-square" imageType="circle" imageSize="xl" />
																</div>
															</div> */}
															<div className="mt-3 ">
																<AddandEditExperiance handleExperience={this.handleExperience} experiences={this.state.formData.experience} userID={window.location.pathname.split('/')[2]} />
															</div>
															<div className="mt-3 ">
																<AddandEditEducation handleEduction={this.handleEducation} educations={this.state.formData.education} />
															</div>
															<div className="mt-3 ">
																<AddandEditMembership handleMembership={this.handleMembership} memberships={this.state.formData.membership} />
															</div>
															<div className="form-row mt-3 ">
																<div className="form-group col-md-8 d-flex">
																	<div className="checkbox">
																		<label style={{ display: window.location.pathname.split('/')[2] ? 'none' : 'block' }}>
																			<input type="checkbox" name="optionsRadios" id="optionsRadios2"
																				className="mr-2"
																				onChange={() => this.handleChange("emailFlag", !formData.emailFlag)}
																				checked={formData.emailFlag ? true : false}
																			/>
																			Send The New User An Email Notification About The Account
																		</label>
																	</div>

																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<Link to="/user-list" className="btn btn-secondary">Cancel</Link>
																	<button type="button" className="btn btn-success float-right" onClick={this.handleSubmit}>
																		{window.location.pathname.split('/')[2] ? 'Update' : 'Submit'}
																	</button>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>

					</section>
				</div>
			</>
		)
	}
}

interface IMapState {
	industries: any
	expertises: any,
	allSpecialities: any,
	allInterests: any,
	allClassification: any
}

function mapState(state): IMapState {
	const { global: { industries, expertises, allSpecialities, allInterests, allClassification } } = state;
	return { industries, expertises, allSpecialities, allInterests, allClassification };
}



const connectedAddUser = withRouter(connect(mapState)(AddUser));
export { connectedAddUser as AddUser };

