import { IValidatorType } from "../validator-interface";
import { ValidationUtility } from "../validation-utility";

//Req [GET] /comunities/all
export interface ICommunityRequest {
	filter?: string,
	start: number,
	count: number
}

//Val [GET] /comunities/all
export const VCommunity: IValidatorType<ICommunityRequest> = {
	filter: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false },
	start: { function: ValidationUtility.validateNumber, params: [] },
	count: { function: ValidationUtility.validateNumber, params: [] }
}


//Res [GET] /comunities/all
export interface ICommunityResponse {
	start: number,
	count: number,
	nextStart?: boolean,
	content: {
		communityId: string,
		name: string,
		image?: string
	}[]
}