import React, { Component } from 'react';
import { Route } from 'react-router-dom';

const CommmonLayout = ({ children, ...rest }) => {
	return (
		<div className="container">
			{children}
		</div>
	)
}

const PublicLayout = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<CommmonLayout>
				<Component {...matchProps} />
			</CommmonLayout>
		)} />
	)
};

export { PublicLayout };