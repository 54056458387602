import { validate, isGreaterThan, isNumber, notEmpty, passwordRules, isEqual, isEmail } from './rules'
//import { isNumber } from 'util';

export function validateLoginForm(values) {
	const errors = validate({
		email: [
			values.email,
			[
				[notEmpty, 'Email is required'],
				[isEmail, 'Email is invalid']
			],
		],
		password: [
			values.password,
			[
				[notEmpty, 'Password is required']
			],
		]
	})
	return errors;
}

export function validateForgotPasswordForm(values) {
	const errors = validate({
		email: [
			values.email,
			[
				[notEmpty, 'Email is required'],
				[isEmail, 'Email is invalid']
			],
		]
	})

	return errors;
}

export function validateSignup1(values) {
	const errors = validate({
		email: [
			values.email,
			[
				[notEmpty, 'Email is required'],
				[isEmail, 'Email is invalid']
			]
		],
		password: [
			values.password,
			[
				[notEmpty, 'Password is required'],
				[passwordRules, "Your password should contain: 1. at least 1 uppercase. [A-Z] 2. at least 1 lowercase. [a-z] 3. at least 1 special character. [!@#$%^&*()-_=+{};:,<.>] 4. at least 1 number. [0-9] 5. has the length of minimum 8 characters"],
				[isGreaterThan(7), 'Minimum length of 8 is required']
			]
		],
		confirmPassword: [
			values.confirmPassword,
			[
				[notEmpty, 'Confirm password is required'],
				[isEqual(values.password), 'Password mismatch']
			],
		]
	})

	return errors;
}

export function validateResetPasswordForm(values) {
	const errors = validate({
		password: [
			values.password,
			[
				[notEmpty, 'Password is required'],
				[passwordRules, "Your password should contain: 1. at least 1 uppercase. [A-Z] 2. at least 1 lowercase. [a-z] 3. at least 1 special character. [!@#$%^&*()-_=+{};:,<.>] 4. at least 1 number. [0-9] 5. has the length of minimum 8 characters"],
				[isGreaterThan(8), 'Minimum length of 8 is required']
			],
		],
		confirmPassword: [
			values.confirmPassword,
			[
				[notEmpty, 'Confirm password is required'],
				[isEqual(values.password), 'Password mismatch']
			],
		]
	})

	return errors;
}

export function validateSignup2(values) {
	const errors = validate({
		companyName: [
			values.companyName,
			[
				[notEmpty, 'Organization name is required']
			]
		],
		communityClassification: [
			values.communityClassification,
			[
				[notEmpty, 'Community classification is required']
			]
		],
		communitySize: [
			values.communitySize,
			[
				[notEmpty, 'Community size is required'],
				[isNumber, 'Invalid community size']
			]
		],
		companyAbout: [
			values.companyAbout,
			[
				[notEmpty, 'Description about organization is required']
			]
		],
		contactName: [
			values.contactName,
			[
				[notEmpty, 'Person name is required']
			]
		],
		/*contactEmail: [
			values.contactEmail,
			[
				[notEmpty, 'Email is required'],
				[isEmail, 'Email is invalid']
			]
		]*/
	})

	return errors;
}