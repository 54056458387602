export enum IndustriesActionEnum {
	GET_INDUSTRIES = 'GET_INDUSTRIES'
}

export enum ExpertisesActionEnum {
	GET_EXPERTISES_ALL = 'GET_EXPERTISES_ALL'
}

export enum ClassificationActionEnum {
	GET_CLASSIFICATION_ALL = 'GET_CLASSIFICATION_ALL'
}

export enum SpecialitiesActionEnum {
	GET_SPECIALITIES_ALL = 'GET_SPECIALITIES_ALL'
}

export enum InterestsActionEnum {
	GET_INTERESTS = 'GET_INTERESTS'
}

export enum AffilatedTagsActionEnum {
	GET_AFFILATED_TAGS_ALL = 'GET_AFFILATED_TAGS_ALL'
}

export enum FeatureActionEnum {
	GET_FEATURE_ALL = 'GET_FEATURE_ALL'
}