import React from "react";
import { PasswordForm } from "./PasswordForm";

class OtherActionsButton extends React.Component<
  { userId: string },
  {
    otherOptionsVisible: boolean;
    loading: boolean;
    openModal: boolean;
    fetchingURL: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      otherOptionsVisible: false,
      loading: false,
      openModal: false,
      fetchingURL: false,
    };
  }

  toggleModal(isOpen: boolean) {
    this.setState({ openModal: isOpen });
  }

  toggleOtherOptions = () => {
    this.setState((prev) => ({
      otherOptionsVisible: !prev.otherOptionsVisible,
    }));
  };

  render() {
    return (
      <>
        <td className='custom-action' onClick={() => this.toggleOtherOptions()}>
          &#8286;
		  {this.state.otherOptionsVisible && (
            <ul className='custom-unordered'>
              <li
                className='custom-unordered-item btn w-100'
                onClick={() => this.toggleModal(true)}
              >
				Change Password
              </li>
            </ul>
          )}
          <PasswordForm
            userId={this.props.userId}
            loading={this.state.loading}
            isOpen={this.state.openModal}
            onClose={() => {
				// this.toggleOtherOptions();
				this.toggleModal(false)
			}}
          />
        </td>
      </>
    );
  }
}

export { OtherActionsButton };
