import React from 'react';
import { NavLink } from 'react-router-dom';
import { history } from '../../../helpers';
import { contains } from 'ramda';

interface IProps {
	to: any,
	clsname: string,
	isActive?: any,
	activehref?: any
}

class NavCustomLink extends React.Component<IProps, any> {

	constructor(props) {
		super(props);
		this.state = {
			currentMenu: ''
		};
	}

	componentDidMount() {
		this.setState({ currentMenu: this.props.to });

		history.listen((location, action) => {
			this.handleActiveMenu(history.location.pathname);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { to } = this.props;
		if (this.state.currentMenu !== to) {
			this.setState({ currentMenu: this.props.to }, () => this.handleActiveMenu(to));
		}
	}

	handleActiveMenu = (to) => {
		this.setState({ currentMenu: to });
		const demoClasses = document.querySelectorAll(".leftMenu");
		demoClasses.forEach(element => {
			if (element.getAttribute('href') === history.location.pathname || contains(element.getAttribute('activehref'), history.location.pathname)) {
				element.classList.add('active');
			} else {
				element.classList.remove('active');
			}
		});
	}

	render() {
		const { currentMenu } = this.state;
		const className = history.location.pathname === currentMenu ? '  leftMenu' : ' leftMenu';
		// history.listen((location, action) => {
		// 	this.handleActiveMenu('history.location.pathname');
		// });

		return (
			<NavLink className={this.props.clsname + className} {...this.props}>
				{this.props.children}
			</NavLink>
		);
	}
}

export default NavCustomLink;