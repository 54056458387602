import { IValidatorInterface, IValidatorType } from "../validator-interface";
import { ValidationUtility } from "../validation-utility";
import { ResponseMessages } from "../response-messages";

//Req [POST] /email/verification/resend
export interface IResendEmailVerificationRequest {
	email: string,
	communityId?: string
}

//Val [POST] /email/verification/resend
export const VResendEmailVerification: IValidatorType<IResendEmailVerificationRequest> = {
	email: { function: ValidationUtility.validateEmail, params: [], required: true, customMessage: ResponseMessages.errorEmail },
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: false }
}

//Res [POST] /email/verification/resend
export interface IResendEmailVerficationResponse {
	info: any
}

//Req [POST] /password/forgot
export interface IForgotPasswordRequest {
	email: string,
	communityId?: string
}

//Val [POST] /password/forgot
export const VForgotPassword: IValidatorInterface = {
	email: { function: ValidationUtility.validateEmail, params: [] },
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: false }
}

//Res [POST] /password/forgot
export interface IForgotPasswordResponse { }

//Req [POST] /password/reset
//passwordToken (HEADER)
export interface IResetPasswordRequest {
	email: string,
	password: string,
}

//Val [POST] /password/reset
export const VResetPassword: IValidatorInterface = {
	email: { function: ValidationUtility.validateEmail, params: [] },
	password: { function: ValidationUtility.validatePassword, params: [] },
}

//Res [POST] /password/reset
export interface IResetPasswordResponse { }

//Req [POST] /logout
export interface ILogoutRequest {
	firebaseToken: string,
}

//Val [POST] /logout
export const VLogout: IValidatorInterface = {
	firebaseToken: { function: ValidationUtility.validateStringFields, params: [1, -1] },
}

//Res [POST] /logout
export interface ILogoutResponse { }