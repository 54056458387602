import { IInterestsReduxState, interestsInitialReduxState, IInterestsReduxAction } from './types/interestsInterface';
import { IndustriesActionEnum, ExpertisesActionEnum, ClassificationActionEnum, SpecialitiesActionEnum, InterestsActionEnum, AffilatedTagsActionEnum, FeatureActionEnum } from '../constants/enums/globalActionEnum';
import { IGlobalCombinedReduxState, globalInitialReduxState, IGlobalReduxAction } from './types/globalInterface';

export function global(state: IGlobalCombinedReduxState = globalInitialReduxState, action: IGlobalReduxAction): IGlobalCombinedReduxState {
	switch (action.type) {
		case IndustriesActionEnum.GET_INDUSTRIES:
			return {
				...state, industries: action.list
			}
		case ExpertisesActionEnum.GET_EXPERTISES_ALL:
			return {
				...state, expertises: action.list
			}
		case ClassificationActionEnum.GET_CLASSIFICATION_ALL:
			return {
				...state, allClassification: action.list
			}
		case SpecialitiesActionEnum.GET_SPECIALITIES_ALL:
			return {
				...state, allSpecialities: action.list
			}
		case InterestsActionEnum.GET_INTERESTS:
			return {
				...state, allInterests: action.list
			}
		case AffilatedTagsActionEnum.GET_AFFILATED_TAGS_ALL:
			return {
				...state, AllAffilatedtags: action.list
			}
		case FeatureActionEnum.GET_FEATURE_ALL:
			return {
				...state, AllFeature: action.list
			}
		default:
			return state
	}
}