import { ModelStatus } from "../networked-shared-interfaces/constants/model-status";

let communityStatusEnums = [];
communityStatusEnums[ModelStatus.Waiting] = 'Waiting';
communityStatusEnums[ModelStatus.Accepted] = 'Accepted';
communityStatusEnums[ModelStatus.Blocked] = 'Blocked';
communityStatusEnums[ModelStatus.Deleted] = 'Deleted';
communityStatusEnums[ModelStatus.BlockedDeleted] = 'BlockedDeleted';
// communityStatusEnums[ModelStatus.ProfileNotSet] = 'ProfileNotSet';
// communityStatusEnums[ModelStatus.Restricted] = 'Restricted';
// communityStatusEnums[ModelStatus.WaitingEmailVerification] = 'WaitingEmailVerification';

let userStatusEnums = [];
userStatusEnums[ModelStatus.Waiting] = 'Waiting';
userStatusEnums[ModelStatus.Accepted] = 'Accepted';
userStatusEnums[ModelStatus.Blocked] = 'Blocked';
userStatusEnums[ModelStatus.Deleted] = 'Deleted';
userStatusEnums[ModelStatus.BlockedDeleted] = 'BlockedDeleted';
userStatusEnums[ModelStatus.ProfileNotSet] = 'ProfileNotSet';
userStatusEnums[ModelStatus.Restricted] = 'Restricted';
userStatusEnums[ModelStatus.WaitingEmailVerification] = 'WaitingEmailVerification';

export { communityStatusEnums, userStatusEnums }