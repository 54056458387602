export const ResponseMessages = {
	errorUserAlreadyDeleted: "The user is already deleted",
	errorUserDeleted: "Profile is deleted. Please contact admin",
	errorLinkExpired: "The link has expired",
	errorNotAuthorized: "Not authorized", // Your are not authorized
	errorInput: "Please check your Inputs",
	errorEmail: "Your Email is incorrect", //Please enter your email in the format yourname@example.com
	errorEmailDontExist: "Email dont exist",
	errorUserNonMember: "User is not a member of the selected community",
	errorName: "Name cant be empty",
	errorUserHandle: "Invalid User Handle",
	errorName50: "Name needs to be atleast 50",
	errorPassword: "Password didn't match", //
	errorPasswordMismatch: "Password didn't match", //Passwords are not matching. Please recheck and continue.
	errorPasswordEnterValid: "Please enter valid password",
	errorCurrentPasswordMisatch: "Current password didn't match",
	errorSamePassword: "Same password cannot be used to change password",
	errorPasswordInvalid: "Please enter a valid password", //[passwordRules, "Your password should contain:</br> 1. at least 1 uppercase. [A-Z]</br> 2. at least 1 lowercase. [a-z]</br> 3. at least 1 special character. [!@#$%^&*()-_=+{};:,<.>] </br>4. at least 1 number. [0-9] </br> 5. has the length of minimum 8 characters"],
	// [isGreaterThan(7), 'Minimum length of 8 is required'] //make sure the points are in next line arranged in asceding order
	errorCommunityId: "Invalid Community ID",
	errorAllowAvailableCommunities: "onlyAvailableToPost should be boolean value",
	errorConnectionRequestId: "Invalid ConnectionId",
	errorRequestId: "Invalid requestedId",
	errorConnectionId: "Invalid ConnectionId",
	errorAccessToken: "Invalid Access Token",
	errorUserId: "Invalid User ID",
	errorPostId: "Invalid Post ID",
	errorPostTimeStamp: "Invalid timeStamp",
	errorCommunityToken: "Invalid Community Token",
	errorInviteToken: "Invalid Invite Token",
	errorUnexpected: "Some Unexpected Error",
	errorForgotPassword: "Verify email before resetting password",
	errorCommunityTag: "Affiliated Tag is missing",
	errorModelStatus: "Please verify your account. Check you email.",
	errorFile404: "File not attached",
	errorHiddenCommunity: "You cant request to join a hidden Community",
	errorUserAlreadyPartOfCommunity: " User is already part of Community", //Member is already part of the community 
	errorCommunityNotAccepted: "Your Community is still not Accepted!", //Your community approval is pending
	errorDataNotFound: "No data found",
	errorUserAlreadyExist: "Email is already being used", //Email already exists
	errorUserExists: "The email belongs to an existing user. Please login to continue.", //Email already exist, external link
	errorAffiliatedTag: "Please specify the Affiliated Tag",
	errorLoginNewUser: "The email ID is not existing in platform. Please request to join.",
	erroVerifyMail: "The email ID already verified.",
	errorSlugAlreadyExists: "Slug is already being used", //Slug already exists
	errorTokenId: "Invalid Token id",

	errorTextField: "Text field is required for PostType Post",
	errorImageField: "Images field is required for PostType Photo",
	errorFileField: "File field is required for PostType File",
	errorVideoLinkField: "Video link field is required for PostType Video",
	errorUrlField: "Url field is required for PostType Article",
	errorPostType: "Invalid PostType, 1-Article, 2-Post, 3-Photo, 4-Video",

	errorMessageGroup: "Group already exist for users",
	errorUserExist: "User already exist",
	errorP2P: "This conversation is p2p",
	errorCalendlyUrl: "Invalid calendly url",

	errorEventId: "Invalid event ID",
	errorEventTitle: "Invalid event title",
	errorEventDescription: "Invalid event description",
	errorEventStartTime: "Validation field at startTime",
	errorEventEndTime: "Validation field at endTime",
	errorEventReoccuringTime: "Validation field at reoccurringTime",
	errorJodId: "Invalid job ID",
	errorJobOwner: "Job creator can not apply",
	errorJobAlreadyApplied: "User has already applied for job",

	errorPostDeleted: "Post is deleted",
	errorPostText: "Invalid post text",
	errorPostUrl: "Invalid post URL",
	errorPostLocation: "Invalid post location",
	errorPostDelete: " User have not liked the post",
	errorNoRegisteredMobile: "No Registered Device",
	errorOpportunityType: "Invalid opportunity type",
	errorAnswerNotLiked: "Answer is not liked by user",
	errorUnfollowQuestion: "User don't follow the question",
	errorUnfollowQuestionAnswer: "User don't follow the answer",
	errorfollowQuestionAlready: "User already follow question",
	errorfollowQuestioAnswerAlready: "User already follow answer",
	errorCommentLiked: "Comment is already liked by user",
	errorCommentNotLiked: "Comment is not liked by user",
	errorProfilePrivate: "Profile is private/hidden",
	errorComuunityHidden: "Community is hidden",
	errorUserSetting: "User settings error",
	errorAlreadySentRequest: "The User is already requested to join the comunnity",
	errorPasswordUsedEarlier: "Last three used password can not be set again",
	errorUserInvite: "User invite sent",
	errorMorderatorExist: "User already a morderator",
	errorMediaCenterId: "Invalid Media Center ID",
	errorMediaCenterParentId: "Invalid Parent Folder",
	errorNoMediaFound: "No media found",
	errorValShareMCfileTitle: "Validation field at caption",

	errorChildCommunityParent: "You dont need to mention the parent community here",
	errorUserLimit: "User Limit Exceeded",

	errorCantRemoveTag: "Remove users affiliated to tags before removing from community",
	errorNoneditableAnswer: "Changing answer is restricted",
	errorUnableFetchMeta: 'unable to Fetch meta from URL',
	successCommentAlreadyLikes: "User has already like the comment",
	successPostCommentLikes: "Comment like added",

	success: "Success",
	successEmailVerified: "Email verified",  //Email verification successfull
	successPasswordReset: "Password is changed", //Your password has been changed successfully
	succesEmailVerificationMail: "Email verification mail sent.",

	successAnswerLikedAlready: "User has already like the answer",
	successMorderatorPostDelete: "Post deleted by morderator",
	successMorderatorPostUnDelete: "Post undeleted by morderator",
	successMorderatorServiceDelete: "Service deleted by morderator",
	successMorderatorJobDelete: "Job deleted by morderator",
	successMorderatorEventDelete: "Event deleted by morderator",
	successMorderatorFundraisingDelete: "Fundsraising deleted by morderator",
	successMorderatorQuestionDelete: "Question deleted by morderator",
	successMorderatorUserPrivilegesRevoked: "All user privileges revoked",
	successMorderatorUserInviteSent: "User invite sent",
	successMorderatorPostDecline: "Post declined by morderator",

	communityClassificationUpdate: "Community classification updated",

	customRequestSent: "Your Request has been sent",
	customJoinedPublicCommunity:
		"Your Successfullt joined the public community",

	successUserRegistration1: "User Successfully registerd",

	successPostCreate: "Post created",
	successPostCommentCreate: "Post comment created",
	successPostLike: "Post liked",
	successPostAlreadyLiked: "Post is already liked by current User",
	successPostShared: "Post shared",
	successPostLikeDelete: "Post like deleted",
	successPostDelete: "Post deleted",
	successPostCommentDelete: "Post comment deleted",
	successPostReportCreated: "Post report created",
	successPostAlreadyReported: "Post already reported",
	successPostCommentReportCreated: "Comment report created",
	successCommentAlreadyReported: "Comment already reported",
	successCommunityCreated: "Community created",

	successUserHide: "User is hidden",
	successUserUnHide: "User is unhide",
	successUserHiddenAlready: "User is already hidden",

	successEventDelete: "Event deleted",
	successFundingDelete: "Funding deleted",
	successJobDelete: "Job deleted",
	successEmailSent: "Email sent successfully",

	updateCommunityStatusMessage: "Community Status Update",

	successQuestionDeleted: "Question deleted",
	SuccessQuestionFollow: "Question followed",
	SuccessQuestionUnfollow: "Question unfollowed",
	SuccessQuestionAnswerFollow: "Question followed",
	SuccessQuestionAnswerUnFollow: "Question unfollowed",
	successAnswerDelete: "Answer deleted",
	successCommentDelete: "Comment deleted",
	successCommentUpdate: "Comment updated",

	successServiceCreated: "Service created",
	successServiceDelete: "Service deleted",

	successFundRaisingUpdate: "Fundraising updated successfully",
	successFundRaisingDeleted: "Fundraising deleted successfully",

	errorMessageField: "Any one, message or media field is required",

	//-------------------------------------------------------------------------------
	//Platform Admin error messages

	//error messages
	notPlatformAdmin: "User is not platform admin",

	uploadErrorName: "Name field is required, mininum 3 characters",
	uploadErrorImageID: "Image ID is not valid",
	uploadErrorIndustryID: "Feature ID is not valid",


	communityUploadErrorSize: "Size field is required, should be number",
	communityUploadErrorLayer: "Layer field is required, should be number",
	communityUploadErrorClassification: "Classification field is required, should be comma separated classification IDs",
	communityUploadErrorClassificationID: "Classification ID is not valid",
	communityUploadErrorSpeciality: "Specialities field is required, should be comma separated speciality IDs",
	communityUploadErrorSpecialityID: "Speciality ID is not valid",
	communityUploadErrorFeature: "Feature field is required, should be comma separated Feature IDs",
	communityUploadErrorFeatureID: "Feature ID is not valid",
	communityUploadErrorTag: "Feature field is required, should be comma separated Feature IDs",
	communityUploadErrorTagID: "Feature ID is not valid",
	communityUploadErrorFeatureImageID: "Banner Image ID is not valid",

	userUploadErrorEmail: "Email field is required",
	userUploadErrorIsAdmin: "isAdmin field is requried, should be boolean value",
	userUploadErrorIsAdminFlag: "isAdmin flag should be boolean, 0/1",
	userUploadErrorCommunity: "Communities field is required, should be comma separated Community IDs",
	userUploadErrorCommunityID: "Community ID is not valid",
	userUploadErrorOnboarBoardCommunity: "Onboard community ID is not valid",
	userUploadErrorPrimaryCompanyID: "Primary company ID is not valid",
	userUploadErrorResumeID: "Resume file ID is not valid",
	userUploadErrorExpertise: "Expertises field is required, should be comma separated Expertise IDs",
	userUploadErrorExpertiseID: "Expertise ID is not valid",
	userUploadErrorTag: "Affiliation tag field is required",
	userUploadErrorTagID: "Affiliation tag ID is not valid",

	uploadErrorTitle: "Title field is required",
	uploadErrorCompany: "Company field is required",
	uploadErrorCommunityName: "Community name field is required",
	uploadErrorCommunityID: "Community name field is required",
	uploadErrorLocation: "Location field is required",
	uploadErrorStartTime: "StartTime field is required",
	uploadErrorEndTime: "EndTime field is required",
	uploadErrorMedia: "Media field is required",
	uploadErrorEmailNotFound: "Email is not present in DB",

	uploadErrorDegree: "Degree field is required",
	uploadErrorFieldOfStudy: "Field of study field is required",

	uploadErrorExpertise: "Expertise field is required, should be minimum 3 chars",
	uploadErrorIndustry: "Industry field is required, should be minimum 3 chars",
	//success messages
	successUpload: "Data upload successfully",

	errorIndustryId: "Invalid industry ID",

	//-------------------------------------------------------------------------------
	colourCommunitySet: "Colour set successfully",

	adminApproval: "Accepted Successfully",
	adminRejection: "Accepted Rejection",
	bodyValidationError: "body should Not be empty",
	errorUserAlreadyNominate: "User is already nominated",
	errorAnalyticsScreen: "Screen is not found",
	faqSupportResponse: "We would reply you soon",
	communityDeclineRequest: "You have successfully reject the community request",

	//---------------------------------------------------------------------------
	//Plugin responses

	errorCommunityAlreadyAdded: "Plugin is already added.",
	errorCommunityPluginNotAdded: "Plugin is not added.",
	errorInvalidPluginId: "Invalid plugin id.",

	//------------------------------------------------------------------------------

	userAdded: "User added",
	userRemoved: "User removed",
	groupLogoChanged: "Group logo changed",
	groupNameChanged: "Group name changed",
	groupNameLogoChanged: "Group name and logo changed",
	groupCategoryExists: "Category already exists",
	groupCategoryNotcannected: "Category is not connected to mattermost",
	userAlreadyAdmin: "User is already admin",
	userAlreadyMember: "User is already a member",
	invalidGroupId: "Invalid group id",
	//-------------------------------------------------------------------------------

	errorPollId: "Invalid Poll ID",
	errorPollPutRequest: "any one field is required for edit",
	errorPollAlreadySubmit: "User has already submitted for this poll",
	errorPollSubmitAfterEnd: "Poll time has ended",
	errorEndPollDownload: "Poll has to end for download",

	errorSurveyId: "Invalid Survey ID",
	errorSurveyPutRequest: "any one field is required for edit",
	errorSurveyAlreadySubmit: "User has already submitted for this survey",
	errorSurveyQuestionLimitExceed: "Maximum limit of questions reached, 10",
	errorSurveyNotCreator: "User should be the creator of survey to end",
	errorSurveySubmitAfterEnd: "Survey time has ended",
	errorSurveyAlreadyEnded: "Survey already ended",
	errorSurveyPollDownload: "Survey has to end for download",

	//-----------------------------------------------------------------------------------

	errorFileExtention: "Unsupported file extention",

	//-----------------------------------------------------------------------------------

	errorEventRoleNotFound: "User role is required",
	errorEventClosed: "This ApiType is closed",

	//-----------------------------------------------------------------------------------
	successInvitationLinkCreate: "Invitation Link Created Successfully",
	successInvitationLinkUpdate: "Invitation Link updated Successfully",

	errorInvitationIdNotFound: "Invitation Link is Not Found",
	errorInvitationToken: "Invalid Invitation Token",
	errorInvitationRole: "User Role is not available",
	errorInvitationFirebaseShortLink: "Firebase Short Link Creation Error",
	errorInvitationUpdateUserLimit: "User limit has already been extended",

	//----------------------------------------------------------------------------------
	internalServerError: "Internal Server Error"
};
