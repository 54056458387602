import React from 'react';
import { Link } from 'react-router-dom';
import { contains, pluck, whereEq, innerJoin, isEmpty } from 'ramda';
import { validate } from './AddValidate';
import { Typeahead } from 'react-bootstrap-typeahead';
import { HttpMethod, HttpWrapper } from '../../../helpers/httpWrapper';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import { toastService } from '../../../services/toast.service';
import { ICommunityCreateRequest, ICommunityCreateResponse, ICommunityRequest, ICommunityResponse, ICommunityUpdateRequest, ICommunityUpdateResponse, VCommunityCreate } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/community';
import { VCommunity } from '../../../networked-shared-interfaces/global-routes/community-routes';
import { CommunityVisiblity } from '../../../networked-shared-interfaces/constants/community-visiblity';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ProfileImage from '../../../helpers/profileImage';
import { Loader } from '../common/Loader';
import './add.css';
import { ModelStatus } from '../../../networked-shared-interfaces/constants/model-status';

interface IState {
	communityId: string,
	formData: {
		name: string,
		features: any[],
		classifications: any[],
		size: any,
		affiliatedTags: any,
		about: string,
		address: string,
		visibility: any,
		specialities: any[],
		industries?: any,
		yearFounded?: any,
		websiteLink?: any,
		adminName?: string,
		adminEmail: string,
		imageId?: any,
		imageUrl?: string,
		featureImageId?: any,
		featureImageUrl?: string,
		emailFlag?: boolean
		status: ModelStatus,
		userProfileWriteAccess: boolean,
		hideCreate?: boolean,
		hideExplore?: boolean
		hideHelp?: boolean
	},
	formErrors: any,
	loading: boolean,
	resError: boolean,
	submitted: boolean,
	section: string,
	errors: any,
	fileLoading: boolean,
	fileName: string,
	fileUrl: string,
	featureImageFilename: string,
	featureImageFileUrl: string,
	featureImageId: string,
	status: any
}

class Add extends React.Component<Partial<IMapState> & any, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			communityId: window.location.pathname.split('/')[2],
			formData: {
				name: '',
				features: [],
				classifications: [],
				size: 0,
				affiliatedTags: [],
				about: '',
				address: '',
				visibility: null,
				specialities: [],
				industries: [],
				yearFounded: '',
				websiteLink: '',
				adminName: '',
				adminEmail: '',
				imageId: '',
				imageUrl: '',
				featureImageId: '',
				featureImageUrl: '',
				emailFlag: false,
				status: ModelStatus.Waiting,
				userProfileWriteAccess: false,
				hideCreate: false,
				hideExplore: false,
				hideHelp : false
			},
			fileLoading: false,
			fileName: '',
			fileUrl: '',
			featureImageFilename: '',
			featureImageFileUrl: '',
			featureImageId: '',
			formErrors: '',
			loading: false,
			resError: false,
			submitted: false,
			section: '',
			errors: {},
			status: props.location && props.location.community ? props.location.community && props.location.community.status : undefined
		}
	}

	componentDidMount() {
		this.getCommunityInfo();
	}

	componentDidUpdate(prevProps, prevState) {
		const communityId: any = window.location.pathname.split('/')[2];

		if (communityId !== this.state.communityId) {
			this.setState({ communityId: communityId, errors: [], submitted: false });
			if (communityId === undefined) {
				this.initFormData({
					name: '',
					features: [],
					classifications: [],
					size: 0,
					affiliatedTags: [],
					about: '',
					address: '',
					visibility: null,
					specialities: [],
					industries: [],
					websiteLink: '',
					adminName: '',
					yearFounded: '',
					adminEmail: '',
					imageId: '',
					imageUrl: '',
					featureImageId: '',
					featureImageUrl: '',
					emailFlag: false,
					status: undefined,
					userProfileWriteAccess: false,
					hideCreate: false,
					hideExplore: false
				});
			} else {
				this.getCommunityInfo();
			}
		}

		if (communityId !== undefined) {
			let formData = [];

			const SearchClassifications = whereEq(this.props.allClassification);
			if (!SearchClassifications(prevProps.allClassification)) {
				formData['classifications'] = innerJoin(
					(indOpt, classification) => indOpt.classificationId === classification.classificationId, this.props.allClassification,
					this.state.formData.classifications) || [];
			}

			const searchSpecialities = whereEq(this.props.allSpecialities);
			if (!searchSpecialities(prevProps.allClassification)) {
				formData['specialities'] = innerJoin(
					(indOpt, specialities) => indOpt.id === specialities.expertiseId, this.props.allSpecialities,
					this.state.formData.specialities) || [];
			}

			const searchIndustries = whereEq(this.props.industries);
			if (!searchIndustries(prevProps.allClassification)) {
				formData['industries'] = innerJoin(
					(indOpt, industry) => indOpt.industryId === industry.industryId, this.props.industries,
					this.state.formData.industries) || [];
			}

			const searchcAffilatedTags = whereEq(this.props.AllAffilatedtags);
			if (!searchcAffilatedTags(prevProps.allClassification)) {
				formData['affiliatedTags'] = innerJoin(
					(affOpt, tags) => affOpt.tagId === tags.tagId, this.props.AllAffilatedtags,
					this.state.formData.affiliatedTags) || [];
			}

			const searchFeatures = whereEq(this.props.AllFeature);
			if (!searchFeatures(prevProps.allClassification)) {
				formData['features'] = innerJoin(
					(featureOpt, feature) => featureOpt.featureId === feature.featureId, this.props.AllFeature,
					this.state.formData.features) || [];
			}

			if (formData.length > 0) {
				this.setState(
					{ formData: { ...this.state.formData, ...formData } },
					() => this.setState({ errors: validate(this.state.formData) })
				);
			}
		}
	}

	getCommunityInfo = () => {
		let communityId: any = window.location.pathname.split('/')[2];
		if (communityId) {
			this.setState({ loading: true });
			HttpWrapper.callUrl<ICommunityRequest, ICommunityResponse>(URLWrapper.urls.platformAdmin.community,
				HttpMethod.GET, {
				start: 0,
				count: 1,
				communityId
			}, VCommunity)
				.then((response: any) => {
					this.setState({ loading: false });
					this.initFormData(response.content[0]);
				}).catch((error) => {
					this.setState({ loading: false });
				})
		}
	}

	initFormData = (data) => {
		this.setState({
			//@ts-ignore
			formData: {
				name: data.name || '',
				size: data.size || 0,
				about: data.about || '',
				address: data.location || '',
				visibility: data.visibility,
				websiteLink: data.websiteLink || '',
				adminName: data.adminUser ? data.adminUser.name : '',
				adminEmail: data.adminUser ? data.adminUser.email : '',
				imageId: '',
				imageUrl: data.imageUrl || '',
				featureImageId: '',
				yearFounded: data.yearFounded || '',
				featureImageUrl: data.featureImageUrl || '',
				emailFlag: data.emailFlag || false,
				classifications: data.classifications.length > 0 ? innerJoin(
					(indOpt, classification) => indOpt.classificationId === classification.classificationId, this.props.allClassification,
					data.classifications) : [],
				specialities: data.specialities.length > 0 ? innerJoin(
					(indOpt, specialities) => indOpt.id === specialities.expertiseId, this.props.allSpecialities,
					data.specialities) : [],
				industries: data.industries.length > 0 ? innerJoin(
					(indOpt, industry) => indOpt.industryId === industry.industryId, this.props.industries,
					data.industries) : [],
				affiliatedTags: data.affiliatedTags.length > 0 ? innerJoin(
					(affOpt, tags) => affOpt.tagId === tags.tagId, this.props.AllAffilatedtags,
					data.affiliatedTags) : [],
				features: data.features.length > 0 ? innerJoin(
					(featureOpt, feature) => featureOpt.featureId === feature.featureId, this.props.AllFeature,
					data.features) : [],
				status: data.status,
				userProfileWriteAccess: data?.userProfileWriteAccess,
				hideCreate: data?.hideCreate,
				hideExplore: data?.hideExplore,
				hideHelp : data?.hideHelp 
			}
		})
	}

	handleChange = (name: any, value: any) => {
		this.setState((prev: any) => {
			prev.formData[name] = value;
			return prev;
		}, () => {
			if (this.state.submitted)
				this.setState({ errors: validate(this.state.formData).errors })
		})
	}

	removeFile = (field) => {
		this.setState((prev: any) => {
			prev.formData[field] = '';
			return prev;
		})
	}

	uploadFile = (name, files: FileList) => {
		let imageType = files[0].type.split('/')[1];

		if (!contains(imageType, ['jpg', 'jpeg', 'png'])) {
			toastService.error('Invalid file format. File should be in jpg/jpeg or png format');
			return false;
		}

		if (files.length !== 1) return;

		let file = files[0],
			fName = file.name;

		this.setState({ fileLoading: true });

		HttpWrapper.uploadFile(file, 0).then((response: any) => {
			if (name === 'imageId') {
				this.setState({ fileName: fName });
				this.handleChange("imageId", response.fileId);
				this.handleChange("imageUrl", response.fileUrl);
			} else {
				this.setState({ featureImageFilename: fName })
				this.handleChange("featureImageId", response.fileId);
				this.handleChange("featureImageUrl", response.fileUrl);
			}
		}).catch(err => {

		}).finally(() => {
			this.setState({ fileLoading: false })
		})
	}

	onKeyPress(e) {
		var code = (e.which) ? e.which : e.keyCode;
		if (code > 31 && (code < 48 || code > 57)) {
			e.preventDefault();
		}
	}

	handleSubmit = (e) => {
		let communityId: any = window.location.pathname.split('/')[2];
		const { formData,
			formData: { classifications, features, affiliatedTags, industries, specialities }
		} = this.state;

		let payload: any = {
			name: formData.name,
			classifications: classifications.length > 0 ? pluck(['classificationId'], classifications) : [],
			features: features.length > 0 ? pluck(['featureId'], features) : [],
			size: formData.size,
			affilatedTags: affiliatedTags.length > 0 ? pluck(['tagId'], affiliatedTags) : [],
			about: formData.about,
			address: formData.address,
			visibility: formData.visibility,
			industries: industries.length > 0 ? pluck(['industryId'], industries) : [],
			specialities: specialities.length > 0 ? pluck(['id'], specialities) : [],
			yearFounded: formData.yearFounded || undefined,
			websiteLink: formData.websiteLink,
			adminName: formData.adminName,
			adminEmail: formData.adminEmail,
			imageId: isEmpty(formData.imageUrl) ? '' : (isEmpty(formData.imageId) ? undefined : formData.imageId),
			featureImageId: isEmpty(formData.featureImageUrl) ? '' : (isEmpty(formData.featureImageId) ? undefined : formData.featureImageId),
			emailFlag: formData.emailFlag,
			status: formData.status,
			userProfileWriteAccess: formData.userProfileWriteAccess,
			hideCreate: formData.hideCreate,
			hideExplore: formData.hideExplore,
			hideHelp: formData.hideHelp
		}

		let validatingAllfields = validate(this.state.formData, !!communityId);

		console.log("Validation error", validatingAllfields);

		if (!validatingAllfields.isValid) {
			this.setState({ errors: validatingAllfields.errors, submitted: true });
			return;
		};

		this.setState({ loading: true });

		if (!communityId) {
			HttpWrapper.callUrl<ICommunityCreateRequest, ICommunityCreateResponse>(URLWrapper.urls.platformAdmin.community,
				HttpMethod.POST, payload, VCommunityCreate)
				.then((response) => {
					toastService.success('Success')
					this.props.history.push('/communities');
					this.setState({ loading: false });
				}).catch((error) => {
					toastService.error(error.message);
					this.setState({ loading: false });
				})
		} else {
			payload['communityId'] = communityId;
			HttpWrapper.callUrl<ICommunityUpdateRequest, ICommunityUpdateResponse>(URLWrapper.urls.platformAdmin.community,
				HttpMethod.PUT, payload, VCommunityCreate)
				.then((res: any) => {
					this.props.history.push('/communities')
					toastService.success(res);
					this.setState({ loading: false });
				}).catch((err: any) => {
					toastService.error(err.message);
					this.setState({ loading: false });
				})
		}
	}

	render() {
		const { errors, formData, loading, communityId } = this.state;

		return (
			<>
				<div className="content-wrapper">
					<section className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<div className="col-sm-6">
									<h1>Community {communityId ? 'Edit' : 'Add'}</h1>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<li className="breadcrumb-item"><Link to="/home">Home</Link></li>
										<li className="breadcrumb-item"><Link to="/communities">Communities List</Link></li>
										<li className="breadcrumb-item active">Community {communityId ? 'Edit' : 'Add'}</li>
									</ol>
								</div>
							</div>
						</div>
					</section>
					<section className="content">
						<div className="row">
							<div className="col-md-12">
								<div className="card card-primary">
									{loading && <Loader />}
									<div className="card-header">
										<h3 className="card-title">{formData.name || 'Community'}</h3>
										<div className="card-tools">
											<button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
												<i className="fas fa-minus"></i></button>
										</div>
									</div>
									<div className="card-body">
										<div className="col basicInfoSection mb-3 pb-3 bg-white">
											<div className="basicInfoFields p-3">
												<form>
													<div className="form-row">
														<div className="form-group col-md-4">
															<label htmlFor="inputJobTitle">Community Name*</label>
															<input type="text" className="form-control input-lg"
																id="inputCommunity" name="CommunityNmae"
																value={formData.name}
																onChange={(e) => { this.handleChange("name", e.target.value) }}
															/>
															{errors.name && <div style={{ color: 'red' }} >
																{errors.name}
															</div>}
														</div>
														<div className="form-group col-md-4">
															<label htmlFor="Classification">Classification*</label>
															<Typeahead
																id="Classification"
																style={{ border: "none" }}
																selected={formData.classifications}
																// defaultSelected={formData.defaultIclassifications}
																labelKey={'name'}
																filterBy={['name']}
																onChange={selected => { this.handleChange('classifications', selected) }}
																options={this.props.allClassification}
																emptyLabel="No classifications found"
																multiple={true}
															/>
															{errors.classifications && <div style={{ color: 'red' }} >
																{errors.classifications}
															</div>}
														</div>
														<div className="form-group col-md-4">
															<label htmlFor="inputIndustry">Community Size*</label>
															<input type="text" className="form-control input-lg"
																id="inputCommunity" name="CommunityNmae"
																onKeyPress={this.onKeyPress.bind(this)}
																onChange={(e) => { this.handleChange("size", e.target.value) }}
																value={formData.size}
															/>
															{errors.size && <div style={{ color: 'red' }} >
																{errors.size}
															</div>}
														</div>

													</div>
													<div className="form-row">
														{/* <div className="form-group  col-md-6 affliatedTagCEdit">
															<label htmlFor="affiliatedTags">Affilated Tags*</label>
															<Typeahead
																id="defaultAffilatedtags"
																style={{ border: "none" }}
																selected={formData.affiliatedTags}
																labelKey={'name'}
																filterBy={['name']}
																onChange={selected => {
																	this.handleChange('affiliatedTags', selected)
																}}
																options={this.props.AllAffilatedtags}
																emptyLabel="No tags found"
																multiple={true}
															/>
															{errors.affiliatedTags && <div style={{ color: 'red' }} >
																{errors.affiliatedTags}
															</div>}
														</div> */}
														<div className="form-group col-md-6 featuresCEdit">
															<label htmlFor="inputOrganization">Features*</label>
															<Typeahead
																options={this.props.AllFeature}
																id="Features"
																selected={formData.features}
																labelKey={'featureName'}
																filterBy={['featureName']}
																onChange={selected => { this.handleChange('features', selected) }}
																emptyLabel="No features found"
																multiple
															/>
															{errors.features && <div style={{ color: 'red' }} >
																{errors.features}
															</div>}
														</div>
													</div>
													<div className="form-row mt-4">
														<div className="form-group col-md-5">
															<label htmlFor="inputOrganization">About Organization*</label>
															<textarea className="form-control"
																rows={5} id="inputOrganization"
																onChange={(e) => { this.handleChange("about", e.target.value) }}
																value={formData.about}
															/>
															{errors.about && <div style={{ color: 'red' }} >
																{errors.about}
															</div>}
														</div>
														<div className="form-group col-md-5">
															<label htmlFor="inputJobAddress">Address</label>
															<textarea className="form-control"
																rows={5} id="inputJobAddress"
																onChange={(e) => { this.handleChange("address", e.target.value) }}
																value={formData.address}
															/>
														</div>
														<div className="form-group col-md-2">
															<label htmlFor="inputJobType">Visibility*</label>

															<div className="custom-control custom-radio">
																<input checked={formData.visibility === CommunityVisiblity.Public ? true : false}
																	value={CommunityVisiblity.Public}
																	onChange={(e) => { this.handleChange("visibility", CommunityVisiblity.Public) }} className="custom-control-input" type="radio" id="customRadio1" name="customRadio" />
																<label htmlFor="customRadio1" className="custom-control-label">Public</label>
															</div>

															<div className="custom-control custom-radio">
																<input checked={formData.visibility === CommunityVisiblity.Private ? true : false}
																	value={CommunityVisiblity.Private}
																	onChange={(e) => { this.handleChange("visibility", CommunityVisiblity.Private) }} className="custom-control-input" type="radio" id="customRadio2" name="customRadio" />
																<label htmlFor="customRadio2" className="custom-control-label">Private</label>
															</div>

															<div className="custom-control custom-radio">
																<input checked={formData.visibility === CommunityVisiblity.Hidden ? true : false}
																	value={CommunityVisiblity.Hidden}
																	onChange={(e) => { this.handleChange("visibility", CommunityVisiblity.Hidden) }} className="custom-control-input" type="radio" id="customRadio3" name="customRadio" />
																<label htmlFor="customRadio3" className="custom-control-label">Hidden</label>
															</div>
															{errors.visibility && <div style={{ color: 'red' }} >
																{errors.visibility}
															</div>}
														</div>
													</div>
													<div className="form-row">
														<div className="form-group col-md-8">
															<label htmlFor="inputOrganization">Specialities</label>
															<Typeahead
																options={this.props.allSpecialities}
																id="Specialities"
																selected={formData.specialities}
																labelKey={'name'}
																filterBy={['name']}
																onChange={selected => { this.handleChange('specialities', selected) }}
																emptyLabel="No specialities found"
																multiple
															/>
														</div>
														<div className="form-group col-md-4">
															<label htmlFor="inputYearFounded">Founded in</label>
															<input type="text" className="form-control input-lg"
																id="yearFounded" name="yearFounded"
																onKeyPress={this.onKeyPress.bind(this)}
																onChange={(e) => { this.handleChange("yearFounded", e.target.value) }}
																value={formData.yearFounded}
															/>
															{errors.yearFounded && <div style={{ color: 'red' }} >
																{errors.yearFounded}
															</div>}
														</div>
														{/* <div className="form-group col-md-4">
															<label htmlFor="inputOrganization">Features*</label>
															<Typeahead
																options={this.props.AllFeature}
																id="Specialities"
																selected={formData.features}
																labelKey={'featureName'}
																filterBy={['featureName']}
																onChange={selected => { this.handleChange('features', selected) }}
																emptyLabel="No features found"
																multiple
															/>
															{errors.features && <div style={{ color: 'red' }} >
																{errors.features}
															</div>}
														</div> */}
													</div>
													<div className="form-row">
														<div className="form-group col-md-3">
															<label htmlFor="inputIndustry">Industry</label>
															<Typeahead
																options={this.props.industries}
																id="Industry"
																style={{ border: "none" }}
																selected={formData.industries}
																labelKey={'name'}
																filterBy={['name']}
																onChange={selected => {
																	this.handleChange('industries', selected)
																}}
																emptyLabel="No industries found"
																multiple={true}
															/>
														</div>
														<div className="form-group col-md-3">
															<label htmlFor="inputWebsite">Website Link</label>
															<input type="text" className="form-control input-lg"
																id="inputWebsite" name="Website"
																onChange={(e) => { this.handleChange("websiteLink", e.target.value) }}
																value={formData.websiteLink}
															/>
															{errors.websiteLink && <div style={{ color: 'red' }} >
																{errors.websiteLink}
															</div>}
														</div>
														<div className="form-group col-md-3">
															<label htmlFor="adminName">Community Admin Name*</label>
															<input type="text" className="form-control input-lg"
																id="adminName" name="adminName"
																onChange={(e) => { this.handleChange("adminName", e.target.value) }}
																value={formData.adminName}
															/>
															{errors.adminName && <div style={{ color: 'red' }} >
																{errors.adminName}
															</div>}
														</div>
														<div className="form-group col-md-3 ">
															<label htmlFor="inputEmail">Email Address*</label>
															<input type="email" className="form-control" id="inputEmail"
																value={formData.adminEmail}
																onChange={(e) => { this.handleChange("adminEmail", e.target.value) }} />
															{errors.adminEmail && <div style={{ color: 'red' }} >
																{errors.adminEmail}
															</div>}

														</div>
													</div>
													<div className="form-row mt-3">
														<div className="form-group col-md-3 d-flex mt-4">
															<label htmlFor="inputUploadlogo" className='w-25'>Upload Logo</label>
															<input type="file" className="form-control-file border-0"
																id="inputUploadlogo" name="inputUploadlogo"
																onChange={(e) => {
																	this.uploadFile('imageId', e.target.files)
																}}
															/>
														</div>
														{!isEmpty(formData.imageUrl) && <div className="form-group col-md-3 d-flex mt-4">
															<ProfileImage url={formData.imageUrl} alt={formData.name} defaultClass="profile-user-img img-fluid img-square" imageType="circle" imageSize="xl" />
															<button type="button" onClick={() => this.removeFile('imageUrl')} className="btn btn-sm bg-white" title="Remove">
																<i className="fas fa-times"></i></button>
														</div>}
														<div className="form-group col-md-3 d-flex mt-4">
															<label htmlFor="inputUploadlogo" className='w-25'>Upload Banner</label>
															<input type="file" className="form-control-file  border-0"
																id="inputUploadlogo2" name="inputUploadlogo2"
																onChange={(e) => {
																	this.uploadFile('featureImageId', e.target.files)
																}}
															/>
														</div>
														{!isEmpty(formData.featureImageUrl) && <div className="form-group col-md-3 d-flex mt-4">
															<ProfileImage url={formData.featureImageUrl} alt={formData.name} defaultClass="profile-user-img img-fluid img-square" imageType="circle" imageSize="xl" />
															<button type="button" onClick={() => this.removeFile('featureImageUrl')} className="btn btn-sm bg-white" title="Remove">
																<i className="fas fa-times"></i></button>
														</div>}
													</div>
													<div className="form-row mt-3 ">
														<div className="form-group col-md-6 d-flex">
															<div className="checkbox">
																<label>
																	<input type="checkbox" name="optionsRadios" id="optionsRadios2"
																		className="mr-2"
																		//@ts-ignore
																		checked={formData.userProfileWriteAccess ? true : false}
																		// value={Boolean(formData.emailFlag)}
																		onChange={() => this.handleChange("userProfileWriteAccess", !formData.userProfileWriteAccess)} />
																	Give permission to community admin for Editing user profile
																</label>
															</div>
														</div>
													</div>
													<div className="form-row mt-3 ">
														<div className="form-group col-md-6 d-flex">
															<div className="checkbox">
																<label>
																	<input type="checkbox" name="optionsRadios" id="optionsRadios2"
																		className="mr-2"
																		//@ts-ignore
																		checked={formData.hideCreate ? true : false}
																		// value={Boolean(formData.emailFlag)}
																		onChange={() => this.handleChange("hideCreate", !formData.hideCreate)} />
																	Hide Create button when inside community
																</label>
															</div>
														</div>
													</div>
													<div className="form-row mt-3 ">
														<div className="form-group col-md-6 d-flex">
															<div className="checkbox">
																<label>
																	<input type="checkbox" name="optionsRadios" id="optionsRadios2"
																		className="mr-2"
																		//@ts-ignore
																		checked={formData.hideExplore ? true : false}
																		// value={Boolean(formData.emailFlag)}
																		onChange={() => this.handleChange("hideExplore", !formData.hideExplore)} />
																	Hide Explore button when inside community
																</label>
															</div>
														</div>
													</div>
													<div className="form-row mt-3 ">
														<div className="form-group col-md-6 d-flex">
															<div className="checkbox">
																<label>
																	<input type="checkbox" name="optionsRadios" id="optionsRadios2"
																		className="mr-2"
																		//@ts-ignore
																		checked={formData.hideHelp ? true : false}
																		// value={Boolean(formData.emailFlag)}
																		onChange={() => this.handleChange("hideHelp", !formData.hideHelp)} />
																	Hide Help buttons when inside community
																</label>
															</div>
														</div>
													</div>
													<div className="form-row mt-3 ">
														<div className="form-group col-md-6 d-flex">
															<div className="checkbox">
																<label style={{ display: window.location.pathname.split('/')[2] ? 'none' : 'block' }}>
																	<input type="checkbox" name="optionsRadios" id="optionsRadios2"
																		className="mr-2"
																		//@ts-ignore
																		checked={formData.emailFlag ? true : false}
																		// value={Boolean(formData.emailFlag)}
																		onChange={() => this.handleChange("emailFlag", !formData.emailFlag)} />
																	Send Email Invite informing Community has been created
																</label>
																{errors.emailFlag && <div style={{ color: 'red' }} >
																	{errors.emailFlag}
																</div>}
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-12">
															<Link to="/communities" className="btn btn-secondary">Cancel</Link>
															<button type="button" className="btn btn-success float-right" onClick={this.handleSubmit}>
																{window.location.pathname.split('/')[2] ? 'Update' : 'Submit'}
															</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</>
		)

	}
}

interface IMapState {
	industries: any
	expertises: any,
	allSpecialities: any,
	allInterests: any,
	allClassification: any
	AllAffilatedtags: any,
	AllFeature: any
}

function mapState(state): IMapState {
	const { global: { industries, expertises, allSpecialities, allInterests, allClassification, AllAffilatedtags, AllFeature } } = state;
	return { industries, expertises, allSpecialities, allInterests, allClassification, AllAffilatedtags, AllFeature };
}

const connectedAdd = withRouter(connect(mapState)(Add));
export { connectedAdd as Add };