import { isEmpty, isNil } from 'ramda';
import { isWebsiteUrl, isEmail, isNumber, isFoundedIn, isGreaterThanCurrentYear } from '../../validate/rules';

export function validate(formData?: any, isUpdate?) {
	let input = formData;
	let errors = {};
	let isValid = true;
	// console.log(input, "input")
	if (!input["name"]) {
		isValid = false;
		errors["name"] = "Please enter community name.";
	}

	if (!isUpdate && input["classifications"].length === 0) {
		isValid = false;
		errors["classifications"] = "Please enter classification.";
	}

	// if (input["features"].length === 0) {
	// 	isValid = false;
	// 	errors["features"] = "Please enter features.";
	// }

	if (!isUpdate && !input["size"] || input["size"] === 0 || !isNumber(input["size"])) {
		isValid = false;
		errors["size"] = "Please enter community size.";
	}

	if (!isUpdate && input["affiliatedTags"].length === 0) {
		isValid = false;
		errors["affiliatedTags"] = "Please enter affilated tags.";
	}

	if (!isUpdate && !input["about"]) {
		isValid = false;
		errors["about"] = "Please enter about organization.";
	}

	if (!isUpdate && isNil(input['visibility'])) {
		isValid = false;
		errors["visibility"] = "Please enter  visibility";
	}

	if (!isUpdate && !isWebsiteUrl(input["websiteLink"])) {
		isValid = false;
		errors["websiteLink"] = "Web link must be entered with prototype reference (http / https)";
	}

	if (!isUpdate && !isEmail(input["adminEmail"])) {
		isValid = false;
		errors["adminEmail"] = "Please enter valid email address.";
	}

	if (!isUpdate && input["yearFounded"] === '0' || !isFoundedIn(input["yearFounded"]) || !isGreaterThanCurrentYear(input["yearFounded"])) {
		isValid = false;
		errors["yearFounded"] = "Please enter valid Founded in.";
	}

	if (!isUpdate && !input["specialities"]) {
		isValid = false;
		errors["specialities"] = "Please enter specialities.";
	}

	if (!isUpdate && !input["adminName"]) {
		isValid = false;
		errors["adminName"] = "Please enter name of the person.";
	}

	// if (!input["emailFlag"]) {
	// 	isValid = false;
	// 	errors["emailFlag"] = "Please select send email invite.";
	// }

	if (!isUpdate && !input["adminEmail"]) {
		isValid = false;
		errors["adminEmail"] = "Please enter email.";
	}

	let errorPayload = {
		errors,
		isValid
	};

	return errorPayload;
}