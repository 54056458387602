import { ModelStatus } from "../../../constants/model-status";
import { UserRole } from "../../../constants/user-role";
import { IProfileEducationPostRequest } from "../../../profile-routes/sub-routes/profile-education-routes";
import { IProfileExperiencePostRequest } from "../../../profile-routes/sub-routes/profile-experience-route";
import { IProfileMembershipPostRequest } from "../../../profile-routes/sub-routes/profile-membership-routes";
import { ValidationUtility } from "../../../validation-utility";
import { IValidatorType } from "../../../validator-interface";
import { IUser } from "../platform-admin";

//Req [GET] /platform/admin/user
export interface IUserRequest {
	start: number,
	count: number,
	query?: string,
	userId?: any,
	onboardDate?: string,
	status?: ModelStatus
}

//Val [GET] /platform/admin/user
export const VUser: IValidatorType<IUserRequest> = {
	start: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	count: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	query: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	userId: { function: ValidationUtility.validateObjectId, params: [], required: false },
	onboardDate: { function: ValidationUtility.validateStringFields, params: [10, 10], required: false },
	status: { function: ValidationUtility.validateNumber, params: [], required: false }
}

//Res [GET] /platform/admin/user
export interface IUserResponse {
	start: number,
	count: number,
	nextStart: boolean,
	totalCount: number,
	content: (IUser & { status: ModelStatus })[]
}

//--------------------------------------------------------------------------------------------------	

//Req [POST] /platform/admin/user
export interface IUserCreateRequest {
	name: string,
	title: any,
	about?: string,
	education?: IProfileEducationPostRequest[],
	experience?: IProfileExperiencePostRequest[],
	membership?: IProfileMembershipPostRequest[],
	expertise?: {
		expertiseIds?: any[],
		rawExpertise?: string[]
	},
	company?: string,
	role: UserRole,
	linkedinUrl: string,
	industry?: any[],
	communityId?: any,
	email: string,
	affiliatedHow?: any,
	image: any,
	emailFlag?: boolean,
	location?: string
}

//Val [POST] /platform/admin/user
export const VUserCreate: IValidatorType<IUserCreateRequest> = {
	name: { function: ValidationUtility.validateStringFields, params: [3, -1], required: true },
	title: { function: ValidationUtility.validateStringFields, params: [3, -1], required: true },
	about: { function: ValidationUtility.validateStringFields, params: [3, -1], required: true },
	education: { function: ValidationUtility.validateAny, params: [], required: false },
	experience: { function: ValidationUtility.validateAny, params: [], required: false },
	membership: { function: ValidationUtility.validateAny, params: [], required: false },
	expertise: {
		function: (x: {
			expertiseIds: any[],
			rawExpertise: string[]
		}) => {
			if (!ValidationUtility.validateObjectArrayId(x.expertiseIds) && x.expertiseIds)
				return undefined
			if (!ValidationUtility.validateArrayStringFields(x.rawExpertise, 0, -1) && x.rawExpertise)
				return undefined
			return x
		}, params: [], required: false
	},
	company: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	role: { function: ValidationUtility.validateNumber, params: [3, -1], required: true },
	linkedinUrl: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	industry: { function: ValidationUtility.validateObjectArrayId, params: [1, 1], required: false },
	communityId: { function: ValidationUtility.validateAny, params: [1, -1], required: false },
	email: { function: ValidationUtility.validateEmail, params: [5, -1], required: true },
	affiliatedHow: { function: ValidationUtility.validateAny, params: [1, -1], required: false },
	image: { function: ValidationUtility.validateObjectId, params: [], required: false },
	emailFlag: { function: ValidationUtility.validateBoolean, params: [1, -1], required: false },
	location: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false }
}

//Res [POST] /platform/admin/user
export type IUserCreateResponse = (IUser & { status: ModelStatus })

//--------------------------------------------------------------------------------------------------	

//Req [PUT] /platform/admin/user
export interface IUserUpdateRequest {
	userId: any,
	name?: string,
	// password: any,
	title?: any,
	education?: (IProfileEducationPostRequest & { educationId?: string })[],
	experience?: (IProfileExperiencePostRequest & { experienceId?: string })[],
	membership?: (IProfileMembershipPostRequest & { membershipId?: string })[],
	expertise?: {
		expertiseIds?: any[],
		rawExpertise?: string[]
	},
	// firstName: string,
	// lastName: string,
	company?: string,
	// role: UserRole,
	// linkedinUrl: string,
	industry?: any[],
	// communityId: any,
	email?: string,
	// affiliatedHow?: any,
	location?: string,
	image?: any
	status: ModelStatus
}

export interface IUserPasswordUpdateRequest {
	userId: any,
	password: string
}

//Val [PUT] /platform/admin/user
export const VUserUpdate: IValidatorType<IUserUpdateRequest> = {
	userId: { function: ValidationUtility.validateObjectId, params: [3, -1], required: true },
	name: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	title: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	education: { function: ValidationUtility.validateAny, params: [], required: false },
	experience: { function: ValidationUtility.validateAny, params: [], required: false },
	membership: { function: ValidationUtility.validateAny, params: [], required: false },
	expertise: {
		function: (x: {
			expertiseIds?: any[],
			rawExpertise?: string[]
		}) => {
			if (!ValidationUtility.validateObjectArrayId(x.expertiseIds))
				return undefined
			if (!ValidationUtility.validateArrayStringFields(x.rawExpertise, 0, -1))
				return undefined
			return x
		}, params: [], required: false
	},
	company: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	industry: { function: ValidationUtility.validateAny, params: [1, 1], required: false },
	email: { function: ValidationUtility.validateEmail, params: [], required: false },
	location: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	image: { function: ValidationUtility.validateObjectId, params: [], required: false },
	status: { function: ValidationUtility.validateNumber, params: [1, -1], required: true }
}
//Val [POST] /platform/admin/user/password
export const VUserUpdatePassword: IValidatorType<IUserPasswordUpdateRequest> = {
	userId: { function: ValidationUtility.validateObjectId, params: [3, -1], required: true },
	password: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true },
}

//Res [PUT] /platform/admin/user
export type IUserUpdateResponse = (IUser & { status: ModelStatus })