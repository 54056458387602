import { IndustriesActionEnum, ExpertisesActionEnum, ClassificationActionEnum, SpecialitiesActionEnum, InterestsActionEnum, AffilatedTagsActionEnum, FeatureActionEnum } from '../constants/enums/globalActionEnum';

export const globalActions = {
	saveIndustriesList,
	saveExpertisesList,
	saveClassifications,
	saveSpecialitiesList,
	saveIntrestsList,
	saveAffilatedTags,
	saveFeature,
};

function saveIndustriesList(list) {
	return dispatch => dispatch({ type: IndustriesActionEnum.GET_INDUSTRIES, list });
}

function saveExpertisesList(list) {
	return dispatch => dispatch({ type: ExpertisesActionEnum.GET_EXPERTISES_ALL, list });
}

function saveClassifications(list) {
	return dispatch => dispatch({ type: ClassificationActionEnum.GET_CLASSIFICATION_ALL, list });
}

function saveSpecialitiesList(list) {
	return dispatch => dispatch({ type: SpecialitiesActionEnum.GET_SPECIALITIES_ALL, list });
}

function saveIntrestsList(list) {
	return dispatch => dispatch({ type: InterestsActionEnum.GET_INTERESTS, list });
}

function saveAffilatedTags(list) {
	return dispatch => dispatch({ type: AffilatedTagsActionEnum.GET_AFFILATED_TAGS_ALL, list })
}

function saveFeature(list) {
	return dispatch => dispatch({ type: FeatureActionEnum.GET_FEATURE_ALL, list })
}