import React from 'react';

const Footer = (props) => {
	return (
		<>
			<aside className="control-sidebar control-sidebar-dark"></aside>
			{/* <footer className="main-footer">
				<strong>Copyright &copy; 2020 <a href="https://networked.co">Networked.co</a>.</strong> All rights reserved.
			<div className="float-right d-none d-sm-inline-block">
					<b>Version</b> 1
    		</div>
			</footer> */}
		</>
	)
}

export default Footer;