import React from 'react'
import { HttpMethod, HttpWrapper } from '../../../helpers/httpWrapper'
import { ICommunityUpdateRequest, ICommunityUpdateResponse, VCommunityUpdate } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/community'
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper'
import { toastService } from '../../../services'
import { IDeleteCommunity, VDeleteCommunity } from 'src/networked-shared-interfaces/interfaces-phase2/platform-admin/platform-admin'


interface IProps {
	communityId: any,
	callback: any
}

export default class DeleteCareerOpportunities extends React.Component<IProps, any> {

	DeleteJobPosted = (communityId: string) => {
		HttpWrapper.callUrl<IDeleteCommunity, ICommunityUpdateResponse>
			(URLWrapper.urls.platformAdmin.community, HttpMethod.DELETE, { communityId }, VDeleteCommunity).then(() => {
				toastService.success("Community Deleted")
				this.props.callback()
			}).catch(error => {
				this.setState({ loading: false });
				toastService.error(`Error: ${error.message}`)
			})
	}

	render() {
		return (
			<div className="modal fade deletePost-modal" id="DeleteCommunity" role="dialog" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered justify-content-center m-auto" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Are you sure you want to delete this community?</h5>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
							<button type="button" className="btn btn-primary" data-dismiss="modal"
								onClick={() => { this.DeleteJobPosted(this.props.communityId) }}
							>Yes, Delete</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
