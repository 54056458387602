import React from 'react';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { trim } from 'ramda';
import { HttpMethod, HttpWrapper } from '../../../../helpers/httpWrapper';
import { URLWrapper } from '../../../../networked-shared-interfaces/url-wrapper';
import { IIndustryGetResponse, IIndustryGetRequest, VIndustryGet, IIndustryDeleteRequest, IIndustryDeleteResponse, VIndustryDelete, IIndustryPostRequest, IIndustryPostResponse, VIndustryPost, IIndustryPutRequest, IIndustryPutResponse, VIndustryPut } from '../../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/industry';
import { toastService } from '../../../../services';
import { ModelStatus } from '../../../../networked-shared-interfaces/constants/model-status';
import { Loader } from '../../common/Loader';
import Accordion from '../../communities/Accordion';

interface IState {
	loading: boolean,
	userStatusLoading: boolean,
	count: number,
	totalCount: number,
	paged: number,
	keyword: string,
	industryTxt: string,
	filter: {
		query: string,
		status: ModelStatus,
		onboardDate: string
	},
	list: any[],
	currentPage: number,
	downloadContent: any,
	currentEditIndustry: string,
	currentDeleteIndustry: string
}

class IndustryList extends React.Component<any, IState> {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			userStatusLoading: false,
			count: 100,
			totalCount: 0,
			paged: 1,
			list: [],
			keyword: '',
			industryTxt: '',
			filter: {
				query: undefined,
				status: undefined,
				onboardDate: undefined
			},
			currentPage: 1,
			downloadContent: '',
			currentEditIndustry: '',
			currentDeleteIndustry: ''
		};
	}

	componentDidMount() {
		this.getIndustries();
	}

	getIndustries = () => {
		const { paged, count, keyword } = this.state;
		this.setState({ loading: true });

		HttpWrapper.callUrl<IIndustryGetRequest, IIndustryGetResponse>(URLWrapper.urls.platformAdmin.industry.url, HttpMethod.GET, {
			start: (paged - 1) * count,
			count: count,
			keyword: keyword || undefined,
			status: ModelStatus.Accepted
		}, VIndustryGet)
			.then((response: any) => {
				this.setState({ loading: false, list: response.content, totalCount: response.totalCount });
			}).catch(error => {
				this.setState({ loading: false });
				toastService.error(error.message);
			})
	}

	deleteIndustry = () => {
		const { currentDeleteIndustry, list, paged } = this.state;
		if (!currentDeleteIndustry) return;
		this.setState({ loading: true });
		HttpWrapper.callUrl<IIndustryDeleteRequest, IIndustryDeleteResponse>
			(URLWrapper.urls.platformAdmin.industry.url, HttpMethod.DELETE, {
				id: currentDeleteIndustry
			}, VIndustryDelete).then((response: any) => {
				toastService.success("Successfully deleted");
				if (list.length === 1 && paged > 1) {
					this.setState({ paged: paged - 1 })
				}
				this.setState({ loading: false, currentDeleteIndustry: '' }, () => this.getIndustries());
			}).catch(error => {
				this.setState({ loading: false, currentDeleteIndustry: '' });
				toastService.error(`Error: ${error.message}`);
			})
	}

	handleSubmit = () => {
		const { industryTxt, currentEditIndustry } = this.state;
		if (!trim(industryTxt)) {
			toastService.error(`Error: Industry should not be empty`);
			return;
		}
		this.setState({ loading: true });

		if (currentEditIndustry) {
			HttpWrapper.callUrl<IIndustryPutRequest, IIndustryPutResponse>(URLWrapper.urls.platformAdmin.industry.url, HttpMethod.PUT, {
				id: currentEditIndustry,
				name: industryTxt
			}, VIndustryPut)
				.then((response: any) => {
					toastService.success("Successfully updated");
					this.setState({ loading: false, currentEditIndustry: '', industryTxt: '' }, () => this.getIndustries());
				}).catch(error => {
					this.setState({ loading: false, currentEditIndustry: '', industryTxt: '' });
					toastService.error(error.message);
				})
		} else {
			HttpWrapper.callUrl<IIndustryPostRequest, IIndustryPostResponse>(URLWrapper.urls.platformAdmin.industry.url, HttpMethod.POST, {
				name: industryTxt
			}, VIndustryPost)
				.then((response: any) => {
					toastService.success("Successfully created");
					this.setState({ loading: false, currentEditIndustry: '', industryTxt: '' }, () => this.getIndustries());
				}).catch(error => {
					this.setState({ loading: false, currentEditIndustry: '', industryTxt: '' });
					toastService.error(error.message);
				})
		}
	}

	resetFilter = () => {

		if (!this.state.keyword) return;

		this.setState({
			keyword: ''
		}, () => this.handleSearch());
	}

	handleSearch = () => {
		this.setState({
			paged: 1,
			list: []
		}, () => this.getIndustries());
	}

	paginate = (paged) => {
		this.setState({ currentPage: paged, paged: paged }, () => this.getIndustries());
	}

	render() {
		const {
			list, loading, count, totalCount, currentPage, keyword, industryTxt
		} = this.state;

		return (
			<>
				<div className="content-wrapper">
					<section className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<div className="col-sm-6">
									<h1>Industries</h1>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<li className="breadcrumb-item"><Link to="/home">Home</Link></li>
										<li className="breadcrumb-item active">Industries</li>
									</ol>
								</div>
							</div>
						</div>
					</section>
					<section className="content">
						<Accordion>
							<div>
								<div className="basicInfoFields p-3">
									<div className="form-row">
										<div className="form-group col-md-5">
											<label htmlFor="Classification">Keyword</label>
											<input className="form-control" type="search" aria-label="Search"
												placeholder="Keyword"
												value={keyword}
												onChange={(e) => { this.setState({ keyword: e.target.value }) }}
											/>
										</div>
										<div className="form-group col-md-7">
											<div className='float-right pr-3'>
												<button type="button" className="btn btn-primary mr-2" onClick={this.resetFilter}>Reset</button>
												<button type="button" className="btn btn-success mr-17" onClick={this.handleSearch}>Search</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Accordion>

						<div className="card">
							<div className="card-header">
								<h3 className="card-title">Industries ({totalCount})</h3>
								<div className="card-tools">
									<div className='d-flex'>
										{list.length > 0 && !loading && <div className="mr-1 mt-1">
											<Pagination
												activePage={currentPage}
												itemsCountPerPage={count}
												totalItemsCount={totalCount}
												pageRangeDisplayed={count}
												onChange={this.paginate}
												itemClass="page-item"
												linkClass="page-link"
											/>
										</div>}
										<button type="button" className="btn btn-primary" data-target="#editIndustry" data-toggle="modal">Add New Industry</button>
									</div>
								</div>
							</div>
							<div className="card-body p-0">
								{loading && <Loader />}
								{list.length === 0 && !loading && <div className="alert alert-info alert-dismissible">
									<h5><i className="icon fas fa-info"></i> No Industries Found!</h5>
								</div>}
								{list.length > 0 && !loading && <table className="table table-striped projects">
									<thead>
										<tr>
											<th> Name </th>
											<th> User Count </th>
											<th> </th>
										</tr>
									</thead>
									<tbody>
										{list && list.length > 0 && list.map((user: any, index) => <tr key={index}>
											<td>{user.name}</td>
											<td>{user.userCount}</td>
											<td className="project-actions text-right">
												<div className='d-flex'>
													<a className="btn btn-info btn-sm mr-2" href="#editIndustry" data-toggle="modal"
														onClick={() => this.setState({ currentEditIndustry: user.industryId, industryTxt: user.name })}
														data-target={`#editIndustry`}>
														<i className="fas fa-pencil-alt">
														</i> Edit</a>
													<a className="btn btn-danger btn-sm" href="#DeleteUser" data-toggle="modal"
														onClick={() => this.setState({ currentDeleteIndustry: user.industryId })}
														data-target={`#DeleteIndustry`}>
														<i className="fas fa-trash">
														</i> Delete </a>
												</div>
											</td>
										</tr>)}
									</tbody>
								</table>}
							</div>
						</div>
					</section>
				</div>
				<div className="modal fade editIndustry-modal" id="editIndustry" data-backdrop="static" role="dialog" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered justify-content-center m-auto" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">Add / Edit Industry</h5>
							</div>
							<div className="modal-body">
								<div className="basicInfoFields p-3">
									<div className="form-row">
										<div className="form-group col-md-12">
											<input className="form-control" type="text" aria-label="Industry"
												placeholder="Enter Industry"
												value={industryTxt}
												onChange={(e) => { this.setState({ industryTxt: e.target.value }) }}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={(e) => { this.setState({ industryTxt: '', currentEditIndustry: '' }) }}>Cancel</button>
								<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleSubmit}>Submit</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade deleteIndustry-modal" id="DeleteIndustry" data-backdrop="static" role="dialog" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered justify-content-center m-auto" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">Are you sure you want to delete this Industry?</h5>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={(e) => this.setState({ currentDeleteIndustry: '' })}>No</button>
								<button type="button" className="btn btn-primary" data-dismiss="modal"
									onClick={this.deleteIndustry}
								>Yes, Delete</button>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

}

export { IndustryList }