import React from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, clone, findIndex, propEq } from 'ramda';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import { HttpWrapper, HttpMethod } from '../../../helpers/httpWrapper';
import { URLWrapper } from '../../../networked-shared-interfaces/url-wrapper';
import { ICommunityRequest, ICommunityResponse, VCommunity } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/community';
import { ICommunityFeatureRequest, ICommunityFeatureReseponse, VCommunityFeature, ICommunityFeatureUpdateRequest, ICommunityFeatureUpdateResponse, VCommunityFeatureUpdate } from '../../../networked-shared-interfaces/interfaces-phase2/platform-admin/sub-routes/community';

const AsyncTypeahead = withAsync(Typeahead);

interface IState {
	communities: any,
	featureList: any,
	selectedFeatureList: any,
	communityId: string,
	loading: boolean,
	formLoading: boolean,
	total: number,
	paged: number,
	nextStart: boolean,
	count: number,
	start: number,
	filter: string,
	paginate: boolean
}

class Configure extends React.Component<any, IState> {

	constructor(props) {
		super(props);

		this.state = {
			communities: [],
			selectedFeatureList: [],
			featureList: [],
			loading: false,
			formLoading: false,
			communityId: '',
			total: 0,
			paged: 1,
			count: 100,
			start: 0,
			filter: '',
			nextStart: true,
			paginate: true
		};

		this.handleCommunityChange = this.handleCommunityChange.bind(this);
		this.paginateCommunities = this.paginateCommunities.bind(this);
		this.getCommnunityFeatures = this.getCommnunityFeatures.bind(this);
		this.handleFeatureChange = this.handleFeatureChange.bind(this);
		this.handlePrivilegeChange = this.handlePrivilegeChange.bind(this);
		this.handleAllPrivilegeChange = this.handleAllPrivilegeChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	private selectedFeature = React.createRef<HTMLSelectElement>()

	paginateCommunities(e: any, cnt) {
		if (!this.state.nextStart) {
			if (this.state.total <= cnt) {
				this.setState({ paginate: false })
			}
		} else {
			this.setState({ start: this.state.start + 1 })
			this.getAllCommunities(this.state.filter, this.state.start + 1);
		}
	}

	handleCommunityChange(selected) {
		if (!selected || selected.length === 0) return;
		this.setState({ communityId: selected[0].communityId });
		this.getCommnunityFeatures(selected[0].communityId);
	}

	handleFeatureChange() {
		const select_feature = this.selectedFeature.current.value,
			select_feature_data = clone(this.state.featureList);
		let selectedFeatureList = select_feature_data.features[select_feature];
		selectedFeatureList.status = findIndex(propEq('status', true), selectedFeatureList.privileges) !== -1;
		this.setState({ selectedFeatureList: selectedFeatureList });
	}

	handleAllPrivilegeChange(status) {
		const selectedFeatureList = clone(this.state.selectedFeatureList)
		selectedFeatureList.status = !status;
		selectedFeatureList.privileges = selectedFeatureList.privileges.map(item => {
			item.status = !status;
			return item;
		})

		this.setState({ selectedFeatureList });
	}

	handlePrivilegeChange(privilegeId, status) {
		this.setState(state => {
			const selectedFeatureList = clone(state.selectedFeatureList)
			let featureStatus = false;
			selectedFeatureList.privileges = selectedFeatureList.privileges.map(item => {
				if (item.privilegeId === privilegeId) {
					item.status = !status;
				}
				featureStatus = featureStatus || item.status;
				return item;
			})
			selectedFeatureList.status = featureStatus;
			return { selectedFeatureList }
		});
	}

	handleSubmit() {
		const { communityId, selectedFeatureList } = this.state;
		this.setState({ formLoading: true });
		HttpWrapper.callUrl<ICommunityFeatureUpdateRequest, ICommunityFeatureUpdateResponse>(
			URLWrapper.urls.platformAdmin.communityFeture, HttpMethod.PUT, {
			communityId: communityId,
			feature: selectedFeatureList
		}, VCommunityFeatureUpdate)
			.then((response) => {
				const select_feature = this.selectedFeature.current.value;
				const select_feature_data = clone(this.state.featureList);
				select_feature_data.features[select_feature] = this.state.selectedFeatureList;
				this.setState({ featureList: select_feature_data, formLoading: false });
			}).catch((error) => {
				this.setState({ formLoading: false });
			})
	}

	getCommnunityFeatures(communityId) {
		this.setState({ loading: true });

		HttpWrapper.callUrl<ICommunityFeatureRequest, ICommunityFeatureReseponse>(
			URLWrapper.urls.platformAdmin.communityFeture, HttpMethod.GET, {
			communityId: communityId
		}, VCommunityFeature)
			.then((response) => {
				console.log(response)
				let selectedFeatureList = response.features[0];
				selectedFeatureList.status = findIndex(propEq('status', true), response.features[0].privileges) !== -1;
				this.setState({ loading: false, featureList: response, selectedFeatureList: selectedFeatureList });
			}).catch((error) => {
				this.setState({ loading: false });
			})
	}

	getAllCommunities(query, start = 0, restart = false) {

		this.setState({ loading: true, filter: query });
		const { count, communities, total } = this.state,
			startCount = restart ? 0 : start * count;

		HttpWrapper.callUrl<ICommunityRequest, ICommunityResponse>(
			URLWrapper.urls.platformAdmin.community, HttpMethod.GET, {
			count: count,
			start: startCount,
			query: query
		}, VCommunity)
			.then((response) => {
				let communityAll = [];
				if (response.content && response.content.length > 0) {
					communityAll = start === 0 ? response.content : [...communities, ...response.content];
				} else {
					communityAll = communities;
				}
				this.setState({
					loading: false,
					communities: communityAll,
					nextStart: response.nextStart,
					total: start === 0 ? response.count : total + response.count,
					paginate: (start === 0 && response.count < 30) ? false : true
				});
			}).catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			})
	}

	render() {
		const { communityId, featureList, selectedFeatureList, formLoading } = this.state

		return (
			<>
				<div className="content-wrapper">

					<section className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<div className="col-sm-6">
									<h1>Configure Platform Features</h1>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<li className="breadcrumb-item"><Link to="/home">Home</Link></li>
										<li className="breadcrumb-item active">Configure Platform Features</li>
									</ol>
								</div>
							</div>
						</div>
					</section>

					{formLoading && <div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>}

					<section className="content">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-6">

									<div className="card card-primary">
										<div className="card-header">
											<h3 className="card-title">Configure Platform Features</h3>
										</div>
										<div className="card-body">
											<div className="form-group">
												<label htmlFor="exampleInputEmail1">Select Community</label>
												<AsyncTypeahead
													isLoading={this.state.loading}
													labelKey="name"
													minLength={2}
													maxResults={30}
													paginationText='Additional results...'
													onPaginate={(e, cnt) => this.paginateCommunities(e, cnt)}
													onChange={selected => this.handleCommunityChange(selected)}
													id="configure_select_community"
													onSearch={query => { this.getAllCommunities(query, 0, true) }}
													options={this.state.communities}
													placeholder="Select Community..."
												/>
											</div>

											{!isEmpty(communityId) && featureList.features && <div className="form-group">
												<label htmlFor="exampleInputEmail1">Select Feature</label>
												<select className="form-control" id="select_feature" ref={this.selectedFeature} onChange={this.handleFeatureChange}>
													{featureList.features.map((feature, index) => <option key={index} value={index}>{feature.featureName}</option>)}
												</select>
											</div>}
										</div>
									</div>
								</div>

								{!isEmpty(selectedFeatureList) && <div className="col-md-6">
									<div className="card card-secondary">
										<div className="card-header">
											<div className="row">
												<div className="col-sm-8"><h3 className="card-title">{selectedFeatureList.featureName}</h3></div>
												<div className="col-sm-4">
													<div className="custom-control custom-switch">
														<input type="checkbox" checked={selectedFeatureList.status} onChange={() => this.handleAllPrivilegeChange(selectedFeatureList.status)} id={"customFeatureSwitch" + selectedFeatureList.featureId} className="custom-control-input" />
														<label className="custom-control-label" htmlFor={"customFeatureSwitch" + selectedFeatureList.featureId}>{selectedFeatureList.privilageName}</label>
													</div>
												</div>
											</div>
										</div>
										<div className="card-body">
											<label htmlFor="exampleInputEmail1"> </label>
											{selectedFeatureList.privileges.map((privilege, index) => <div className="form-group" key={index}>
												<div className="custom-control custom-switch">
													<input type="checkbox" onChange={() => this.handlePrivilegeChange(privilege.privilegeId, privilege.status)} className="custom-control-input" id={"customSwitch" + index} checked={privilege.status} />
													<label className="custom-control-label" htmlFor={"customSwitch" + index}>{privilege.privilageName}</label>
												</div>
											</div>)}
										</div>
										<div className="card-footer">
											<button type="submit" className="btn btn-info" onClick={this.handleSubmit}>Save</button>
											<button type="submit" className="btn btn-default float-right" onClick={this.handleFeatureChange}>Cancel</button>
										</div>
									</div>
								</div>}

							</div>
						</div>
					</section>

				</div>

			</>
		);
	}
}

export { Configure }