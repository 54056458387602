export const monthDiff = (dateFrom, dateTo) => {
	const df = new Date(dateFrom).toString() !== "Invalid Date" ? new Date(dateFrom) : new Date(),
		dt = dateTo === 0 ? new Date() : (new Date(dateTo).toString() !== "Invalid Date" ? new Date(dateTo) : new Date())
	return dt.getMonth() - df.getMonth() + (12 * (dt.getFullYear() - df.getFullYear()))
}

// Returns time from timestamp if timestamp date = present date else timestamp date
export const displayTimestampDateTime = (timestamp) => {
	const presentDate = new Date().toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' });
	const timestampDate = displayTimestampDate(timestamp);
	const timestampTime = displayTimestampTime(timestamp);
	return (timestampDate === presentDate) ? timestampTime : timestampDate;
}

/* Returns date from timestamp
 * format = true -----> August 28, 2020
 * format = false -----> 8/28/2020 
*/
export const displayTimestampDate = (timestamp, format = false) => {
	return new Date(timestamp).toLocaleString('en-US', { day: 'numeric', month: format ? 'long' : 'numeric', year: 'numeric' });
}

// Returns time from timestamp
export const displayTimestampTime = (timestamp) => {
	return new Date(timestamp).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}